import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}


export const deleteLightSlice = createSlice({
    name: 'deleteFlight',
    initialState,
    reducers: {
      DeleteFlightTrue:(state)=>{
        state.value=true
      },
      DeleteFlightFalse:(state)=>{
        state.value=false
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { DeleteFlightTrue, DeleteFlightFalse} = deleteLightSlice.actions
  
  export default deleteLightSlice.reducer