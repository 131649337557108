import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}


export const editLightSlice = createSlice({
    name: 'editFlight',
    initialState,
    reducers: {
      EditFlightTrue:(state)=>{
        state.value=true
      },
      EditFlightFalse:(state)=>{
        state.value=false
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { EditFlightTrue, EditFlightFalse} = editLightSlice.actions
  
  export default editLightSlice.reducer