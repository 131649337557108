import React, { useEffect, useState } from 'react';
import baseUrl from '../../components/baseUrl';
import Footer from '../../components/Footer/Footer'
import profile from './assets/profile.jpg'
import change_profile from './assets/change_profile.svg'
import CarrierNav from '../../components/Nav/CarrierNav';
import mail from './assets/mail.svg'
import phone from './assets/phone.svg'
import location from './assets/location.svg'
import SettingCountrySelector from './SettingCountrySelector';
import { useSelector } from 'react-redux';
import ListFlightModal from '../../components/Flight/ListFlightModal';
import { BiChevronDown } from 'react-icons/bi';
import axios from 'axios';
import PageLoading from '../Dashboard/PageLoading';
import visibility_on from './assets/visibility_on.svg'
import visibility_off from './assets/visibility_off.svg'
import { useDispatch } from 'react-redux'
import { sessionTrue } from '../../features/pages/sessionSlice';


const Settings = ({ airports }) => {
  const dispatch = useDispatch()
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [profileData, setProfileData] = useState([])
  const [carrierID, setCarrierID] = useState("")
  const [username, setUsername] = useState("")
  const [userCountry, setUserCountry] = useState("")
  const [userCity, setUserCity] = useState("")
  const [access_token, setAccessToken] = useState()


  const [refresh_token, setRefreshToken] = useState()
  const [switchStatus, setSwitchStatus] = useState(false)
  const [country, setCountry] = useState(null)
  const [selectWeight, setSelectWeight] = useState('')
  const [errorMessage, setErrorMessage] = useState("")
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [currentPassword, setCurrentPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [address, setAddress] = useState(null)
  const [address2, setAddress2] = useState(null)
  const [digitalAddress, setDigitalAddress] = useState(null)
  const [city, setCity] = useState(null)
  const [zipCode, setZipCode] = useState(null)
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [unChangedData, setUnChangedData] = useState([])

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [strength, setStrength] = useState(0);
  const [confirmStrength, setConfirmStrength] = useState(0);

  //disable
  const [disabled, setDisabled] = useState(false)

  //listFlightModal
  const listFlightModal = useSelector((state) => state.listFlight.value)

  // Decode the data after retrieving it
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true)
    const data = JSON.stringify({
      first_name: firstName === null ? profileData?.first_name : firstName,
      last_name: lastName === null ? profileData?.last_name : lastName,
      phone_number: phoneNumber === null ? profileData?.phone_number : phoneNumber,
      address: address === null ? profileData?.address : address,
      address2: address2 === null || address2 === "" ? "null" : address2,
      digital_address: digitalAddress === null || digitalAddress === "" ? "null" : digitalAddress,
      country: country === null ? userCountry : country,
      city: city === null ? userCity : city,
      zip_code: zipCode === null || zipCode === "" ? "null" : zipCode
    })

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/profile/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        const feedback = response.data
        if (feedback?.message) {
          window.location.reload()
        }
      }
      ).catch((error) => {
        console.log(error?.response?.data)
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        else {
          console.log(error)
          
        }
      })

  }


  const handleUpdate = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Password Mismatch!")
    }
    else if (currentPassword.length < 8) {
      setErrorMessage("Old password error")
    }
    else if (password.length < 8) {
      setErrorMessage("Must be at least 8 characters")
    }
    else {
      setErrorMessage("")
      const data = JSON.stringify({
        old_password: currentPassword,
        password: password,
        password_repeated: confirmPassword
      })
      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${baseUrl}/set-new-password/`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        },
        data: data
      };
      await axios.request(config)
        .then((response) => {
          const feedback = response.data
          if (feedback?.message) {
            window.localStorage.clear()
            window.location.reload()
          }
        }
        ).catch((error) => {
          if (error?.response?.data?.detail) {
            setErrorMessage("Incorrect Password")
          }
          if (error?.response?.data?.messages) {
            if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
              dispatch(sessionTrue())

            }
          }
          else {
            console.log(error)
            
          }
          // console.log(error?.response?.data)
        })
    }

  }

  const tester = (value) => {
    let criteriaMet = 0;

    if (value.length >= 8) criteriaMet++;
    if (/[A-Z]/.test(value)) criteriaMet++;
    if (/[a-z]/.test(value)) criteriaMet++;
    if (/\d/.test(value)) criteriaMet++;
    if (/[!@#$%^&*]/.test(value)) criteriaMet++;

    return criteriaMet
  }

  const passwordOnChange = (value) => {
    setPassword(value)
    setStrength(tester(value));
  }

  const confirmPasswordOnChange = (value) => {
    setConfirmPassword(value)
    setConfirmStrength(tester(value));
  }

  const handlePasswordChange = (newPassword) => {
    // checkPasswordStrength(newPassword);
    // console.log(getPasswordStrengthIndicator())
    // Regular expressions to check for lowercase, uppercase, and special characters
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numeric = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Check if the password meets the criteria
    const containsLowercase = lowercaseRegex.test(newPassword);
    const containsUppercase = uppercaseRegex.test(newPassword);
    const containsNumeric = numeric.test(newPassword)
    const containsSpecialChar = specialCharRegex.test(newPassword);

    // You can further use these boolean values to display error messages or change styles.
    // console.log('Contains lowercase:', containsLowercase);
    // console.log('Contains uppercase:', containsUppercase);
    // console.log('Contains special character:', containsSpecialChar);
    if (!containsLowercase) {
      return "Password Must Contain atleast 1  lowercase"
    }
    else if (!containsUppercase) {
      return "Password Must Contain atleast 1 uppercase"
    }
    else if (!containsNumeric) {
      return "Password Must Contain atleast 1 numeric"
    }
    else if (!containsSpecialChar) {
      return "Password Must Contains atleast 1 special character"
    }
    else {
      return ""
    }
  };

  useEffect(() => {
    // Check if any form field value is different from the initial flight data
    const hasFormChanged =
      firstName !== unChangedData?.first_name && firstName !== null ||
      lastName !== unChangedData?.last_name && lastName !== null ||
      phoneNumber !== unChangedData?.phone_number && phoneNumber !== null ||
      zipCode !== unChangedData?.zip_code && zipCode !== null ||
      address !== unChangedData?.address && address !== null ||
      address2 !== unChangedData?.address2 && address2 !== null ||
      country !== unChangedData?.country && country !== null ||
      city !== unChangedData?.city && city !== null ||
      digitalAddress !== unChangedData?.digital_address && digitalAddress !== null

    setIsFormChanged(hasFormChanged);
  }, [
    firstName, lastName,
    phoneNumber, zipCode, address,
    address2, country, city, digitalAddress
  ]);

  useEffect(() => {
    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      //Fetching Bookings
      const fetchProfile = async (token, id) => {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/profile/`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          data: {}
        };

        await axios.request(config).then((response) => {
          const data = response?.data
          if (data?.email) {
            setZipCode(data?.zip_code)
            setAddress2(data?.address2)
            setDigitalAddress(data?.digital_address)
            setProfileData(data)
            setUnChangedData(data)
          }
          else {
            window.localStorage.clear()
            window.location.reload()
          }
        }).catch((error) => {
          if (error?.response?.data?.messages) {
            if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
              dispatch(sessionTrue())

            }
          }
          else {
            console.log(error)
            
          }
        })
      }

      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setCarrierID(parsedData?.id)
      setUsername(parsedData?.full_name)
      setUserCity(parsedData?.city)
      setUserCountry(parsedData?.country)
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      fetchProfile(parsedData?.access_token, parsedData?.id)
    }
    else {
      window.location.href = '/'
    }
  }, [])

  if (profileData) {
    return (
      <div className="bg-white  min-h-screen">
        {listFlightModal ? <ListFlightModal access_token={access_token} carrierID={carrierID} airports={airports} /> : null}
        <div className=' flex z-50 fixed mt-[-170px] pt-4 bg-white'>
          <CarrierNav access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
        </div>
        <div className=" w-full mx-auto mt-[151px] px-[2rem] sm:px-[4.375rem] md:px-[5rem] ">
          <div className="flex justify-between flex-col lg:flex-row lg:items-start gap-5 ">
            {/* Profile Card */}
            <div className="bg-white  w-full lg:w-[40%]">
              <div className="flex flex-col items-center">
                <div className='flex w-full lg:flex-col gap-[25px] lg:gap-0 items-center justify-center'>
                  <div className='flex flex-col relative'>
                    <img className=" w-[54.75px] sm:w-[120px] h-[54.75px] sm:h-[120px] rounded-full" src={profile} alt="Profile" />
                    {/**Change the Profile */}
                    {/* <div className=' mr-[-0.8rem] sm:-mr-4 mt-[1.7rem] sm:mt-16 absolute bg-[#FFDFE4] shadow-input rounded-full flex items-center justify-center align-middle right-0 float-right w-[25px] h-[25px] sm:w-[43px] sm:h-[43px]' >
                      <img src={change_profile} alt="" className=' w-[12.79px] h-[11.63px] sm:w-[22px] sm:h-[20px] cursor-pointer' />
                    </div> */}
                  </div>
                  {/**infor */}
                  <div className=' text-left lg:text-center lg:items-center lg:justify-center align-middle flex flex-col gap-[5px] mt-[14px]'>
                    <h3 className="font-bold text-[18px] sm:text-[25px] leading-[21.83px] sm:leading-[30.33px]">{username}</h3>
                    {/**City */}
                    <div className='flex gap-[15px]'>
                      <img src={location} alt="" />
                      <p className=" text-[14px] sm:text-[16px] text-[#707070] leading-[16.98px] sm:leading-[19.41px]">{userCity}, {userCountry}</p>
                    </div>
                  </div>
                </div>
                {/**Booking details */}
                {/* <div className=' xs:whitespace-nowrap w-full xs:w-[329px] sm:w-[400px] items-start justify-between bg-[#E5E5E5] flex rounded-[30px] lg:w-[365px] mt-[29px] py-[21px] sm:pt-[29px] sm:pb-[40px] px-[22px] sm:px-[35px] gap-4'>
                  <div className='flex flex-col gap-[5px]'>
                    <h1 className=' font-bold text-[25px] sm:text-[30px] leading-[30.33px] sm:leading-[36.39px]'>0</h1>
                    <h1 className=' text-[#707070] text-[12px] sm:text-[14px] leading-[14.56px] sm:leading-[16.98px]'>Flights listed</h1>
                  </div>
                  <div className='flex flex-col gap-[5px]'>
                    <h1 className=' font-bold text-[25px] sm:text-[30px] leading-[30.33px] sm:leading-[36.39px]'>0</h1>
                    <h1 className=' text-[#707070] text-[12px] sm:text-[14px] leading-[14.56px] sm:leading-[16.98px]'>Packages sent</h1>
                  </div>
                  <div className='flex flex-col gap-[5px]'>
                    <h1 className=' font-bold text-[25px] sm:text-[30px] leading-[30.33px] sm:leading-[36.39px]'>$0</h1>
                    <h1 className=' text-[#707070] text-[12px] sm:text-[14px] leading-[14.56px] sm:leading-[16.98px]'>Total earnings</h1>
                  </div>
                </div> */}
                {/**Contact details */}
                <div className=" w-full xs:w-[329px] sm:w-[400px] lg:w-full gap-5 flex flex-col text-left items-start justify-start mt-[36px]">
                  {/**Mail */}
                  <div className='flex gap-[15px]'>
                    <img src={mail} alt="" />
                    <p>{profileData?.email}</p>
                  </div>

                  {/**Phone Number */}
                  <div className='flex gap-[15px]'>
                    <img src={phone} alt="" />
                    <p>{profileData?.phone_number}</p>
                  </div>

                  {/**Location */}
                  <div className='flex gap-[15px]'>
                    <img src={location} alt="" />
                    <p>{profileData?.address}</p>
                  </div>
                </div>
              </div>
            </div>

            {/**Personal and Account Switch */}
            <div className=" w-full flex-grow flex gap-[14px] flex-col items-center justify-center lg:items-start lg:justify-start mt-[50px] lg:mt-0 ">
              <div className="flex w-fit min-w-[249px] sm:min-w-[386px] bg-[#E5E5E5] font-bold text-[12px] sm:text-[18px] leading-[14.56px] sm:leading-[21.83px] rounded-[80px] px-[6px] sm:px-[11px] py-[5px] sm:py-[10px] items-center mb-6">
                <button onClick={() => setSwitchStatus(false)} className={` ${!switchStatus ? "text-white border-[#EB0728] bg-[#EB0728] border-2 rounded-full" : ""} px-[33px] sm:px-[52px] py-[8px] sm:py-3 transition-all`}>PROFILE</button>
                <button onClick={() => setSwitchStatus(true)} className={` ${switchStatus ? "text-white border-[#EB0728] bg-[#EB0728] border-2 rounded-full" : ""} px-[33px] sm:px-[52px] py-[8px] sm:py-3 transition-all`}>ACCOUNT</button>
              </div>
              {/* Settings Form */}
              {!switchStatus ?
                <div className=' bg-[#E5E5E5] w-full rounded-[30px] pt-[34px] pb-[74px] px-[23px] sm:px-[40px]'>
                  <form method='put' onSubmit={handleSubmit}>
                    <h2 className="font-bold text-[16px] sm:text-[18px] leading-[19.41px] sm:leading-[21.83px] mb-5">Personal Information</h2>
                    <div className="flex flex-col w-full  gap-[13px] mb-4">
                      {/**First and Last Names */}
                      <div className='flex flex-col sm:flex-row w-full gap-[13px]'>
                        <input disabled required value={firstName === null ? profileData?.first_name : firstName} onChange={(e) => setFirstName(e.target.value)} className=" disabled:opacity-30 h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="text" placeholder="First Name" />
                        <input disabled required value={lastName === null ? profileData?.last_name : lastName} onChange={(e) => setLastName(e.target.value)} className=" disabled:opacity-30 h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="text" placeholder="Last Name" />
                      </div>
                      {/**Phone Number and Zip Code */}
                      <div className='flex flex-col sm:flex-row w-full gap-[13px]'>
                        <input disabled required value={phoneNumber === null ? parseInt(profileData?.phone_number) : phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className=" disabled:opacity-30  appearance-none h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="number" placeholder="Phone Number" />
                        <input value={zipCode === "null" ? "" : zipCode} onChange={(e) => setZipCode(e.target.value)} className=" appearance-none h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="number" placeholder="Zip Code" />
                      </div>
                    </div>

                    {/**Address */}
                    <h2 className="font-bold text-[16px] sm:text-[18px] leading-[19.41px] sm:leading-[21.83px] mb-5">Address</h2>
                    <div className="flex flex-col w-full  gap-[13px]">
                      <div className='flex flex-col sm:flex-row w-full gap-[13px]'>
                        <input required value={address === null ? profileData?.address : address} onChange={(e) => setAddress(e.target.value)} className=" h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="text" placeholder="Address" />
                        <input value={address2 === "null" ? "" : address2} onChange={(e) => setAddress2(e.target.value)} className=" h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="text" placeholder="Address 2 (Optional)" />
                      </div>
                      {/**Country and City */}
                      <div className='flex flex-col sm:flex-row w-full gap-[13px]'>
                        {/* <SettingCountrySelector userCountry={userCountry} setCountry={setCountry} component="setting" /> */}

                        <input disabled required value={country === null ? profileData?.country : country} onChange={(e) => setCountry(e.target.value)} className=" disabled:opacity-30 h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="text" placeholder="City" />
                        <input disabled required value={city === null ? profileData?.city : city} onChange={(e) => setCity(e.target.value)} className=" disabled:opacity-30 h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none" type="text" placeholder="City" />
                      </div>
                      {/**Digital Address */}
                      <div className='flex flex-col sm:flex-row w-full gap-[13px]'>
                        <input value={digitalAddress === "null" ? "" : digitalAddress} onChange={(e) => setDigitalAddress(e.target.value)} className="  h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] sm:w-[48%] outline-none" type="text" placeholder="Digital Address (Optional)" />
                      </div>
                    </div>
                    {/**Save button */}
                    <div className='flex w-full sm:items-end sm:justify-end mt-[32px] sm:mt-0'>
                      <button type='submit' disabled={!isFormChanged || disabled} className={` disabled:bg-[#F4C5CC] disabled:cursor-not-allowed ${isFormChanged ? 'bg-[#EB0728] ' : ' bg-[#F4C5CC] opacity-50 cursor-not-allowed'} text-white text-[18px] leading-[21.83px] py-3 rounded-[40px] w-full sm:w-[48%] text-center `}>Save changes</button>
                    </div>
                  </form>
                </div> :
                <div className=' bg-[#E5E5E5] w-full rounded-[30px] pt-[34px] pb-[74px] px-[23px] sm:px-[40px]'>
                  <form method='patch' onSubmit={handleUpdate}>
                    <h2 className="font-bold text-[16px] sm:text-[18px] leading-[19.41px] sm:leading-[21.83px] mb-5">Update password</h2>
                    {/**Update Password */}
                    <div className=' flex flex-col gap-3'>
                      <div className='w-full flex items-start'>
                        <input required value={currentPassword} type={showCurrentPassword ? "text" : "password"} onChange={(e) => setCurrentPassword(e.target.value)} className={` ${errorMessage === "Old password error" || errorMessage === "Incorrect Password" ? "rounded-[2.5rem] border-[1px] border-[#EB0728]" : ""} h-[44.98px] placeholder:text-[#707070] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] w-full outline-none`} placeholder="Current Password" />
                        <div onClick={toggleCurrentPasswordVisibility} className='-ml-[39px] mt-[10px] cursor-pointer '>
                          {showCurrentPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                        </div>
                      </div>
                      {errorMessage === "Incorrect Password" ? <h1 className=' pl-5 w-full  text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                      {errorMessage === "Old password error" ? <h1 className=' pl-5 w-full  text-red-600 text-[12px] text-left'>Must be at least 8 characters</h1> : null}

                      {/** Password */}
                      <div className=' mt-[15px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                        <div className=' box-border flex flex-wrap w-full'>

                          <div className='w-full flex items-start'>
                            <input value={password} onChange={(e) => passwordOnChange(e.target.value)} required type={showPassword ? "text" : "password"} name="password" id="password" data-cy="password" placeholder="New Password" className={`
                                                                ${(password.length > 0 && handlePasswordChange(password) !== "") ||
                                (password.length < 4 && errorMessage === "Password is Required") ||
                                (password.length < 4 && errorMessage === "Password is too short") ||
                                errorMessage === "Password Mismatch"
                                ? " border-[1px] border-[#EB0728]" : ""}  m-0 placeholder-[#707070] text-[12px] w-full font-normal bg-white h-[44.98px] rounded-[50px] pl-5 outline-none `} />
                            {/**Strength */}
                            <div className=' -ml-[100px] flex mt-[20px] xs:mt-[18px] gap-[5px] pr-[50px]'>
                              <div className={` ${password.length < 1 ? "bg-[#D9D9D9]" : password.length < 6 ? "bg-[#EB0728]" : strength <= 2 ? "bg-[#EB0728]" : strength === 3 ? " bg-yellow-300" : strength >= 4 ? "bg-[#92D16B]" : "bg-[#92D16B]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${password.length < 7 ? "bg-[#D9D9D9]" : strength === 1 ? "bg-[#D9D9D9]" : strength === 2 ? "bg-[#EB0728]" : strength === 3 ? " bg-yellow-300" : strength >= 4 ? "bg-[#92D16B]" : "bg-[#92D16B]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${password.length < 8 ? "bg-[#D9D9D9]" : strength === 3 ? " bg-yellow-300" : strength >= 4 ? "bg-[#92D16B]" : ""} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${password.length >= 8 && strength >= 4 ? "bg-[#92D16B]" : "bg-[#D9D9D9]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${password.length >= 8 && strength >= 5 ? "bg-[#92D16B]" : "bg-[#D9D9D9]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                            </div>
                            <div onClick={togglePasswordVisibility} className='-ml-[39px] mt-[13px] cursor-pointer '>
                              {showPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                            </div>
                          </div>
                          {handlePasswordChange(password) === "Password Must Contain atleast 1 numeric" || errorMessage === "Password is Required" || errorMessage === "Password is too short" || errorMessage === "Password Mismatch"
                            || handlePasswordChange(password) === "Password Must Contain atleast 1  lowercase" ||
                            handlePasswordChange(password) === "Password Must Contain atleast 1 uppercase" ||
                            handlePasswordChange(password) === "Password Must Contains atleast 1 special character" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{password.length > 0 && handlePasswordChange(password) !== "" ? handlePasswordChange(password) :
                              (password.length < 4 && errorMessage === "Password is Required") ||
                                (password.length < 4 && errorMessage === "Password is too short") ||
                                errorMessage === "Password Mismatch" ? errorMessage : ""}</h1> : null}
                        </div>
                      </div>

                      {/** Confirm New Password */}
                      <div className=' mt-[15px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                        <div className=' box-border flex flex-wrap w-full'>

                          <div className='w-full flex items-start'>
                            <input value={confirmPassword} onChange={(e) => confirmPasswordOnChange(e.target.value)} required type={showConfirmPassword ? "text" : "password"} name="password" id="password" data-cy="password" placeholder="Confirm New Password" className={`
                                                                ${(confirmPassword.length > 0 && handlePasswordChange(confirmPassword) !== "") ||
                                (confirmPassword.length < 4 && errorMessage === "Password is Required") ||
                                (confirmPassword.length < 4 && errorMessage === "Password is too short") ||
                                errorMessage === "Password Mismatch"
                                ? " border-[1px] border-[#EB0728]" : ""}  m-0 placeholder-[#707070] text-[12px] w-full font-normal bg-white h-[44.98px] rounded-[50px] pl-5 outline-none `} />
                            {/**Strength */}
                            <div className=' -ml-[100px] flex mt-[20px] xs:mt-[18px] gap-[5px] pr-[50px]'>
                              <div className={` ${confirmPassword.length < 1 ? "bg-[#D9D9D9]" : confirmPassword.length < 6 ? "bg-[#EB0728]" : confirmStrength <= 2 ? "bg-[#EB0728]" : confirmStrength === 3 ? " bg-yellow-300" : confirmStrength >= 4 ? "bg-[#92D16B]" : "bg-[#92D16B]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${confirmPassword.length < 7 ? "bg-[#D9D9D9]" : confirmStrength === 1 ? "bg-[#D9D9D9]" : confirmStrength === 2 ? "bg-[#EB0728]" : confirmStrength === 3 ? " bg-yellow-300" : confirmStrength >= 4 ? "bg-[#92D16B]" : "bg-[#92D16B]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${confirmPassword.length < 8 ? "bg-[#D9D9D9]" : confirmStrength === 3 ? " bg-yellow-300" : confirmStrength >= 4 ? "bg-[#92D16B]" : ""} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${confirmPassword.length >= 8 && confirmStrength >= 4 ? "bg-[#92D16B]" : "bg-[#D9D9D9]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                              <div className={` ${confirmPassword.length >= 8 && confirmStrength >= 5 ? "bg-[#92D16B]" : "bg-[#D9D9D9]"} w-[0.3rem] h-[0.3rem] xs:w-2 xs:h-2 rounded-[8px]`} />
                            </div>
                            <div onClick={toggleConfirmPasswordVisibility} className='-ml-[39px] mt-[13px] cursor-pointer '>
                              {showConfirmPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                            </div>
                          </div>
                          {handlePasswordChange(confirmPassword) === "Password Must Contain atleast 1 numeric" || errorMessage === "Password is Required" || errorMessage === "Password is too short" || errorMessage === "Password Mismatch"
                            || handlePasswordChange(confirmPassword) === "Password Must Contain atleast 1  lowercase" ||
                            handlePasswordChange(confirmPassword) === "Password Must Contain atleast 1 uppercase" ||
                            handlePasswordChange(confirmPassword) === "Password Must Contains atleast 1 special character" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{confirmPassword.length > 0 && handlePasswordChange(confirmPassword) !== "" ? handlePasswordChange(confirmPassword) :
                              (confirmPassword.length < 4 && errorMessage === "Password is Required") ||
                                (confirmPassword.length < 4 && errorMessage === "Password is too short") ||
                                errorMessage === "Password Mismatch" ? errorMessage : ""}</h1> : null}
                        </div>
                      </div>
                    </div>


                    <div className=' mt-[30px] items-center justify-center flex flex-col xm:flex-row w-full gap-[13px]'>
                      {/* <button className=" border-[1px] border-[#AAAAAAAA] border-opacity-[66.67] text-[18px] leading-[21.83px] py-3 rounded-[40px] w-full sm:w-[48%] text-center ">Cancel</button> */}
                      <button className="bg-[#EB0728] text-white text-[18px] leading-[21.83px] py-3 rounded-[40px] w-full sm:w-[48%] text-center ">Save changes</button>
                    </div>

                    {/**Change weight */}
                    {/* <div className='  flex flex-col mt-[43px]'>
                      <h2 className="font-bold text-[16px] sm:text-[18px] leading-[19.41px] sm:leading-[21.83px] mb-5">Account settings</h2>
                      <div className='flex relative'>
                        <select value={selectWeight} onChange={(e) => setSelectWeight(e.target.value)} name="weight" id="weight" className=' appearance-none cursor-pointer w-full xm:w-1/2  h-[44.98px] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px] bg-white py-[14px] px-[21px] rounded-[50px] outline-none'>
                          <option disabled value="" className='text-[#707070]'>change weight</option>
                          <option value="kg">kg</option>
                          <option value="lb">lb</option>
                        </select>
                        <BiChevronDown color="#707070" size={25} className={`float-right -ml-8 mt-2`} />
                      </div>
                    </div> */}
                  </form>
                </div>}
            </div>
          </div>
        </div>
        {/**Footer */}
        <Footer />
      </div>
    );
  }
  else {
    return (
      <div className=' w-full h-full'>
        <PageLoading dashboard="carrier" access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
      </div>
    )
  }
};

export default Settings;
