import React, { useState } from 'react'
import goodifly from "../../assets/goodifly.png"
import baseUrl from '../../../../components/baseUrl'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { sessionTrue } from '../../../../features/pages/sessionSlice';


const AddMobileMoney = ({ user, networks, whereTo, access_token, setAddWithrawalType, setMobileMoneyAdded, setAddWithrawalMethod, setNoMethod }) => {
    const dispatch = useDispatch()
    const [networkName, setNetworkName] = useState('MTN')
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        const customData = networks?.filter(item => item?.name === networkName)

        if (customData[0]) {
            const data = JSON.stringify({
                account_name: name,
                name: networkName,
                account_number: number,
                code: customData[0]?.code,
                type: customData[0]?.type,
                user: user,
            })

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${baseUrl}/withdrawal-method/`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    if (response?.data) {
                        setMobileMoneyAdded(response?.data)
                        if (whereTo === "Manage") {
                            setAddWithrawalMethod(true)
                        }
                        else if (whereTo === "Withdrawal") {
                            setNoMethod(true)
                        }
                        setAddWithrawalType(null)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.messages) {
                        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                            dispatch(sessionTrue())

                        }
                    }
                    else {
                        console.log(error)
                        
                    }
                });
        }
    }
    return (
        <div className=' px-[25px] sm:px-[45px] bg-white rounded-[30px] w-full h-full flex flex-col items-center pt-[34px] sm:pt-[42px] pb-[35px] sm:pb-[95px]'>
            <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
            <h1 className=' mt-[23px] font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] '>Withdrawal method</h1>
            <h1 className=' mt-[5px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>Mobile Money</h1>
            {/**All networks */}
            <div className=' text-[14px] leading-[16.98px] mt-[36px] w-full flex items-center justify-center gap-4'>
                <div onClick={() => setNetworkName('MTN')} className={`${networkName === 'MTN' ? "cursor-not-allowed" : "cursor-pointer"} flex gap-[6px]`}>
                    <div className={` ${networkName === 'MTN' ? "border-[3px] border-[#E5E5E5] bg-[#EB0728]" : "bg-white border-[1px] border-[#C1C1C1]"} w-[18px] h-[18px] rounded-full `} />
                    <h1 className=' text-[#707070]'>MTN Momo</h1>
                </div>
                <div onClick={() => setNetworkName('Vodafone')} className={`${networkName === 'Vodafone' ? "cursor-not-allowed" : "cursor-pointer"} flex gap-[6px]`}>
                    <div className={` ${networkName === 'Vodafone' ? "border-[3px] border-[#E5E5E5] bg-[#EB0728]" : "bg-white border-[1px] border-[#C1C1C1]"} w-[18px] h-[18px] rounded-full `} />
                    <h1 className=' text-[#707070]'>Vodafone Cash</h1>
                </div>
                <div onClick={() => setNetworkName('AirtelTigo')} className={`${networkName === 'AirtelTigo' ? "cursor-not-allowed" : "cursor-pointer"} flex gap-[6px]`}>
                    <div className={` ${networkName === 'AirtelTigo' ? "border-[3px] border-[#E5E5E5] bg-[#EB0728]" : "bg-white border-[1px] border-[#C1C1C1]"} w-[18px] h-[18px] rounded-full `} />
                    <h1 className=' text-[#707070]'>AirtelTigo Cash</h1>
                </div>
            </div>
            {/**form */}
            <form onSubmit={handleSubmit} method='post' className=' w-full mt-[27px]'>
                <div className=' w-full flex flex-col gap-[15px] text-[12px] leading-[14.56px] sm:text-[15px] sm:leading-[18.2px]'>
                    <input value={name} onChange={(e) => setName(e.target.value)} required className=" h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="text" placeholder="Name" />
                    <input value={number} onChange={(e) => setNumber(e.target.value)} required className=" appearance-none h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="number" placeholder="Number" />
                </div>
                <button type="submit" className=' mt-[27px] w-full h-[40px] rounded-[30px] bg-[#EB0728] text-white flex items-center justify-center text-center text-[16px] leading-[19.41px]'>Add withdrawal method</button>
            </form>
        </div>
    )
}

export default AddMobileMoney
