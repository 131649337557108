import React, { useState } from 'react'
import baseUrl from '../baseUrl.js'
import goodifly from './assets/goodifly.png'
import { DeleteFlightFalse } from '../../features/flight/deleteFlightSlice'
import {SuccessDeleteFlightTrue,SuccessDeleteFlightFalse } from '../../features/flight/successDeleteFlightSlice'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import errorIcon from './assets/error.svg'
import { sessionTrue } from '../../features/pages/sessionSlice';

const DeleteFlight = ({ access_token, flight }) => {
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = JSON.stringify({})

        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseUrl}/flight/${flight.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };
        axios.request(config)
            .then((response) => {
                if (response) {
                    // alert("Flight Listed Successfully!!!")
                    dispatch(DeleteFlightFalse())
                    dispatch(SuccessDeleteFlightTrue())
                
                    // Set a timeout for 3 seconds before dispatching SuccessEditFlightFalse
                    setTimeout(() => {
                        dispatch(SuccessDeleteFlightFalse())
                        // Reload the window after dispatching SuccessEditFlightFalse
                        window.location.reload()
                    }, 3000)
                }
                
            })
            .catch((error) => {
                if(error?.response?.data?.messages){
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                  else{
                if(error?.response?.data?.detail){
                    setErrorMessage(error?.response?.data?.detail)
                }
                else{
                
                }
            }
            });
    }
    return (
        <div>
          <div className='  shadow-input bg-white rounded-[30px] w-full xs:w-[329px] sm:w-[528px] flex flex-col items-center justify-center pt-[44px] sm:pt-[98px] pb-[44px] sm:pb-[105px]'>
                <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                {errorMessage.length > 0 ? <h1 className=' mb-2 py-[5px] flex justify-center items-center text-center rounded-[20px] bg-[#FFDFE4] border-solid border-[1px] border-[#EB0728] w-full mt-[15px] text-[12px] text-[#EB0728]'>
                    <img src={errorIcon} alt="" className='mr-[5px]' />
                    {errorMessage}
                </h1> : null}
                <h1 className=' mt-[47px] text-[18px] font-bold'>Delete</h1>
                <h1 className=' mt-[7px] text-[16px] text-[#707070]'>
                    Are you sure you want to delete the request?
                </h1>
                <div className=' mt-[15px] sm:mt-[41px] gap-[12px] sm:gap-[24px] flex'>
                    <button onClick={()=>dispatch(DeleteFlightFalse())} className=' w-[120px] h-[40px] sm:w-[171px] sm:h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>Cancel</button>
                    <button onClick={handleSubmit} className=' w-[120px] h-[40px] sm:w-[171px] sm:h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteFlight
