import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const EditCalendar = ({ type, alreadySelectedDate, showCalendar, onClose, selectDate, selectedDate, setSelectedDate, setPlaceholder }) => {
  const [tempSelectedDate, setTempSelectedDate] = useState(null);
  const selectorRef = useRef(null);

  const goToPreviousMonth = () => {
    const newSelectedDate = selectedDate.clone().subtract(1, 'month');
    setSelectedDate(newSelectedDate);
  };

  const goToNextMonth = () => {
    const newSelectedDate = selectedDate.clone().add(1, 'month');
    setSelectedDate(newSelectedDate);
  };

  const handleDoneClick = () => {
    if (tempSelectedDate) {
      selectDate(tempSelectedDate);
    }
    onClose();
    setPlaceholder(false);
  };

  const handleCancelClick = () => {
    setTempSelectedDate(null);
    onClose();
  };

  const renderCalendar = () => {
    const currentDate = moment().add(1, 'days'); // Adjust to disable dates after the next day of the current date
    const calendarDates = [];
    const month = selectedDate.clone();
    const firstDayOfMonth = month.startOf('month').format('d');
    const daysInMonth = month.daysInMonth();
    const weekdays = moment.weekdaysShort();

    const monthDates = [];
    const weekdaySubtitles = weekdays.map((weekday) => (
      <div key={weekday} className="font-bold text-[8px] text-center">
        {weekday[0]}
      </div>
    ));

    for (let i = 0; i < firstDayOfMonth; i++) {
      monthDates.push(<div key={`empty-${i}`} className="p-2 text-[#C1C1C1]"></div>);
    }

    for (let j = 1; j <= daysInMonth; j++) {
      const date = month.clone().date(j);
      // this will later be changed from 7 to 1
      let disabled = date.isBefore(moment().add(7, "days"), "day");

      // Additional disabling logic for departure and arrival scenarios
      // if (type === "departure" && alreadySelectedDate && date.isAfter(alreadySelectedDate, 'day')) {
      //   disabled = true;
      // }
       if (type === "arrival" && alreadySelectedDate && date.isBefore(alreadySelectedDate, 'day')) {
        disabled = true;
      }

      const isSelectedDate = tempSelectedDate && date.isSame(tempSelectedDate, 'day');
      const dayStyles = isSelectedDate
        ? 'p-2 text-[8px] text-white bg-blue-500 cursor-pointer'
        : `p-2 text-[8px] ${disabled ? 'text-[#C1C1C1]' : 'text-black'} cursor-pointer day`;

      monthDates.push(
        <div
          key={date.format('YYYY-MM-DD')}
          className={`${dayStyles} hover:rounded-[18px]`}
          onClick={() => !disabled && setTempSelectedDate(date)}
          style={{
            backgroundColor: isSelectedDate ? '#EB0728' : '',
            borderRadius: isSelectedDate ? '18px' : '',
            width: "18px",
            height: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
          onMouseEnter={(e) => !disabled && (e.target.style.backgroundColor = '#f1f1f1')}
          onMouseLeave={(e) => !isSelectedDate && (e.target.style.backgroundColor = '')}
        >
          {date.format('D')}
        </div>
      );
    }

    calendarDates.push(
      <div key={month.format('YYYY-MM')} className="mb-4">
        <div className="flex justify-between mb-[17px]">
          <div className="font-bold text-[10px]">{month.format('MMM YYYY')}</div>
          <div className='flex'>
            <button className="text-lg text-[#707070] cursor-pointer" onClick={goToPreviousMonth}>
              <IoIosArrowBack />
            </button>
            <button className="text-lg text-[#707070] cursor-pointer" onClick={goToNextMonth}>
              <IoIosArrowForward />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-[10px]">
          {weekdaySubtitles}
          {monthDates}
        </div>
      </div>
    );

    return calendarDates;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onClose(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className='w-full flex justify-center calendar_display absolute z-50'>
      <div className='absolute z-50 w-full'>
        <div className="relative">
          {showCalendar && (
            <div className={`${type === "arrival" ? " sm:ml-[40%]" : ""} absolute top-12 bg-white shadow-lg rounded-[30px] pt-[29px] pb-[21px] px-[25px] border-[1px] border-primary`}>
              {renderCalendar()}
              <div className="flex justify-end mt-4">
                <button className="mr-2 text-[10px]" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button className="w-[90px] h-[27px] bg-[#EB0728] rounded-[30px] flex items-center justify-center text-center text-white text-[10px]" onClick={handleDoneClick}>
                  Done
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCalendar;
