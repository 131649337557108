import React, { useState } from 'react'
import baseUrl from '../baseUrl.js'
import axios from 'axios'
import goodifly from './assets/goodifly.png'
import { useNavigate } from 'react-router-dom'
import visibility_on from './assets/visibility_on.svg'
import visibility_off from './assets/visibility_off.svg'
import errorIcon from './assets/error.svg'
import codeSentIcon from './assets/condeSent.svg'

const AdminLogin = ({setIsAdmin}) => {
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState("")
    const [email, setEmail] = useState("")
    const [passowrd, setPassowrd] = useState("")
    const [showPassword, setShowPassword] = useState(false)


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

        // Encode the data before storing it
        function encodeData(data) {
            return btoa(data); // Base64 encoding
        }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = JSON.stringify({
            email: email.toLocaleLowerCase(),
            password: passowrd
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/login/`,
            // url: `http://127.0.0.1:8000/api/login/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        try {
            axios.request(config)
                .then((response) => {
                    const data = response?.data
                    if (data?.is_staff) {
                        setIsAdmin(true)
                        window.localStorage.setItem("adiuiusgyudgsuwhywguyegerugerguyrgruygeygruyerdjhjhfjdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy", encodeData(JSON.stringify(data)))
                        window.location.reload()
                    }
                    else{
                        setErrorMessage("Invalid credentials, try again")
                    }
                })
                .catch((error) => {
                    //Catch User is not confirmed Exception 
                    // console.log(error)
                    // console.log(error?.response?.data)
                    if (error?.response?.data?.email) {
                        setErrorMessage(error?.response?.data?.email)
                    }
                    else if (error?.response?.data?.detail === 'Email is not verified') {
                        setErrorMessage("Email is not verified")
                        // setTimeout(() => {
                        //     setToVerify(true);
                        // }, 2000); 
                        // 2000 milliseconds (2 seconds)
                    }
                    else if (error?.response?.data?.detail) {
                        setErrorMessage(error?.response?.data?.detail)
                    }
                    else if (error?.response?.data?.message) {
                        setErrorMessage(error?.response?.data?.message)
                    }
                });
        } catch (error) {
            // console.log(error)
        }
    }


    return (
        <div className=' pt-[59px]  px-4 xm:px-[2.875rem]'>
            <img src={goodifly} alt="" onClick={() => navigate("/")} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[190px] sm:h-[58.29px]" />
            <div className=' w-full flex flex-col items-center justify-center text-center mt-[75px]'>
                <h1 className=' font-bold text-[25px] leading-[30.33px]'>Admin Login</h1>

                <div className=' px-[20px] sm:px-[40px] pt-[55px] pb-[58px] w-full sm:w-[550px] flex flex-col gap-[13px] bg-[#FFFFFF] mt-[21px] rounded-[30px] shadow-adminLogIn'>
                    {errorMessage.length > 0 ? <h1 className={` ${errorMessage === "Email verified successfully" ? "bg-[#D6FBC9] border-[#4FBF26] text-[#4FBF26]" : "bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]"} mb-5 py-[5px] flex justify-center items-center text-center rounded-[20px] border-solid border-[1px] w-full -mt-[20px] text-[12px]`}>
                        {errorMessage === "Email verified successfully" ?
                            <img src={codeSentIcon} alt="" className='mr-[5px]' />
                            :
                            <img src={errorIcon} alt="" className='mr-[5px]' />}
                        {errorMessage}
                    </h1> : null}
                    <form method='post' onSubmit={handleSubmit} >
                        <input required onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email' className=' w-full h-[45px] px-[22px] bg-[#E5E5E5] placeholder:text-[#707070] rounded-[60px]' />
                        {/** Password */}
                        <div className=' mt-[12px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                            <div className=' box-border flex flex-wrap w-full'>
                                <div className='w-full flex items-start'>
                                    <input value={passowrd} onChange={(e) => setPassowrd(e.target.value)} required type={showPassword ? "text" : "password"} name="password" id="password" data-cy="password" placeholder="Password" className=' m-0 text-[15px] text-[#707070] w-full font-normal bg-[#E5E5E5] placeholder:text-[#707070] h-[40px] rounded-[30px] pl-5 ' />
                                    <div onClick={togglePasswordVisibility} className='-ml-[39px] mt-[10px] cursor-pointer '>
                                        {showPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                                    </div>
                                </div>
                            </div>

                        </div>                    {/** Login button */}
                        <button type="submit" className=' mt-[13px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[1rem] w-full '>Submit</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default AdminLogin
