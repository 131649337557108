import React from 'react'
import PriceCalculator from '../priceCalculator'
import CarrierPriceCalculator from '../carrierPriceCalculator'

function convertDate(inputDate) {
  // Split the input date (assumed to be in the format YYYY-MM-DD)
  const parts = inputDate.split('-');
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  // Create a new Date object
  const date = new Date(year, month - 1, day); // month - 1 because months are 0-indexed in JavaScript Date

  // Format the date as dd/mm/yy
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(2)}`;

  return formattedDate;
}

function convertTo12HourFormat(time) {
  // Split the time into hours, minutes, and seconds
  const [hours24, minutes, seconds] = time.split(':');

  // Convert hours to 12-hour format and determine AM/PM
  const hours12 = ((parseInt(hours24) + 11) % 12) + 1;
  const amPm = parseInt(hours24) >= 12 ? 'pm' : 'am';

  // Return the formatted time
  return `${hours12}:${minutes}${amPm}`;
}

const History = ({ airports, request, setViewHistory }) => {
  const departure_airport = airports?.filter((air) => air.id === request.departure_airport)
  const arrival_airport = airports?.filter((air) => air.id === request.arrival_airport)
  if (departure_airport && arrival_airport) {
    request['departure_city'] = departure_airport[0]?.city
    request['arrival_city'] = arrival_airport[0]?.city
  }

  return(
    <div className='w-full bg-[#FFFFFF] flex flex-col gap-[20px] xf:gap-[30px] xf:items-center border-solid border-[1px] border-[#C1C1C180] p-[20px] sm:p-[30px] rounded-[20px]'>
    <div className=' w-full flex flex-col-reverse gap-5 xs:gap-0 xs:flex-row xs:justify-between xs:items-center'>
      {/**Info */}
      <div className='flex flex-col gap-1 text-start'>
        <h1 className=' font-bold text-[10px] xm:text-[16px] sm:text-[18px]'>{request.departure_city} - {request.arrival_city}</h1>
        <p className='text-[#707070] font-normal text-[10px] sm:text-[14px]'>Arrival date: <span className=' text-black'>{convertDate(request.arrival_date)}</span></p>
      </div>

      {/**Status and View Button */}
      <div className='flex items-center gap-[15px]'>
       {request?.sender_acceptance_status===1? <div className=' flex h-[20px] sm:h-[25px] w-[70px] items-center justify-center text-center font-bold text-[10px] lg:text-[14px] leading-[12.13px] lg:leading-[16.98px] border-solid border-[1px] border-[#4FBF26] bg-[#D6FBC9] text-[#4FBF26] rounded-[30px]'>Earned</div>:
       <div className=' flex h-[20px] sm:h-[25px] w-[80px] items-center justify-center text-center font-bold text-[10px] lg:text-[14px] leading-[12.13px] lg:leading-[16.98px] border-solid border-[1px] bg-[#FFDFE4] border-[#EB0728] text-[#EB0728] rounded-[30px]'>Cancelled</div>
       }
        <button onClick={() => setViewHistory(request)} className=' hidden sm:flex mt-2 xs:mt-0 w-full h-[30px] xm:w-[100px] xm:h-[35px] lg:w-[180px] lg:h-[45px] bg-[#EB0728] text-white text-[12px] md:text-[18px] font-normal rounded-[40px] items-center justify-center'>View</button>
      </div>
    </div>
    {/** */}
    <button onClick={() => setViewHistory(request)} className=' flex sm:hidden  w-full h-[30px] xm:h-[40px] bg-[#EB0728] text-white text-[18px] font-normal rounded-[40px] items-center justify-center'>View</button>
  </div>
  )

}

export default History
