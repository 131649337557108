// import React, { useEffect } from 'react'
// import successIcon from '../assets/success.png'

// const SuccessOutboundModal = ({ setViewSuccessOutboundModal, viewSuccessOutboundModal }) => {

//     const hideModal = (event) => {
//         const modal = document.getElementById('modal');
//         // Check if the clicked element is outside of the modal
//         if (event.target !== modal && !modal.contains(event.target)) {
//             setViewSuccessOutboundModal(null)
//             window.location.reload()
//         }
//     }

//     useEffect(() => {
//           const timer = setTimeout(() => {
//             setViewSuccessOutboundModal(null)
//             window.location.reload()
//           }, 3000)
//           return () => clearTimeout(timer);
//       }, [])

//     return (
//         <div>
//             <div
//                 onClick={hideModal}
//                 className="cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
//             >
//                 <div id='modal' className=" w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0 ">
//                     <div className=' xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center pl-[25px] bg-white rounded-[40px] gap-[13px]'>
//                         <img src={successIcon} alt="" />
//                         <div className='flex flex-col'>
//                             <h1 className=' text-[18px] font-bold'>Success!</h1>
//                             <h1 className='text-[14px] text-[#707077]'>{viewSuccessOutboundModal}</h1>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
//         </div>
//     )
// }

// export default SuccessOutboundModal

import React, { useEffect } from "react";
import successIcon from "../assets/success.png";

const SuccessOutboundModal = ({
  setViewSuccessOutboundModal,
  viewSuccessOutboundModal,
}) => {
  const hideModal = (event) => {
    const modal = document.getElementById("modal");
    if (event.target !== modal && !modal.contains(event.target)) {
      setViewSuccessOutboundModal(null);
      window.location.reload();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setViewSuccessOutboundModal(null);
      window.location.reload();
    }, 3000);

    return () => clearTimeout(timer);
  }, [setViewSuccessOutboundModal]); // Add setViewSuccessOutboundModal to the dependency array

  return (
    <div>
      <div
        onClick={hideModal}
        className="cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none scrollbar-hide"
      >
        <div
          id="modal"
          className=" w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0 "
        >
          <div className="xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center pl-[25px] bg-white rounded-[40px] gap-[13px]">
            <img src={successIcon} alt="" />
            <div className="flex flex-col">
              <h1 className=" text-[18px] font-bold">Success!</h1>
              <h1 className="text-[14px] text-[#707077]">
                {viewSuccessOutboundModal}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  );
};

export default SuccessOutboundModal;
