// import React, { useEffect, useState } from 'react'
// import CancelRequest from './CancelRequest';

// const CancelRequestModal = ({access_token, cancelRequest, setCancelRequest}) => {
//     const [confirm, setConfirm] = useState(null)
//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth' // For smooth scrolling
//         });
//     };
    
//     const hideModal = (event) =>{
//         const modal = document.getElementById('modal');
//         // Check if the clicked element is outside of the modal
//         if (event.target !== modal && !modal.contains(event.target)) {
//             setCancelRequest(null); // Call the function to close the modal
//           if(confirm){
//             window.location.reload()
//           }
//         }
//     }
//     useEffect(() => {
//         scrollToTop()
//     }, []);
//     return (
//         <div>
//             <div
//                 onClick={hideModal}
//                 className=" cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
//             >
//                 <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 ">
//                     {/*content*/}
//                     <div className=" cursor-default border-0  relative flex flex-col w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] outline-none focus:outline-none">
//                         <CancelRequest access_token={access_token} cancelRequest={cancelRequest} setCancelRequest={setCancelRequest} confirm={confirm} setConfirm={setConfirm}/>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
//         </div>
//     )
// }

// export default CancelRequestModal

import React, { useEffect, useState, useCallback } from "react";
import CancelRequest from "./CancelRequest";

const CancelRequestModal = ({
  access_token,
  cancelRequest,
  setCancelRequest,
}) => {
  const [confirm, setConfirm] = useState(null);

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling
    });
  }, []);

  // Hide the modal when clicking outside of it
  const hideModal = useCallback(
    (event) => {
      const modal = document.getElementById("modal");
      if (modal && !modal.contains(event.target)) {
        setCancelRequest(null); // Close the modal
        if (confirm) {
          window.location.reload();
        }
      }
    },
    [confirm, setCancelRequest]
  );

  return (
    <div>
      <div
        onClick={hideModal}
        className="cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none"
        role="dialog"
        aria-modal="true"
      >
        <div id="modal" className="relative my-6 mx-[2%] sm:mx-0">
          <div className="cursor-default border-0 relative flex flex-col w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] outline-none focus:outline-none">
            <CancelRequest
              access_token={access_token}
              cancelRequest={cancelRequest}
              setCancelRequest={setCancelRequest}
              confirm={confirm}
              setConfirm={setConfirm}
            />
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  );
};

export default CancelRequestModal;
