import React from 'react'
import {convertDate, convertTo12HourFormat} from '../../ConvertDateAndTime'

const CompleteOutbound = ({item, setViewSednerRecipientOutboundViewModal, setViewMarketPlaceOutboundViewModal}) => {
    const handleSednerRecipientOutboundViewModal = (item) =>{
        setViewMarketPlaceOutboundViewModal(null)
        setViewSednerRecipientOutboundViewModal(item)
    }
    const handleMarketPlaceOutboundViewModal = (item) =>{
        setViewSednerRecipientOutboundViewModal(null)
        setViewMarketPlaceOutboundViewModal(item)
    }
    return (
        <div className=' w-full h-[80px] flex items-center border-solid border-b-[1px] border-b-[#C1C1C180]'>
            <div className=' w-full flex items-center text-[14px] text-[#707070] leading-[16.98px] px-[19px]'>
                <div className=' w-[12.5%] font-bold text-[18px] leading-[21.83px]'>{item?.sender_name?.split(' ')[0]}</div>
                <div className=' w-[12.5%]'>{item?.from_city_code} - {item?.arrival_code}</div>
                <div className=' w-[12.5%] flex flex-col gap-[5px]'>
                    <p>{convertDate(item?.departure_date)}</p>
                    <p>{convertTo12HourFormat(item?.depart_time)}</p>
                </div>
                <div className=' w-[12.5%] flex flex-col gap-[5px]'>
                    <p>{convertDate(item?.arrival_date)}</p>
                    <p>{convertTo12HourFormat(item?.arrival_time)}</p>
                </div>
                <div className=' w-[12.5%]'>{item?.request_type===1?"Send":item?.request_type===2?"Receive": item?.request_type===3? "Marketplace":null}</div>
                <div className=' w-[12.5%]'>{item?.carrier_name}</div>
                <div className=' w-[12.5%]'>
                    {item?.request_type===3?
                    <button onClick={() => handleMarketPlaceOutboundViewModal(item)} className='w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]'>View</button>
                    :
                    <button onClick={() => handleSednerRecipientOutboundViewModal(item)} className='w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]'>View</button>}
                </div>
            </div>
        </div>
    )
}

export default CompleteOutbound
