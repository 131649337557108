import React from 'react'
import goodifly from './assets/goodifly.png'
import correct from './assets/correct.svg'

const CongratsForJoining = () => {
  return (
    <div className=' flex flex-col items-center justify-center text-center bottom-0 w-full xs:w-[350px]  xm:w-[500px] sm:w-[528px] rounded-[30px] shadow-input bg-white pt-[30px] xm:pt-[65px] pb-[79px] px-8 xm:px-[2.875rem] relative'>
      <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
      <img src={correct} alt="" className=' mt-[34px] w-[35px] h-[35px]' />
      <h1 className=' mt-[15px] font-bold text-[18px] leading-[21.83px]'>Thank you for joining!</h1>
      <h1 className=' mt-3 xm:w-[410px] text-[#707070] text-[16px] leading-[19.41px]'>Our team will get in touch with you shortly to provide more
        information and ensure you have the best experience
        on Goodifly.</h1>
    </div>
  )
}

export default CongratsForJoining
