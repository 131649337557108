import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberValidation.css'
import ghana from './assets/ghana.svg'

const PhoneNumberValidation = ({ value, setMobileNumber }) => {
  const num = value?.length>0?value :"233"
  const [phoneNumber, setPhoneNumber] = useState(num);
  //   const [valid, setValid] = useState(true);

  const handleChange = (value) => {
    setPhoneNumber(value);
    setMobileNumber(value)
    // setValid(validatePhoneNumber(value));
  };

  //   const validatePhoneNumber = (phoneNumber) => {
  //     const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

  //     return phoneNumberPattern.test(phoneNumber);
  //   };

  return (
    <div className={ `w-full`}>
    <div className={` ${phoneNumber.length > 0 ? "hidden" : " flex w-fit absolute z-[10] pt-[12px] ml-[23px]"}`}>
        <img src={ghana} alt="" />
        </div>
      <label>
        <PhoneInput
          buttonStyle={{ "borderTopLeftRadius": "50px", "borderBottomLeftRadius": "50px", "backgroundColor": "#D9D9D9", "width": "70px", "display": "flex", "alignItems": "center", "justifyContent": "center",  'border': 'none' }}
          inputStyle={{ "color":"#707070", "backgroundColor": "#E5E5E580", "width": "100%", "display": "flex", "borderRadius": "50px", "height": "40px", "paddingLeft": "83px" , 'border': 'none', "fontSize":"16px","lineHeight":"19.41px"}}
          dropdownStyle={{ "marginTop": "260px", "marginLeft": "240px" }}
          value={phoneNumber}
          onChange={handleChange}
          inputProps={{
            required: true,
          }}
          placeholder='233 50 567 990'
        />
      </label>
      {/* {!valid && alert("Please enter a valid phone number.")} */}
    </div>
  );
};

export default PhoneNumberValidation;