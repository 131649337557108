import React from 'react'
import goodifly from '../../assets/goodifly.png'
import baseUrl from '../../../../components/baseUrl'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { sessionTrue } from '../../../../features/pages/sessionSlice';


const DeleteBankTransfer = ({ bankAdded, access_token, setDeleteBankAdded, setBankAdded, setAddWithrawalMethod, setManageSucess }) => {
    const dispatch = useDispatch()
    const handleCnacel = () => {
        setDeleteBankAdded(false)
        setAddWithrawalMethod(true)
    }

    const handleDelete = () => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseUrl}/withdrawal-method/${bankAdded?.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: {}
        };

        axios.request(config)
            .then((response) => {
                if (response?.data) {
                    setBankAdded(null)
                    setDeleteBankAdded(false)
                    setManageSucess("Delete")
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                else {
                    if(error?.response?.data?.detail==="Not found."){
                        setBankAdded(null)
                        setDeleteBankAdded(false)
                        setManageSucess("Delete")
                    }
                    else{
                    console.log(error)
                    
                    }
                }
            });

    }


    return (
        <div>
            <div className='  shadow-input bg-white rounded-[30px] w-full xs:w-[329px] sm:w-[528px] flex flex-col items-center justify-center pt-[44px] sm:pt-[98px] pb-[44px] sm:pb-[105px]'>
                <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                <h1 className=' mt-[47px] text-[18px] font-bold'>Delete</h1>
                <h1 className=' mt-[7px] text-[16px] text-[#707070]'>
                    Are you sure you want to delete this withdrawal
                    method
                </h1>
                <div className=' mt-[15px] sm:mt-[41px] gap-[12px] sm:gap-[24px] flex'>
                    <button onClick={() => handleCnacel()} className=' w-[120px] h-[40px] sm:w-[171px] sm:h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>Cancel</button>
                    <button onClick={() => handleDelete()} className=' w-[120px] h-[40px] sm:w-[171px] sm:h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteBankTransfer
