import React from 'react'
import { DeleteFlightFalse } from '../../features/flight/deleteFlightSlice'
import { useDispatch } from 'react-redux'
import DeleteFlight from './DeleteFlight'

const DeleteFlightModal = ({access_token, flight }) => {
    const dispatch = useDispatch()


    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            dispatch(DeleteFlightFalse()); // Call the function to close the modal
        }
    }

    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id="modal" className="relative my-6 mx-[2%] sm:mx-0 ">
                    {/*content*/}
                    <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <DeleteFlight access_token={access_token} flight={flight} />
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default DeleteFlightModal
