import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const TimePicker = ({ type, showPicker, onClose, selectTime }) => {
  const [selectedTime, setSelectedTime] = useState(moment().format('HH:mm:ss'));
  const selectorRef = useRef(null);

  const handleTimeChange = (e) => {
    const { value } = e.target;
    const newTime = moment(value, 'HH:mm');
    if (newTime.isValid()) {
      setSelectedTime(newTime.format('HH:mm:ss'));
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onClose(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  const handleDoneClick = () => {
    selectTime(selectedTime);
    onClose();
  };

  return (
    <div className={` ${type=="departure"?"justify-start":" justify-center ml-[10%]"} w-full flex time-picker absolute z-50`}>
      {showPicker && (
        <div  className="absolute flex flex-col top-12 bg-white shadow-lg rounded-[30px] p-4 border-[1px]">
          <input
            type="time"
            value={selectedTime}
            onChange={handleTimeChange}
            className="text-[10px] border rounded mb-4"
          />
          <div className="flex justify-end gap-4">
            <button className="mr-2 text-[10px]" onClick={onClose}>
              Cancel
            </button>
            <button className="w-[90px] h-[27px] bg-[#EB0728] rounded-[30px] flex items-center justify-center text-center text-white text-[10px]" onClick={handleDoneClick}>
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
