import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";

const Selector = ({ options, setCity, placeholder, style }) => {
  const [data, setData] = useState(options);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const selectorRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setCity(value)
    if(value){
      setOpen(true)
    }
  };

  const hideSelector = (event) => {
    const modal = document.getElementById('modal');
    // Check if the clicked element is outside of the modal
    if (event.target !== modal && !modal.contains(event.target)) {
      setOpen(!open)
    }
  }

  // useEffect(() => {
  //   // fetch("https://restcountries.com/v2/all?fields=name")
  //   //   .then((res) => res.json())
  //   //   .then((data) => {
  //   //     setCountries(data);
  //   //   });
  //   const filteredOptions = options?.filter(
  //     (option) => option?.name.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  //   setData(filteredOptions);
  // }, [inputValue, options]);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    const filteredOptions = options?.filter(
      (option) => option?.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setData(filteredOptions);
  }, [inputValue, options]);

  return (
    <div ref={selectorRef} className={`relative  sm:w-[12.75rem] md:w-[14.75rem] lg:w-[16.75rem] xl:w-[18.75rem] cursor-pointer ` } >
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className={` ${!selected && "text-gray-700"
          }`}
      >
        <div className=' flex items-center w-full sm:w-auto drop-shadow-input sm:shadow-none border-solid bg-white border-[1px] border-[#C1C1C180] rounded-[40px] h-[40px] px-[17px] sm:px-0 sm:rounded-none sm:border-none'>

        <input
          type="text"
          id="input"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="text-[#AAAAAA] font-normal text-[14px] leading-[16.98px]  outline-none w-full bg-transparent"
        />
         
         </div>
      </div>
      {open || inputValue.length>0 ?
        <div>
          <div
            onClick={hideSelector}
            className=" flex flex-col mr-[29px] cursor-pointer  h-full justify-center flex overflow-x-hidden overflow-y-auto z-[70] outline-none focus:outline-none scrollbar-hide"
          >
            <ul

              id='modal'
              className={` ${!open?"hidden":""} mt-[9px] py-[22px]  rounded-[20px]  top-full w-full  sm:w-[12.75rem] md:w-[14.75rem] lg:w-[16.75rem] xl:w-[18.75rem]  absolute shadow-input bg-white overflow-y-auto z-50 ${open ? data?.length < 6 ? "max-h-fit" : "h-[241px]" : "max-h-0"
                }`}
            >
              {data && data.length > 0 ? data?.map((item) => (
                <li

                  className={`py-[7px]  px-[22px] text-[14px] font-normal leading-[16.98px] hover:bg-[#E5E5E580] hover:text-white
         ${item?.name.toLowerCase() === selected?.toLowerCase() &&
                    "bg-[#E5E5E580] text-[#000000] h-[31px]"
                    }
         ${item?.name.toLowerCase().includes(inputValue)
                      ? "block"
                      : !open || inputValue.length===0  ? "hidden" : "block"
                    }`}
                  onClick={() => {
                    if (item?.name.toLowerCase() !== selected.toLowerCase()) {
                      setSelected(item?.name);
                      setOpen(false);
                      setInputValue(item?.name);
                      setCity(item?.name)
                    } else {
                      setOpen(false);
                      setSelected(item?.name);
                      setInputValue(item?.name);
                      setCity(item?.name)
                    }
                  }}
                >
                  {item?.name}
                </li>
              )) :
                <li className="p-2 text-sm text-[#EB0728]">no results found</li>
              }
            </ul>
          </div>
          <div className="opacity-70 fixed z-[60] bg-black"></div>
        </div>
        : ""

      }
      
    </div>
  );
};
export default Selector;
