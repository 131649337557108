import React, { useState } from 'react'
import goodifly from "../../assets/goodifly.png"
import BankSelector from '../BankSelector'
import baseUrl from '../../../../components/baseUrl'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { sessionTrue } from '../../../../features/pages/sessionSlice';


const AddBankTransfer = ({ user, banks, whereTo, access_token, setAddWithrawalType, setBankAdded, setAddWithrawalMethod, setNoMethod }) => {
    const dispatch = useDispatch()
    const [accountName, setAccountName] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [type, setType] = useState('')
    // const [bankBranch, setBankBranch] = useState('')
    const [bankCode, setBankCode] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        const data = JSON.stringify({
            account_name: accountName,
            name: bankName,
            account_number: accountNumber,
            code: bankCode,
            type: type,
            user: user,
            // bank_branch:bankBranch,
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/withdrawal-method/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                if (response?.data) {
                    setBankAdded(response?.data)
                    if (whereTo === "Manage") {
                        setAddWithrawalMethod(true)
                    }
                    else if (whereTo === "Withdrawal") {
                        setNoMethod(true)
                    }

                    setAddWithrawalType(null)
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                  }
                else {
                    console.log(error)
                    
                }
            });
    }
    return (
        <div className=' px-[25px] sm:px-[45px] bg-white rounded-[30px] w-full h-full flex flex-col items-center pt-[34px] sm:pt-[39px] pb-[25px] sm:pb-[47px]'>
            <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
            <h1 className=' mt-[23px] font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] '>Withdrawal method</h1>
            <h1 className=' mt-[5px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>Bank Transfer</h1>
            <form onSubmit={handleSubmit} method='post' className=' w-full mt-[29px]'>
                <div className=' w-full flex flex-col gap-[15px] text-[12px] leading-[14.56px] sm:text-[15px] sm:leading-[18.2px]'>
                    <input value={accountName} onChange={(e) => setAccountName(e.target.value)} required className=" h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="text" placeholder="Account Name" />
                    {/* <input value={bankName} onChange={(e) => setBankName(e.target.value)} required className=" h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="text" placeholder="Bank Name" /> */}
                    <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                        <div className=' box-border flex flex-wrap w-full'>
                            <div className={`w-full text-left flex items-start`}>
                                <BankSelector placeholder="Bank Name" setData={setBankName} bankName={bankName} setCode={setBankCode} setType={setType} options={banks} />
                            </div>
                        </div>
                    </div>
                    <input value={accountNumber} onChange={(e) => {if (e.target.value >=0) { setAccountNumber(e.target.value)}}} required className=" h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="number" placeholder="Account Number" />
                    {/* <input value={bankBranch} onChange={(e)=>setBankBranch(e.target.value)} required className=" h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="text" placeholder="Bank Branch" />
                    <input value={bankCode} onChange={(e)=>setBankCode(e.target.value)} required className=" h-[40px] placeholder:text-[#707070] bg-[#E5E5E5] flex items-center px-[20px] rounded-[30px] w-full outline-none" type="text" placeholder="Bank Code" /> */}
                </div>
                <button type="submit" className=' mt-[33px] w-full h-[40px] rounded-[30px] bg-[#EB0728] text-white flex items-center justify-center text-center text-[16px] leading-[19.41px]'>Add withdrawal method</button>
            </form>

        </div>
    )
}

export default AddBankTransfer
