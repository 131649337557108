import React from 'react'
import ViewFlightHistory from './ViewFlightHistory';

const ViewFlightHistoryModal = ({viewFlightHistory, setViewFlightHistory}) => {

    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setViewFlightHistory(null) // Call the function to close the modal
        }
    }
    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer pt-[200px] xl:pt-0  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 w-full xm:w-[80%] sm:w-auto ">
                    {/*content*/}
                    <div className=" cursor-default border-0 rounded-[30px] relative flex flex-col w-full sm:w-[518px] bg-white outline-none focus:outline-none">
                        <ViewFlightHistory viewFlightHistory={viewFlightHistory} />
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default ViewFlightHistoryModal
