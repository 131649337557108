import React from 'react'
import successIcon from './assets/success.svg'

const SuccessEditFlight = () => {
  return (
    <div className=' xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center pl-[25px] bg-white rounded-[40px] gap-[13px]'>
      <img src={successIcon} alt="" />
      <div className='flex flex-col'>
            <h1 className=' text-[18px] font-bold'>Success!</h1>
            <h1 className='text-[14px] text-[#707077]'>Your flight has been successfully edited</h1>
      </div>
    </div>
  )
}

export default SuccessEditFlight
