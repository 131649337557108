import React, { useState } from 'react'
import ConfirmItemDelivered from './ConfirmItemDelivered';

const ConfirmItemDeliveredModal = ({access_token, item, setConfirmItemDelivered, setViewAcceptedRequest}) => {
    const [delivered, setDelivered] = useState(null)
    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
          setConfirmItemDelivered(null); // Call the function to close the modal
          if(delivered){
            window.location.reload()
          }

        }
    }
    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 ">
                    {/*content*/}
                    <div className=" cursor-default border-0  relative flex flex-col w-full xs:w-[329px] xm:w-[418px] sm:w-[518px] outline-none focus:outline-none">
                        <ConfirmItemDelivered delivered={delivered} setDelivered={setDelivered} access_token={access_token} item={item} setConfirmItemDelivered={setConfirmItemDelivered} setViewAcceptedRequest={setViewAcceptedRequest} />
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default ConfirmItemDeliveredModal

