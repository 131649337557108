import React, { useState } from 'react'
import JoinCommunity from './JoinCommunity'
import CongratsForJoining from './CongratsForJoining'

const JoinCommunityModal = ({setCommunityModal}) => {
    const [congrat, setCongrat] = useState(false)
    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setCommunityModal(false)// Call the function to close the modal
        }
    }
    return (
        <div>
            <div
            onClick={hideModal}
                className=" cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 xm:mx-[2%] sm:mx-0 ">
                    {/*content*/}
                       {congrat? <CongratsForJoining/>: 
                                           <div className="cursor-default bottom-0 left-0 xm:bottom-auto xm:left-auto mb-[-300px] xm:mb-0 relative rounded-t-[30px] xm:rounded-[30px] border-0 rounded-[30px] shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none"><JoinCommunity setCongrat={setCongrat} /></div>}
                </div>
            </div>
            <div className=" opacity-60 xm:opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default JoinCommunityModal
