import PriceCalculator from "./priceCalculator"

const TotalSenderPrice = (weight) => {
const luggageSpaceAmount = PriceCalculator(weight).senderCost

const serviceCharge = (0.24*(PriceCalculator(weight).senderCost-50)) 

const totalCharge = parseFloat(luggageSpaceAmount) + parseFloat(serviceCharge)
return totalCharge.toFixed(2)
}
export default TotalSenderPrice