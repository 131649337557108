import React from 'react'
import goodifly from "../../assets/goodifly.png"
import bank from '../../assets/bank.svg'
import momo from '../../assets/momo.svg'
import vodaCash from '../../assets/vodaCash.jpg'
import airtelTigo from '../../assets/airtelTigo.jpg'
import editIcon from '../../assets/edit.svg'
import deleteIcon from '../../assets/delete.svg'

const AddWithrawalMethod = ({setWhereTo, access_token,setAddWithrawalMethod, setAddWithrawalType, bankAdded, mobileMoneyAdded, setEditBankAdded, setEditMobileMoneyAdded, setDeleteBankAdded, setDeleteMobileMoney }) => {
   const handleDeleteBank = () =>{
    setAddWithrawalMethod(false)
    setDeleteBankAdded(true)
   }
   const handleDeleteMobileMoney = () =>{
    setAddWithrawalMethod(false)
    setDeleteMobileMoney(true)
   }

    return (
        <div className=' px-5 sm:px-[50px] bg-white rounded-[30px] w-full sm:h-full flex flex-col items-center pt-[44px] sm:pt-[48px] pb-[44px] sm:pb-[105px]'>
            <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
            <h1 className=' mt-[23px] font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] '>Withdrawal methods</h1>
            <h1 className=' mt-[7px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>{bankAdded !== null && mobileMoneyAdded !== null ? "Manage withdrawal methods" : "Add a new withdrawal method"}</h1>

            <div className={` ${bankAdded !==null && mobileMoneyAdded !== null?"mb-[37px]":" mb-[20px]"} mt-[37px] w-full flex flex-col gap-[25px]`}>
            {bankAdded !== null ?
                <div className='w-full flex justify-between'>
                    <div className='flex gap-[33px]'>
                        <img src={bank} alt="" className=' w-[24px] h-[24px]' />
                        <div className=' text-left flex flex-col gap-[2px]'>
                            <h1 className=' font-bold text-[16px] leading-[19.41px]'>{bankAdded.name}</h1>
                            <h1 className=' text-[#AAAAAA] text-[12px] leading-[14.56px]'>{bankAdded.account_name}</h1>
                        </div>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button onClick={()=>{ setAddWithrawalMethod(false); setEditBankAdded(true)}}><img src={editIcon} alt="" className=' w-[22.5px] h-[22.5px]' /></button>
                        <button onClick={()=>handleDeleteBank()}><img src={deleteIcon} alt="" className=' w-[20px] h-[22.5px]' /></button>
                    </div>
                </div>
                : null}
            {mobileMoneyAdded !== null ?
                <div className='w-full flex items-center justify-between'>
                    <div className='flex items-center gap-[25px]'>
                        {mobileMoneyAdded?.name==="MTN"?<img src={momo} alt="" className=' w-[40px] h-[40px]' />:mobileMoneyAdded?.name==="Vodafone"?<img src={vodaCash} alt="" className=' w-[40px] h-[40px]' />:<img src={airtelTigo} alt="" className=' object-contain w-[40px] h-[40px]' />}
                        <div className=' text-left flex flex-col gap-[2px]'>
                            <h1 className=' font-bold text-[16px] leading-[19.41px]'>{mobileMoneyAdded.name}</h1>
                            <div className='flex gap-[10px] flex-wrap'>
                                <h1 className=' font-bold text-[12px] leading-[14.56px]'>{mobileMoneyAdded.account_number}</h1>
                                <h1 className=' text-[#AAAAAA] text-[12px] leading-[14.56px]'>{mobileMoneyAdded.account_name}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button onClick={()=>{{ setAddWithrawalMethod(false); setEditMobileMoneyAdded(true);}}}><img src={editIcon} alt="" className=' w-[22.5px] h-[22.5px]' /></button>
                        <button onClick={()=>handleDeleteMobileMoney()}><img src={deleteIcon} alt="" className=' w-[20px] h-[22.5px]' /></button>
                    </div>
                </div> : null}
            </div>
            {bankAdded === null ? <button onClick={() => { setAddWithrawalMethod(false); setAddWithrawalType("Bank Transfer"); setWhereTo("Manage"); }} className=' mt-[35px] flex items-center justify-center text-center w-full h-[50px]  sm:h-[68px] border-[1px] border-solid border-[#C1C1C1] rounded-[20px] text-[#C1C1C1] font-bold text-[12px] leading-[14.56px]'>+ ADD BANK TRANSFER</button> : null}
            {mobileMoneyAdded === null ? <button onClick={() => { setAddWithrawalMethod(false); setAddWithrawalType("Mobile Money"); setWhereTo("Manage"); }} className=' mt-[12px] flex items-center justify-center text-center w-full h-[50px]  sm:h-[68px] border-[1px] border-solid border-[#C1C1C1] rounded-[20px] text-[#C1C1C1] font-bold text-[12px] leading-[14.56px]'>+ ADD MOBILE MONEY</button> : null}
        </div>
    )
}

export default AddWithrawalMethod
