import React, { useEffect, useState } from 'react'
import baseUrl from './components/baseUrl';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from './pages/About/About'
import Faq from './pages/FAQ/Faq'
import CustomerDashboard from './pages/Dashboard/CustomerDashboard';
import CarrierDashboard from './pages/Dashboard/CarrierDashboard';
import axios from 'axios';
import Settings from './pages/Settings/Settings';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Contact from './pages/Contact/Contact';
import Terms from './pages/Terms/Terms';
import Earnings from './pages/Earnings/Earnings';
import Admin from './pages/Admin/Admin';
import { useSelector } from 'react-redux';
import SessionModal from './components/Session/SessionModal'
import { useDispatch } from 'react-redux';
import { sessionTrue } from './features/pages/sessionSlice';
import BookFlight from './pages/Booking/BookFlight';
import Footer from './components/Footer/Footer'

function App() {
  const [airports, setAirports] = useState();
  const dispatch = useDispatch();

  const sessionExpired = useSelector((state) => state.session.value);

  // Variable for sending and Receiving a package
  const [packageDirection, setPackageDirection] = useState(null);

  // useEffect(() => {
  //   axios.get(`${baseUrl}/airport/`).then((response) => {
  //     const sortedOptions = [...response.data].sort((a, b) => {
  //       return a.name.localeCompare(b.name);
  //     });
      // setAirports(sortedOptions);
  //   });
  // }, []);

useEffect(() => {
  axios
    .get(`${baseUrl}/airport/`)
    .then((response) => {
      const sortedOptions = response.data
        .filter((airport) => airport.name) 
        .sort((a, b) => a.name.localeCompare(b.name));
      setAirports(sortedOptions);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}, []);

  useEffect(() => {
    // Function to clear local storage
    const clearLocalStorage = () => {
      dispatch(sessionTrue());
    };

    // Set a timeout to clear local storage after 50 minutes
    const timeoutId = setTimeout(clearLocalStorage, 50 * 60 * 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId); // Clear the timeout
      window.removeEventListener("beforeunload", clearLocalStorage); // Remove the event listener
    };
  }, []);

  return (
    <div>
      {sessionExpired === true ? <SessionModal /> : null}
      <BrowserRouter>
        <Routes>
          <Route index element={<Home airports={airports} />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="/dashboard"
            element={<CustomerDashboard airports={airports} packageDirection={packageDirection} setPackageDirection={setPackageDirection} />}
          />
          <Route
            path="/dashboard/carrier"
            element={<CarrierDashboard airports={airports} />}
          />
          <Route path="/settings" element={<Settings airports={airports} />} />
          <Route path="/earnings" element={<Earnings airports={airports} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/booking" element={<BookFlight airports={airports} />} />
        </Routes>
      </BrowserRouter>

    </div>
    
  );

}

export default App;
