import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}


export const flightListSuccess = createSlice({
    name: 'flightListSuccess',
    initialState,
    reducers: {
      FlightListSuccessTrue:(state)=>{
        state.value=true
      },
      FlightListSuccessFalse:(state)=>{
        state.value=false
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { FlightListSuccessTrue, FlightListSuccessFalse} = flightListSuccess.actions
  
  export default flightListSuccess.reducer