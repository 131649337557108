import React from 'react'
import goodifly from "../assets/goodifly.png"

const RequestInfo = ({ requestInfo }) => {
    return (
        <div className=' flex flex-col items-center'>
            <div className=' pt-[38px] pb-[22px] px-[30px] sm:px-[50px] bg-white rounded-t-[30px] w-full flex flex-col items-center gap-[29px]'>
                <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />

                {/**Name and Email*/}
                <div className='flex flex-col gap-1'>
                    <h1 className=' font-bold text-[18px] leading-[21.83px]'>{requestInfo?.first_name} {requestInfo?.last_name}</h1>
                    <h1 className=' text-[#AAAAAA] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>{requestInfo?.email}</h1>
                </div>

                {/**Address and Phone number */}
                <div className=' w-full flex flex-col sm:flex-row gap-[29px] sm:gap-1 items-center justify-between'>
                    <div className='flex flex-col gap-1'>
                        <h1 className=' text-[#AAAAAA] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>Address</h1>
                        <h1 className=' font-bold text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]'>{requestInfo?.address}</h1>
                    </div>

                    <div className='flex flex-col gap-1'>
                        <h1 className=' text-[#AAAAAA] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>Phone number</h1>
                        <h1 className=' font-bold text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]'>{requestInfo?.phone_number}</h1>
                    </div>
                </div>
            </div>

            {/** Other Details*/}
            <div className=' pt-[37px] pb-[35px] px-[30px] sm:px-[50px] bg-[#E5E5E5] rounded-b-[30px] w-full flex flex-col items-center gap-[35px]'>
                <div className='w-full flex flex-col gap-[10px]'>
                    <div className='w-full flex flex-col xs:flex-row gap-2 xs:gap-1 justify-between'>
                        <h1 className=' text-[#707070] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>Account name</h1>
                        <h1 className=' font-bold text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]'>{requestInfo?.account_name}</h1>
                    </div>
                    <div className='w-full flex flex-col xs:flex-row gap-2 xs:gap-1 justify-between'>
                        <h1 className=' text-[#707070] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>Account number</h1>
                        <h1 className=' font-bold text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]'>{requestInfo?.account_number}</h1>
                    </div>
                    <div className='w-full flex flex-col xs:flex-row gap-2 xs:gap-1 justify-between'>
                        <h1 className=' text-[#707070] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>{requestInfo?.type === "ghipss" ? "Bank name" : "Account type"}</h1>
                        <h1 className=' font-bold text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]'>{requestInfo?.bank_name}</h1>
                    </div>
                </div>

                <div className=' w-full h-[45px] sm:h-[68px] flex flex-col xf:flex-row gap-2 xs:gap-1 items-center justify-between xs:px-[20px] sm:px-[30px] rounded-[20px] xs:border-solid xs:border-[1px] xs:border-[#C1C1C1]'>
                    <h1 className=' text-[#707070] text-[12px] sm:text-[14px] sm:leading-[16.98px]'>Amount requested</h1>
                    <h1 className=' font-bold text-[16px] sm:text-[20px] md:text-[25px] md:leading-[30.33px]'>${requestInfo?.withdrawal_amount}</h1>
                </div>
            </div>
        </div>
    )
}

export default RequestInfo
