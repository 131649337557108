import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer'
import CarrierNav from '../../components/Nav/CarrierNav';
import ListFlightModal from '../../components/Flight/ListFlightModal';
import { useSelector } from 'react-redux';
import AddWithrawalMethodModal from './MangeWithdrawal/AddWithrawalMethod/AddWithrawalMethodModal';
import AddBankTransferModal from './MangeWithdrawal/AddBankTransfer/AddBankTransferModal';
import AddMobileMoneyModal from './MangeWithdrawal/AddMobileMoney/AddMobileMoneyModal';
import EditBankTransferModal from './MangeWithdrawal/EditBankTransfer/EditBankTransferModal';
import EditMobileMoneyModal from './MangeWithdrawal/EditMobileMoney/EditMobileMoneyModal';
import ManageWithdrawalSuccessfulModal from './MangeWithdrawal/ManageWithdrawalSuccessfulModal';
import DeleteMobileMoneyModal from './MangeWithdrawal/DeleteMobileMoney/DeleteMobileMoneyModal';
import DeleteBankTransferModal from './MangeWithdrawal/DeleteBankTransfer/DeleteBankTransferModal';
import NoMethodModal from './Withdrawal/NoMethod/NoMethodModal';
import ReviewMethodModal from './Withdrawal/ReviewMethod/ReviewMethodModal';
import SuccessWithdrawalModal from './Withdrawal/SuccessWithdrawal/SuccessWithdrawalModal';
import baseUrl from '../../components/baseUrl';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../features/pages/sessionSlice';

// const temporal_earningData = [
//     { method: 'Bank Transfer', date: '25/08/2023', status: 'Paid', amount: '$950' },
//     { method: 'Mobile money', date: '25/08/2023', status: 'Processing', amount: '$1,150' },
//     { method: 'Mobile money', date: '25/08/2023', status: 'Cancelled', amount: '$110' },
// ]
function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns a zero-based index
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

const Earnings = ({ airports }) => {
    const dispatch = useDispatch()
    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [carrierID, setCarrierID] = useState("")
    const [username, setUsername] = useState("")
    // const [userCity, setUserCity] = useState("")
    // const [userCountry, setUserCountry] = useState("")
    const [access_token, setAccessToken] = useState()
    // const [refresh_token, setRefreshToken] = useState()

    //Earnings
    const [earningData, setEarningData] = useState(null)
    const [totalBalance, setTotalBalance] = useState()
    const [totalEarning, setTotalEarning] = useState()
    const [totalWithrawal, setTotalWithrawal] = useState()

    const [amountWithdrawn, setAmountWithdrawn] = useState()

    // Add withrawal method
    const [addWithrawalMethod, setAddWithrawalMethod] = useState(false)
    const [bankAdded, setBankAdded] = useState(null)
    const [mobileMoneyAdded, setMobileMoneyAdded] = useState(null)

    const [editBankAdded, setEditBankAdded] = useState(false)
    const [editMobileMoneyAdded, setEditMobileMoneyAdded] = useState(false)

    const [deleteBankAdded, setDeleteBankAdded] = useState(false)
    const [deleteMobileMoney, setDeleteMobileMoney] = useState(false)

    const [manageSuccess, setManageSucess] = useState(null)

    // Add clicked
    const [addWithrawalType, setAddWithrawalType] = useState(null)


    // Withrawal
    const [noMethod, setNoMethod] = useState(false)
    // Review
    const [reviewWithdrawalMethod, setReviewWithdrawalMethod] = useState(null)
    // Success withdrawal
    const [successWithdrawal, setSuccessWithdrawal] = useState(false)

    // where to {Manage, or Withdrawal}
    const [whereTo, setWhereTo] = useState(null)

    //listFlightModal
    const listFlightModal = useSelector((state) => state.listFlight.value)



    const [banks, setBanks] = useState(null)
    const [networks, setNetworks] = useState(null)

    // Decode the data after retrieving it
    function decodeData(encodedData) {
        return atob(encodedData); // Base64 decoding
    }
    const handleManageWithrawalMethod = () => {
        setAddWithrawalMethod(true)
    }
    const handleWithdrawal = () => {
        setAddWithrawalMethod(false)
        setNoMethod(true)
    }

    const fetchWithdrawalMethod = async (token) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseUrl}/withdrawal-method/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {}
        };

        try {
            axios.request(config)
                .then((response) => {
                    if (response?.data) {
                        response?.data.map(item => {
                            if (item?.type === "ghipss") {
                                setBankAdded(item)
                            } else {
                                setMobileMoneyAdded(item)
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.messages) {
                        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                            dispatch(sessionTrue())

                        }
                    }
                    else {
                        console.log(error)
                        
                    }
                });

        } catch (error) {
            console.log(error)
        }
    }

    const fetchFinancialSummary = async (token) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseUrl}/financial-summary/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {}
        };

        try {
            axios.request(config)
                .then((response) => {
                    if (response?.data) {
                        setTotalBalance(response?.data?.total_balance)
                        setTotalEarning(response?.data?.total_earnings)
                        setTotalWithrawal(response?.data?.total_withdrawals)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.messages) {
                        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                            dispatch(sessionTrue())

                        }
                    }
                    else {
                        console.log(error)
                        
                    }
                });

        } catch (error) {
            console.log(error)
        }
    }

    const fetchWithdrawals = async (token) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseUrl}/withdrawal/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {}
        };

        try {
            axios.request(config)
                .then((response) => {
                    if (response?.data) {
                        setEarningData(response?.data)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.messages) {
                        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                            dispatch(sessionTrue())

                        }
                    }
                    else {
                        console.log(error)
                        
                    }
                });

        } catch (error) {
            console.log(error)
        }
    }

    const fetchData = async () => {
        try {
            const banks_response = await axios.get(`${baseUrl}/banks/`);
            const networks_response = await axios.get(`${baseUrl}/mobile-money/`);

            setBanks(banks_response?.data)
            setNetworks(networks_response?.data)
        } catch (error) {
            console.log(error)
        }
    }


    //**Keeping track of the withdrawn amount */
    useEffect(()=>{
        if(totalBalance>0){
            setAmountWithdrawn(totalBalance)
        }
    }, [totalBalance])

    useEffect(() => {
        const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
        if (some_data) {
            const current_Data = decodeData(some_data)
            const parsedData = JSON.parse(current_Data)
            setCarrierID(parsedData?.id)
            setUsername(parsedData?.first_name)
            // setUserCity(parsedData?.city)
            // setUserCountry(parsedData?.country)
            setAccessToken(parsedData?.access_token)
            fetchWithdrawalMethod(parsedData?.access_token)
            fetchFinancialSummary(parsedData?.access_token)
            fetchWithdrawals(parsedData?.access_token)
            // setRefreshToken(parsedData?.refresh_token)


        }
        else {
            window.location.href = '/'
        }
        fetchData()

    }, [])


    //**Auto Refresh */

    useEffect(() => {
        const handleMouseOver = () => {
            const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
            if (some_data) {
                const current_Data = decodeData(some_data)
                const parsedData = JSON.parse(current_Data)
                setCarrierID(parsedData?.id)
                setUsername(parsedData?.first_name)
                // setUserCity(parsedData?.city)
                // setUserCountry(parsedData?.country)
                setAccessToken(parsedData?.access_token)
                fetchWithdrawalMethod(parsedData?.access_token)
                fetchFinancialSummary(parsedData?.access_token)
                fetchWithdrawals(parsedData?.access_token)
                // setRefreshToken(parsedData?.refresh_token)


            }
            else {
                window.location.href = '/'
            }
            fetchData()
        };

        document.addEventListener('mouseover', handleMouseOver);

        return () => {
            document.removeEventListener('mouseover', handleMouseOver);
        };
    }, []);

    //api/withdrawal/

    return (
        <div className="bg-white">
            {listFlightModal ? <ListFlightModal access_token={access_token} carrierID={carrierID} airports={airports} /> : null}
            {addWithrawalMethod === true ? <AddWithrawalMethodModal setWhereTo={setWhereTo} access_token={access_token} setAddWithrawalMethod={setAddWithrawalMethod} setAddWithrawalType={setAddWithrawalType} bankAdded={bankAdded} mobileMoneyAdded={mobileMoneyAdded} setEditBankAdded={setEditBankAdded} setEditMobileMoneyAdded={setEditMobileMoneyAdded} setDeleteBankAdded={setDeleteBankAdded} setDeleteMobileMoney={setDeleteMobileMoney} /> : null}

            {addWithrawalType === "Bank Transfer" ? <AddBankTransferModal user={carrierID} banks={banks} whereTo={whereTo} access_token={access_token} setAddWithrawalType={setAddWithrawalType} setBankAdded={setBankAdded} setAddWithrawalMethod={setAddWithrawalMethod} setNoMethod={setNoMethod} /> : null}
            {addWithrawalType === "Mobile Money" ? <AddMobileMoneyModal user={carrierID} networks={networks} whereTo={whereTo} access_token={access_token} setAddWithrawalType={setAddWithrawalType} setMobileMoneyAdded={setMobileMoneyAdded} setAddWithrawalMethod={setAddWithrawalMethod} setNoMethod={setNoMethod} /> : null}

            {editBankAdded === true ? <EditBankTransferModal user={carrierID} banks={banks} access_token={access_token} setAddWithrawalMethod={setAddWithrawalMethod} bankAdded={bankAdded} setBankAdded={setBankAdded} setEditBankAdded={setEditBankAdded} setManageSucess={setManageSucess} /> : null}
            {editMobileMoneyAdded === true ? <EditMobileMoneyModal user={carrierID} networks={networks} access_token={access_token} setAddWithrawalMethod={setAddWithrawalMethod} mobileMoneyAdded={mobileMoneyAdded} setMobileMoneyAdded={setMobileMoneyAdded} setEditMobileMoneyAdded={setEditMobileMoneyAdded} setManageSucess={setManageSucess} /> : null}

            {deleteBankAdded === true ? <DeleteBankTransferModal bankAdded={bankAdded} access_token={access_token} setDeleteBankAdded={setDeleteBankAdded} setBankAdded={setBankAdded} setAddWithrawalMethod={setAddWithrawalMethod} setManageSucess={setManageSucess} /> : null}
            {deleteMobileMoney === true ? <DeleteMobileMoneyModal mobileMoneyAdded={mobileMoneyAdded} access_token={access_token} setDeleteMobileMoney={setDeleteMobileMoney} setMobileMoneyAdded={setMobileMoneyAdded} setAddWithrawalMethod={setAddWithrawalMethod} setManageSucess={setManageSucess} /> : null}

            {manageSuccess ? <ManageWithdrawalSuccessfulModal manageSuccess={manageSuccess} setManageSucess={setManageSucess} setAddWithrawalMethod={setAddWithrawalMethod} /> : null}

            {/**Widrawal */}
            {noMethod ? <NoMethodModal totalBalance={totalBalance} setWhereTo={setWhereTo} access_token={access_token} setNoMethod={setNoMethod} setAddWithrawalType={setAddWithrawalType} bankAdded={bankAdded} mobileMoneyAdded={mobileMoneyAdded} setAddWithrawalMethod={setAddWithrawalMethod} setReviewWithdrawalMethod={setReviewWithdrawalMethod} /> : null}

            {/**Review Withdrawal */}
            {reviewWithdrawalMethod !== null ? <ReviewMethodModal totalBalance={totalBalance} access_token={access_token} reviewWithdrawalMethod={reviewWithdrawalMethod} setReviewWithdrawalMethod={setReviewWithdrawalMethod} bankAdded={bankAdded} mobileMoneyAdded={mobileMoneyAdded} setSuccessWithdrawal={setSuccessWithdrawal} /> : null}

            {/**Success Withdrawal */}
            {successWithdrawal === true ? <SuccessWithdrawalModal amountWithdrawn={amountWithdrawn} access_token={access_token} setSuccessWithdrawal={setSuccessWithdrawal} /> : null}

            <div className=' flex z-50 fixed mt-[-170px] pt-4 bg-white'>
                <CarrierNav access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />
            </div>
            <div className=" px-[2rem] sm:px-[4.375rem] md:px-[5rem]  mt-[151px] ">
                <h1 className=" text-[25px] leading-[30.33px] sm:text-[50px] font-bold sm:leading-[60.65px]">Hi, {username}</h1>

                <div className=" w-full mt-[30px] hidden md:flex justify-between gap-4">
                    <div className=" w-full max-w-[390px] bg-white rounded-[30px] shadow-input h-[170px] px-[20px] lg:px-[37px] pt-[29px]">
                        <div className=" text-[#707070]  text-[10px] sm:text-[16px] leading-[12.13px] sm:leading-[19.41px]">Total Income Earned</div>
                        <div className=" text-[20px] sm:text-[25px] md:text-[30px] lg:text-[40px] leading-[24.26px] sm:leading-[48.52px] mt-[3px] font-bold">${totalEarning}</div>
                    </div>
                    <div className=" w-full max-w-[390px] bg-white rounded-[30px] shadow-input h-[170px] px-[20px] lg:px-[37px] pt-[29px]">
                        <div className=" text-[#707070]  text-[10px] sm:text-[16px] leading-[12.13px] sm:leading-[19.41px]">Available for withdrawal</div>
                        <div className=" text-[20px] sm:text-[25px] md:text-[30px] lg:text-[40px] leading-[24.26px] sm:leading-[48.52px] mt-[3px] font-bold">${totalBalance}</div>
                    </div>
                    <div className=" w-full max-w-[390px] bg-white rounded-[30px] shadow-input h-[170px] px-[20px] lg:px-[37px] pt-[29px]">
                        <div className=" text-[#707070]  text-[10px] sm:text-[16px] leading-[12.13px] sm:leading-[19.41px]">Total withdrawn</div>
                        <div className=" text-[20px] sm:text-[25px] md:text-[30px] lg:text-[40px] leading-[24.26px] sm:leading-[48.52px] mt-[3px] font-bold">${totalWithrawal}</div>
                    </div>
                </div>
                {/**Small Device */}
                <div className=" w-full mt-[30px] flex flex-col md:hidden items-center justify-between gap-4">
                    <div className=" w-full  sm:max-w-[390px] items-center justify-center text-center rounded-[15px] h-[170px] px-[37px] pt-[29px]">
                        <div className=" text-[#707070] font-bold text-[10px] leading-[12.13px] sm:text-[16px] sm:leading-[19.41px]">Total Income Earned</div>
                        <div className="text-[40px] leading-[48.52px] font-bold">${totalEarning}</div>
                    </div>
                    <div className='  flex w-full items-center justify-between xm:justify-center gap-[9px] xm:gap-4'>
                        <div className=" w-full  sm:max-w-[390px] bg-[#E5E5E5] rounded-[15px] h-[170px] px-[20px] sm:px-[37px] pt-[29px]">
                            <div className=" text-[#707070]  text-[10px] sm:text-[16px] leading-[12.13px] sm:leading-[19.41px]">Available for withdrawal</div>
                            <div className=" text-[20px] sm:text-[25px] md:text-[30px] lg:text-[40px] leading-[24.26px] sm:leading-[48.52px] mt-[3px] font-bold">${totalBalance}</div>
                        </div>
                        <div className=" w-full  sm:max-w-[390px] bg-[#E5E5E5] rounded-[15px] h-[170px] px-[20px] sm:px-[37px] pt-[29px]">
                            <div className=" text-[#707070]  text-[10px] sm:text-[16px] leading-[12.13px] sm:leading-[19.41px]">Total withdrawn</div>
                            <div className=" text-[20px] sm:text-[25px] md:text-[30px] lg:text-[40px] leading-[24.26px] sm:leading-[48.52px] mt-[3px] font-bold">${totalWithrawal}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap-reverse mt-[55px] justify-around sm:justify-between items-center xs:place-items-end lg:items-center mb-4">
                    <h2 className=" mt-3 sm:mt-0 text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] font-bold">Recent withdrawals</h2>
                    <div className='flex flex-col-reverse  lg:flex-row items-center gap-[11px] lg:gap-[25px]'>
                        <button onClick={handleManageWithrawalMethod} className=' whitespace-nowrap border-b-[1px] border-b-[#707070] text-[#707070] text-[12px] leading-[14.56px] sm:text-[18px] sm:leading-[21.83px] h-[23px]'>Manage withdrawal methods</button>
                        <button onClick={handleWithdrawal} className=" h-[40px] sm:h-[45px] px-[23px] sm:px-[42px] bg-white border-solid border-2 border-[#EB0728] text-[#EB0728] text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] rounded-[40px] font-bold transition-colors">+ Withdraw</button>
                    </div>
                </div>

                {earningData?.filter(item => item.user === carrierID).length > 0 ?
                    <div className="bg-white flex flex-col gap-[20px] overflow-scroll h-[563px]">
                        {
                            earningData?.filter(item => item.user === carrierID).map((transaction, index) => (
                                <div key={index} className={` w-full pl-[20px] pr-[20px] xf:pl-[13px] xf:pr-[11px] sm:pl-[20px] sm:pr-[20px] md:pl-[33px] md:pr-[44px] gap-5 xf:gap-0 py-5 xf:py-0 h-fit xf:min-h-[60px] sm:min-h-[80px] bg-[#E5E5E5] rounded-[20px] flex flex-col xf:flex-row justify-between items-center`}>
                                    <div className='flex w-full justify-between xf:justify-start items-center gap-[13px] sm:gap-[22px]'>
                                        <div className="font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px]">{transaction?.withdrawal_method === bankAdded?.id ? "Bank Transfer" : "Mobile Money"}</div>
                                        <div className=" text-[12px] leading-[14.56px] sm:text-[16px] sm:leading-[19.41px]">{formatDate(transaction?.created_at)}</div>
                                    </div>
                                    <div className='flex w-full justify-between xf:justify-end gap-[7px] sm:gap-[31px]'>
                                        <div className={` whitespace-nowrap w-[100px] h-[30px] flex items-center text-center justify-center font-bold text-[10px] leading-[12.13px] sm:text-[14px] sm:leading-[16.98px] rounded-[50px] border-[1px] border-solid px-[18px] ${transaction?.withdrawal_status === 3 ? 'text-[#EB0728] border-[#EB0728] bg-[#FFDFE4]' : transaction?.withdrawal_status === 2 ? ' bg-[#D6FBC9] border-[#4FBF26] text-[#4FBF26]' : ' bg-[#E5E5E5] border-[#70707080] border-opacity-50 text-[#70707080] text-opacity-50'}`}>{transaction?.withdrawal_status === 3 ? "Cancelled" : transaction?.withdrawal_status === 2 ? "Paid" : "Processing"}</div>
                                        <div className={`font-bold text-[#EB0728] text-[20px] leading-[24.26px]  sm:text-[25px] sm:leading-[30.33px]`}>${transaction?.withdrawal_amount}</div>

                                    </div>
                                </div>

                            ))
                        }
                    </div>
                    :
                    <div className=' mt-[113px] w-full flex flex-col items-center justify-center text-center'>
                        <h1 className=' font-bold text-[25px] leading-[30.33px]'>No recent withdrawals</h1>
                        <h1 className=' mt-[16px] text-[18px] text-[#707070] leading-[21.83px]'>Your withdrawal history is currently empty! All your withdrawals will appear here once they are made  </h1>
                    </div>
                }
            </div>
            {/**Footer */}
            <Footer />
        </div>
    );
};

export default Earnings;

