import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import downIcon from './assets/downIcon.svg'; // Adjust the path as needed


const CountrySelector = ({setUserCountry, component}) => {
  const [fixedCountries, setFixedCountries] = useState(null)
  const [countries, setCountries] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);


  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if(value){
      setOpen(true)
    }   
  };

  const handleOpen = () =>{
    setOpen(!open)
      setCountries(fixedCountries)
  }

  useEffect(() => {
    fetch("https://restcountries.com/v2/all?fields=name")
      .then((res) => res.json())
      .then((data) => {
        setCountries(data);
        setFixedCountries(data)
      });
  }, []);

  useEffect(() => {
    if (!inputValue) {
      // If input value is empty, reset to the original list of countries
      setCountries(fixedCountries);
      return;
    }
  
    const filteredCountry = fixedCountries?.filter(
      (country) => country?.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setCountries(filteredCountry);
  }, [inputValue, fixedCountries]);
  
  return (
    <div className=" w-full cursor-pointer">
      <div 
       
        className="relative " >
       
          <input
          
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Country"
            className={`w-full placeholder:text-[#AAAAAA] text-[16px] outline-none  rounded-[50px] pl-[20px] pr-[50px] ${component==='signup'?'bg-[#E5E5E580] h-[40px] text-[15px] text-[#707070]':'bg-white  h-[44.98px] py-[14px] text-[12px] leading-[14.56px] items-center  sm:text-[14px] sm:leading-[16.98px] relative'}  ${
              !selected && "text-[#707070]"
            }`}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} // Prevent text overflow

          />  
          <div id='down'  className="absolute inset-y-0 right-0 pr-[17px] flex items-center  ">
          <img onClick={() => handleOpen()}
              src={downIcon} 
              alt="Dropdown Icon" 
              className={` transition-transform duration-300 ${open ? 'rotate-180' : ''}`} // Apply rotation
          />
           </div>
       
      </div>
      
     
      <div  className="   " >
      <ul

              id='modal'
              className={` ${!open?"hidden":""} flex flex-wrap mr-[29px] mt-[9px] py-[12px]  rounded-[20px] absolute    shadow-input bg-white overflow-y-auto z-50 ${open ? countries?.length < 6 ? "max-h-fit" : "h-[150px]" : "max-h-0"
                }`}
            > 
              {countries && countries.length > 0 ? countries?.map((country) => (
                <li 
                  key={country?.name}
                  className={`w-full py-[5px]  flex flex-wrap items-center px-[22px] text-[14px] font-normal leading-[16.98px] hover:bg-[#E5E5E580] 
         ${country?.name.toLowerCase() === selected?.toLowerCase() &&
                    "bg-[#E5E5E580] text-[#000000] h-[31px]"
                    }
         ${country?.name.toLowerCase().includes(inputValue)
                      ? "block"
                      : !open || inputValue.length===0  ? "hidden" : "block"
                    }`}
                  onClick={() => {
                    if (country?.name.toLowerCase() !== selected.toLowerCase()) {
                      setSelected(country?.name);
                      setUserCountry(country?.name)
                      setOpen(false);
                      setInputValue(country?.name);
                    } else {
                      setOpen(false);
                      setSelected(country?.name);
                      setInputValue(country?.name);
                    }
                  }}
                >
                  {country?.name}
                </li>
              )) :
                <li className="p-2 text-sm text-[#EB0728]">no results found</li>
              }
            </ul>
            </div>
            </div>
    
  );
};

export default CountrySelector;
      

{/* <ul
        className={` shadow-input bg-white mt-2 overflow-y-auto flex flex-col absolute rounded-md z-50 ${
          open ? countries?.length<6? "max-h-fit":"h-[30vh]" : inputValue.length>0? "h-fit": "max-h-0"
        } `}
      >
        
        {countries?.map((country) => (
          <li
            key={country?.name}
            className={` text-sm hover:bg-sky-600 hover:text-white
            ${
              country?.name?.toLowerCase() === selected?.toLowerCase() &&
              "bg-sky-600 text-white"
            }
            ${
              country?.name?.toLowerCase().includes(inputValue)
                ? "block"
                : !open? "hidden":"block"
            }`}
            onClick={() => {
              if (country?.name?.toLowerCase() !== selected.toLowerCase()) {
                setSelected(country?.name);
                setUserCountry(country?.name)
                setOpen(false);
                setInputValue(country?.name);
              }else{
                setOpen(false);
                setSelected(country?.name);
                setInputValue(country?.name);
              }
            }}
          >
            {country?.name}
          </li>
        ))} */}
      {/* </ul>  */}

       {/* <ul
        className={`shadow-input bg-white mt-2 overflow-y-auto flex flex-col absolute rounded-md z-50 ${
          open ? countries?.length<6? "max-h-fit":"h-[30vh]" : inputValue.length>0? "h-fit": "max-h-0"
        } `}
      >
        
        {countries?.map((country) => (
          <li
            key={country?.name}
            className={` w-full text-sm hover:bg-sky-600 hover:text-white
            ${
              country?.name?.toLowerCase() === selected?.toLowerCase() &&
              "bg-sky-600 text-white"
            }
            ${
              country?.name?.toLowerCase().includes(inputValue)
                ? "block"
                : !open? "hidden":"block"
            }`}
            onClick={() => {
              if (country?.name?.toLowerCase() !== selected.toLowerCase()) {
                setSelected(country?.name);
                setUserCountry(country?.name)
                setOpen(false);
                setInputValue(country?.name);
              }else{
                setOpen(false);
                setSelected(country?.name);
                setInputValue(country?.name);
              }
            }}
          >
            {country?.name}
          </li>
        ))}
      </ul>  */}
      
         
    
      
