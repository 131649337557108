export function formatDate(inputDate){
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];
  

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(inputDate);
    const formattedDate = date.toLocaleDateString(undefined, options);

    const day = date.getDate();
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }

    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    const formattedDateString = `${day}${suffix} ${monthName} ${formattedDate.slice(-4)}`;

    return formattedDateString;
  };

  

export function convertDate(inputDate) {
    // Split the input date (assumed to be in the format YYYY-MM-DD)
    const parts = inputDate?.split('-');
    if(parts){
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    

    // Create a new Date object
    const date = new Date(year, month - 1, day); // month - 1 because months are 0-indexed in JavaScript Date

    // Format the date as dd/mm/yy
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(2)}`;

    return formattedDate;

    }
}

export function convertTo12HourFormat(time) {
    // Split the time into hours, minutes, and seconds
    const [hours24, minutes, seconds] = time?.split(':');

    // Convert hours to 12-hour format and determine AM/PM
    const hours12 = ((parseInt(hours24) + 11) % 12) + 1;
    const amPm = parseInt(hours24) >= 12 ? 'pm' : 'am';

    // Return the formatted time
    return `${hours12}:${minutes}${amPm}`;
}

