import React from 'react'
import page_loading from './assets/page_loading.svg'
import page_loading_mobile from './assets/page_loading_mobile.svg'
import CarrierNav from '../../components/Nav/CarrierNav'
import CustomerNav from '../../components/Nav/CustomerNav'

const PageLoading = ({ dashboard, userID, refresh_token, access_token, isOpenDropDown, setIsOpenDropDown}) => {
    return (
        <div>
            {dashboard === 'customer' ? <CustomerNav userID={userID} refresh_token={refresh_token} access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown}  /> : <CarrierNav access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} />}
            <div className='pt-[148px] sm:pt-[210px] '>
                <img src={page_loading} alt="" className='hidden sm:flex w-full h-full' />
                <img src={page_loading_mobile} alt="" className='flex sm:hidden w-full h-full' />
            </div>
        </div>
    )
}

export default PageLoading
