import React from 'react'
import goodifly from "../assets/goodifly.png"
import successIcon from "../assets/success.svg"

const ConfirmDeclineRequest = ({ newRequest, confirmDeclineRequest, setConfirmDeclineRequest, setListFlightAfterDecline }) => {
    // useEffect(() => {
    //     if (confirmDecline) {
    //         const timeout = setTimeout(() => {
    //             // Set 'delivered' to null after 3 seconds
    //             setConfirmDeclineRequest(null)
    //             setConfirmDecline(null);
    //             setListFlightAfterDecline(true)
    //             window.location.reload()
    //         }, 1000); // 1000 milliseconds = 3 seconds

    //         // Clean up the timeout to avoid memory leaks
    //         return () => clearTimeout(timeout);
    //     }
    // }, [confirmDecline]);

    const handleDecline = (item) => {
        setConfirmDeclineRequest(null)
        setListFlightAfterDecline(item)
    }
    return (
        <div className=' bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] flex flex-col items-center justify-center pt-[50px] sm:pt-[98px] pb-[60px] sm:pb-[105px]'>
            <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
            <h1 className=' text-[18px] font-bold mt-[20px] sm:mt-[47px]'>Decline</h1>
            <h1 className=' w-full flex text-center items-center justify-center text-[#707070] text-[16px] mt-[7px]'>Declining this request will remove your flight from your dashboard. Are you sure you want to decline?</h1>
            <div className=' w-full px-6 mt-[41px] gap-[24px] flex items-center justify-center'>
                <button onClick={() => setConfirmDeclineRequest(null)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>Cancel</button>
                <button onClick={() => handleDecline(confirmDeclineRequest)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Decline</button>
            </div>
        </div>
    )
}

export default ConfirmDeclineRequest


{/**

{confirmDecline ?
                <div className='bg-white xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center text-left pl-[25px] rounded-[40px] gap-[13px]'>
                    <img src={successIcon} alt="" />
                    <div className='flex flex-col'>
                        <h1 className=' text-[18px] font-bold'>Success!</h1>
                        <h1 className='text-[14px] text-[#707077]'>Your request has been successfully declined</h1>
                    </div>
                </div> :
                <div className=' bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] flex flex-col items-center justify-center pt-[50px] sm:pt-[98px] pb-[60px] sm:pb-[105px]'>
                    <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
                    <h1 className=' text-[18px] font-bold mt-[20px] sm:mt-[47px]'>Decline</h1>
                    <h1 className=' w-full flex text-center items-center justify-center text-[#707070] text-[16px] mt-[7px]'>Declining this request will remove your flight from your dashboard. Are you sure you want to decline?</h1>
                    <div className=' w-full px-6 mt-[41px] gap-[24px] flex items-center justify-center'>
                        <button onClick={() => setConfirmDeclineRequest(null)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>Cancel</button>
                        <button onClick={() => handleDecline(confirmDeclineRequest)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Decline</button>
                    </div>
                </div>
            }

*/}