const PriceCalculator = (weight) => {
    if (weight < 1){
        weight = 1
    }
    weight = parseFloat(weight); // Convert weight to an integer
    weight = Math.round(weight)
    const transportFees = 25;
    let basePrice, senderCost, goodiflyFees, totalSenderCost;

    if (weight === 1) {
        basePrice = 25;
    } else if (weight > 1 && weight <= 17) {
        basePrice = 15.52; // Adjusted according to Python code
    } else {
        basePrice = 16.52; // For weights above 17 kg
    }

    // Direct assignment without rounding for weight of 1
    senderCost = weight * basePrice + transportFees

    // Applying rounding here, relevant for weights > 1
    if (weight > 1) {
        senderCost = Math.round(senderCost);
    }

    if (weight === 1) {
        goodiflyFees = 0.20 * senderCost; // 20% fee for 1kg
    } else if (weight > 1 && weight <= 17) {
        goodiflyFees = 0.20 * senderCost; // 20% fee for 2kg to 17kg
    } else {
        goodiflyFees = 0.24 * senderCost; // 24% fee for above 17kg
    }

    totalSenderCost = senderCost + goodiflyFees;
    const paystack = 0.015 * totalSenderCost;
    const senderToGoodifly = totalSenderCost - senderCost - paystack - transportFees;

    return {
        weight,
        senderCost,
        finalPrice: totalSenderCost,
        senderToGoodifly
    };
};
export default PriceCalculator


/** 
 * 
 * #weight in kilograms 
def goodiflyPricing(weight):
    pricemargin=0.1
    baseprice=18.3 #18.3USD for 1kg
    inputprice = weight * baseprice
    finalprice = inputprice*(1 + pricemargin)
    return finalprice

    2 // weight
    1*18.3 = 18.3 // finalPrice = 18.3 * (1+0.1) = 18.3 * 1.1 = 20.13
    2*18.3 = 36.6 // finalPrice = 36.6 * (1+0.1) = 36.6 * 1.1 = 40.26
    3*18.3 = 54.9 // finalPrice = 54.9 * (1+0.1) = 54.9 * 1.1 = 60.39
    .
    .
    .
    20*18.3 = 366 // finalPrice = 366 * (1+0.1) = 366 * 1.1 = 402.6
    21*18.3 = 384.3 // finalPrice = 384.3 * (1+0.1) = 384.3 * 1.1 = 422.73

    Therefore; 422.73 - 402.6 = 20.13
    We can conclude that the price keeps on increasing by an amount of 20.13
 */