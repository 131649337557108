import React from 'react'
import Session from './Session';
// import { useDispatch } from 'react-redux';
// import { sessionFalse } from '../../features/pages/sessionSlice';

const SessionModal = () => {
    // const dispatch = useDispatch()

    // const hideModal = (event) => {
    //     const modal = document.getElementById('modal');
    //     // Check if the clicked element is outside of the modal
    //     if (event.target !== modal && !modal.contains(event.target)) {
    //        dispatch(sessionFalse()) // Call the function to close the modal
    //     }
    // }
    return (
        <div>
            <div
                // onClick={hideModal}
                className=" w-full h-screen items-center justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[100] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 w-full xm:w-auto">
                    {/*content*/}
                    <div className=" cursor-default border-0  relative flex flex-col text-center w-full  xm:w-[300px] h-[128px] outline-none focus:outline-none">
                        <Session />
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[90] bg-black"></div>
        </div>
    )
}

export default SessionModal

