// import React from 'react'
// import {convertDate, convertTo12HourFormat} from '../../ConvertDateAndTime'

// const ApproveInbound = ({item, setViewSednerRecipientViewModal, setViewMarketPlaceViewModal}) => {
//     const handleSednerRecipientViewModal = (item) =>{
//         setViewMarketPlaceViewModal(null)
//         setViewSednerRecipientViewModal(item)
//     }
//     const handleMarketPlaceViewModal = (item) =>{
//         setViewSednerRecipientViewModal(null)
//         setViewMarketPlaceViewModal(item)
//     }
//     return (
//         <div className=' w-full h-[80px] flex items-center border-solid border-b-[1px] border-b-[#C1C1C180]'>
//             <div className=' w-full flex items-center text-[14px] text-[#707070] leading-[16.98px] px-[19px]'>
//                 <div className=' w-[12.5%] font-bold text-[18px] leading-[21.83px]'>{item?.sender_name?.split(' ')[0]}</div>
//                 <div className=' w-[12.5%]'>{item?.from_city_code} - {item?.arrival_code}</div>
//                 <div className=' w-[12.5%] flex flex-col gap-[5px]'>
//                     <p>{convertDate(item?.departure_date)}</p>
//                     <p>{convertTo12HourFormat(item?.depart_time)}</p>
//                 </div>
//                 <div className=' w-[12.5%] flex flex-col gap-[5px]'>
//                     <p>{convertDate(item?.arrival_date)}</p>
//                     <p>{convertTo12HourFormat(item?.arrival_time)}</p>
//                 </div>
//                 <div className=' w-[12.5%]'>{convertDate(item?.drop_off_date_to_agent)}</div>
//                 <div className=' w-[12.5%]'>{item?.request_type===1?"Send":item?.request_type===2?"Receive": item?.request_type===3? "Marketplace":null}</div>
//                 <div className=' w-[12.5%]'>{item?.carrier_name}</div>
//                 <div className=' w-[12.5%]'>
//                     {item?.request_type===3?
//                     <button onClick={() => handleMarketPlaceViewModal(item)} className='w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]'>View</button>
//                     :
//                     <button onClick={() => handleSednerRecipientViewModal(item)} className='w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]'>View</button>}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default ApproveInbound

import React from "react";
import { convertDate, convertTo12HourFormat } from "../../ConvertDateAndTime";

const ApproveInbound = ({
  item,
  setViewSednerRecipientViewModal,
  setViewMarketPlaceViewModal,
}) => {
  const handleSednerRecipientViewModal = () => {
    setViewMarketPlaceViewModal(null);
    setViewSednerRecipientViewModal(item);
  };

  const handleMarketPlaceViewModal = () => {
    setViewSednerRecipientViewModal(null);
    setViewMarketPlaceViewModal(item);
  };

  const getRequestType = (type) => {
    switch (type) {
      case 1:
        return "Send";
      case 2:
        return "Receive";
      case 3:
        return "Marketplace";
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-[80px] flex items-center border-solid border-b-[1px] border-b-[#C1C1C180]">
      <div className="w-full flex items-center text-[14px] text-[#707070] leading-[16.98px] px-[19px]">
        <div className="w-[12.5%] font-bold text-[18px] leading-[21.83px]">
          {item?.sender_name?.split(" ")[0]}
        </div>
        <div className="w-[12.5%]">
          {item?.from_city_code} - {item?.arrival_code}
        </div>
        <div className="w-[12.5%] flex flex-col gap-[5px]">
          <p>{convertDate(item?.departure_date)}</p>
          <p>{convertTo12HourFormat(item?.depart_time)}</p>
        </div>
        <div className="w-[12.5%] flex flex-col gap-[5px]">
          <p>{convertDate(item?.arrival_date)}</p>
          <p>{convertTo12HourFormat(item?.arrival_time)}</p>
        </div>
        <div className="w-[12.5%]">
          {convertDate(item?.drop_off_date_to_agent)}
        </div>
        <div className="w-[12.5%]">{getRequestType(item?.request_type)}</div>
        <div className="w-[12.5%]">{item?.carrier_name}</div>
        <div className="w-[12.5%]">
          <button
            onClick={
              item?.request_type === 3
                ? handleMarketPlaceViewModal
                : handleSednerRecipientViewModal
            }
            className="w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]"
          >
            View
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveInbound;
