import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}


export const unableFlightSlice = createSlice({
    name: 'unableFlight',
    initialState,
    reducers: {
      UnableFlightTrue:(state)=>{
        state.value=true
      },
      UnableFlightFalse:(state)=>{
        state.value=false
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { UnableFlightTrue, UnableFlightFalse} = unableFlightSlice.actions
  
  export default unableFlightSlice.reducer