// import React, { useEffect, useState } from 'react'
// import ApproveRequest from './ApproveRequest';

// const ApproveRequestModal = ({access_token, approveRequest, setApproveRequest}) => {
//     const [confirm, setConfirm] = useState(null)
//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth' // For smooth scrolling
//         });
//     };
    
//     const hideModal = (event) =>{
//         const modal = document.getElementById('modal');
//         // Check if the clicked element is outside of the modal
//         if (event.target !== modal && !modal.contains(event.target)) {
//             setApproveRequest(null); // Call the function to close the modal
//           if(confirm){
//             window.location.reload()
//           }
//         }
//     }
//     useEffect(() => {
//         scrollToTop()
//     }, []);
//     return (
//         <div>
//             <div
//                 onClick={hideModal}
//                 className=" cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
//             >
//                 <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 ">
//                     {/*content*/}
//                     <div className=" cursor-default border-0  relative flex flex-col w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] outline-none focus:outline-none">
//                         <ApproveRequest access_token={access_token} approveRequest={approveRequest} setApproveRequest={setApproveRequest} confirm={confirm} setConfirm={setConfirm}/>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
//         </div>
//     )
// }

// export default ApproveRequestModal

import React, { useEffect, useState, useCallback } from "react";
import ApproveRequest from "./ApproveRequest";

const ApproveRequestModal = ({
  access_token,
  approveRequest,
  setApproveRequest,
}) => {
  const [confirm, setConfirm] = useState(null);

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Hide the modal when clicking outside of it
  const hideModal = useCallback(
    (event) => {
      const modal = document.getElementById("modal");
      if (modal && !modal.contains(event.target)) {
        setApproveRequest(null); // Close the modal
        if (confirm) {
          window.location.reload();
        }
      }
    },
    [confirm, setApproveRequest]
  );

  return (
    <div>
      <div
        onClick={hideModal}
        className="cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none"
        role="dialog"
        aria-modal="true"
      >
        <div id="modal" className="relative my-6 mx-[2%] sm:mx-0">
          <div className="cursor-default border-0 relative flex flex-col w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] outline-none focus:outline-none">
            <ApproveRequest
              access_token={access_token}
              approveRequest={approveRequest}
              setApproveRequest={setApproveRequest}
              confirm={confirm}
              setConfirm={setConfirm}
            />
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  );
};

export default ApproveRequestModal;