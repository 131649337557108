import React, { useEffect } from 'react'
import {FlightListSuccessFalse} from '../../../features/flight/flightListSuccessSlice'
import { useDispatch } from 'react-redux'
import FlightListSuccess from './FlightListSuccess'

const FlightListSuccessModal = () => {
    const dispatch = useDispatch()

    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            dispatch(FlightListSuccessFalse()); // Call the function to close the modal
            window.location.reload()
        }
    }

    useEffect(() => {
        const delayedFunction = () => {
          // Your function logic goes here
          dispatch(FlightListSuccessFalse());
          window.location.reload()
        };
    
        const timer = setTimeout(delayedFunction, 3000); // 3000 milliseconds = 3 seconds
    
        // Clear the timeout if the component unmounts before 3 seconds
        return () => clearTimeout(timer);
      }, []); 
    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 ">
                    {/*content*/}
                    <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <FlightListSuccess/>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default FlightListSuccessModal
