import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}


export const sucessEditLightSlice = createSlice({
    name: 'sucessEditFlight',
    initialState,
    reducers: {
      SuccessEditFlightTrue:(state)=>{
        state.value=true
      },
      SuccessEditFlightFalse:(state)=>{
        state.value=false
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { SuccessEditFlightTrue, SuccessEditFlightFalse} = sucessEditLightSlice.actions
  
  export default sucessEditLightSlice.reducer