import React from 'react'
import HomeNav from '../../components/Nav/HomeNav'
import page_loading from './assets/page_loading.svg'
import page_loading_mobile from './assets/page_loading_mobile.svg'

const NoFlight = () => {
  return (
    <div>
      <HomeNav area="NoFlight" />
      <div className='pt-[148px] sm:pt-[210px] '>
        <img src={page_loading} alt="" className='hidden sm:flex w-full h-full' />
        <img src={page_loading_mobile} alt="" className='flex sm:hidden w-full h-full' />
      </div>
    </div>
  )
}

export default NoFlight
