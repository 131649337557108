import React from 'react'
import goodifly from '../../assets/goodifly.png'
import sucess from '../../assets/success.svg'
import infoGray from '../../assets/infoGray.svg'

const SuccessWithdrawal = ({ amountWithdrawn }) => {
  return (
    <div className=' px-5 sm:px-[50px] bg-white rounded-[30px] w-full sm:h-full flex flex-col items-center pt-[44px] sm:pt-[56px] pb-[44px] sm:pb-[105px]'>
      <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
      <img src={sucess} alt="" className=' w-[35px] h-[35px] mt-[28px]' />
      <h1 className=' font-bold text-[50px] leading-[60.65px] mt-[6px]'>${amountWithdrawn}</h1>
      <h1 className=' font-bold text-[16px] leading-[19.41px] mt-[13px]'>Funds are on the way</h1>
      <h1 className=' text-[#AAAAAA] text-[14px] leading-[16.98px] mt-[7px]'>Funds should appear in your account within 7 business days</h1>

      {/**Info */}
      <div className=' mt-[22px] w-full rounded-full bg-[#E5E5E5] border-solid border-[1px] border-[#70707080] text-[#707070] text-[12px] text-left leading-[14.56px] flex gap-[9px] px-[13px] py-[6px] items-center'>
        <img src={infoGray} alt="infoGray" className=' w-[20px] h-[20px]' />
        <h1>Please be informed that there’s a charge associated with this transaction</h1>
      </div>
    </div>
  )
}

export default SuccessWithdrawal
