import React, { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

const ListFlightSelector = ({departureAirport, arrivalAirport, alreadySelected, placeholder, setData, options }) => {
    const [allOption, setAllOption] = useState(options);
    const [inputValue, setInputValue] = useState("");
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);
    const selectorRef = useRef(null);


    const hideSelector = (event) => {
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setOpen(!open)
        }
    }


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (selectorRef.current && !selectorRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);


    useEffect(() => {
        const filteredOption = options?.filter(
            (option) => option?.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setAllOption(filteredOption);
    }, [inputValue, options]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setData("")
        setInputValue(value);
        if (value) {
            setOpen(true)
        }
        if(departureAirport==="" && arrivalAirport===""){
            setAllOption(options)
        }
    };

 
    return (
        <div ref={selectorRef} className=" w-full cursor-pointer">
            <div
                onClick={() => {
                    if (inputValue.length === 0) {
                        setOpen(!open)
                    }
                }}
                className={` pl-[20px] text-[15px] rounded-[2.5rem] h-[40px] bg-[#E5E5E580] p-2 flex items-center justify-between ${!selected && "text-[#707070]"
                    }`}
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    className=" w-full placeholder:text-[#707070] pl-0 p-2 outline-none bg-transparent"
                />
                <BiChevronDown color="#707070" size={25} className={`${open && "rotate-180"}`} />
            </div>
            {open || inputValue.length > 0 ?
                <div>
                    <div
                        onClick={hideSelector}
                        className=" cursor-pointer w-full h-full justify-center flex overflow-x-hidden overflow-y-auto z-[70] outline-none focus:outline-none scrollbar-hide"
                    >
                        <ul
                            id='modal'
                            className={` ${!open ? "hidden" : ""}  w-[82%] mr-2 shadow-input bg-white mt-2 overflow-y-auto flex flex-col absolute rounded-[30px] z-50 ${open ? allOption?.length < 5 ? "max-h-fit" : "h-[20vh]" : inputValue.length > 0 ? "h-fit" : "max-h-0"
                                } `}
                        >
                            {allOption.length>0 ? allOption?.filter(item=>item.id!==alreadySelected)?.map((option) => (
                                <li
                                    key={option?.name}
                                    className={`pl-[20px] text-sm hover:bg-[#EB0728] hover:text-white
            ${option?.name.toLowerCase() === selected?.toLowerCase() &&
                                        "bg-[#EB0728] text-white"
                                        }
            ${option?.name.toLowerCase().includes(inputValue)
                                            ? "block"
                                            : !open ? "hidden" : "block"
                                        }`}
                                    onClick={() => {
                                        if (option?.name.toLowerCase() !== selected?.toLowerCase()) {
                                            setSelected(option?.name);
                                            setData(option?.id)
                                            setOpen(false);
                                            setInputValue(option?.name);
                                        } else {
                                            setOpen(false);
                                            setSelected(option?.name);
                                            setData(option?.id)
                                            setInputValue(option?.name);
                                        }
                                    }}
                                >
                                    {option?.name}
                                </li>
                            )) :
                            allOption?.length<1?
                            <li className="p-2 text-sm text-[#EB0728]">Airport Not Found!</li>
                            :
                                <li className="p-2 text-sm text-[#EB0728]">Loading Airports!!! Or Check Your Internet!</li>
                            }
                        </ul>
                    </div>
                    <div className="opacity-70 fixed z-[60] bg-black"></div>
                </div>
                : ""

            }
        </div>
    );
};

export default ListFlightSelector;
