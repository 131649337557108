import React, { useEffect, useState } from 'react'
import goodifly from '../assets/goodifly.png'
import PriceCalculator from '../../priceCalculator'
import CarrierPriceCalculator from '../../carrierPriceCalculator';
function convertDateFormat(dateString) {
  // Split the string into parts
  const parts = dateString?.split('-');
  // Reorder the parts and join them with a "/"
  if (parts) {
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }
}


const ViewHistory = ({ viewHistory, setViewHistory }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const startDate = viewHistory?.departure_date
  const startTime = viewHistory?.departure_time
  const endDate = viewHistory?.arrival_date
  const endTime = viewHistory?.arrival_time


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };

  useEffect(() => {
    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    const intervalId = setInterval(() => {
      const now = new Date();

      if (now < start) {
        setTimeRemaining('Not started');
      } else if (now >= start && now <= end) {
        const timeDiff = end - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // total hours remaining
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const seconds = Math.floor((timeDiff / 1000) % 60);
        setTimeRemaining(formatTime(hours, minutes, seconds));
      } else {
        setTimeRemaining('Ended');
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate, startTime, endDate, endTime]); // Dependencies array

  const formatTime = (hours, minutes, seconds) => {
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className=' w-full max-w-[680px] min-h-[488px] pt-[32px] px-[20px] flex flex-col '>
      {/**Logo and go back button */}
      <div className='w-full flex justify-between'>
        <img src={goodifly} alt="logo" className=' w-[76px] h-[23px] md:w-[127px] md:h-[39px]' />
        <button onClick={() => setViewHistory(null)} className=' h-[19px] text-[14px] sm:text-[16px] sm:leading-[19.41px] text-[#EB0728] border-solid border-b-[1px] border-b-[#EB0728]'>Go Back</button>
      </div>

      {/**Amount and Approval Info */}
      <div className=' w-full mt-[24px] flex flex-col-reverse gap-1 xm:gap-0 sm:flex-row justify-between'>
        <div className='flex flex-col gap-[3px] text-left'>
          <h1 className=' font-bold text-[18px] sm:text-[20px] md:text-[25px] lg:text-[25px] xl:text-[40px] xl:leading-[48.52px]'>${CarrierPriceCalculator(viewHistory.item_weight, PriceCalculator(viewHistory.item_weight).senderCost)}</h1>
          <p className=' text-[16px] text-[#AAAAAA] leading-[19.41px]'>Earnings includes service charge</p>
        </div>
      </div>

      {/**More Info */}
      <div className=' mt-[25px] w-full xm:px-3 md:px-0 md:w-auto lg:w-full lg:px-[30px] xl:px-0 flex flex-col items-center'>
        <div className=' pt-[18px] xm:pt-[22px] border-solid border-[1px] border-[#C1C1C180] rounded-[20px] flex flex-col items-start w-full '>
          <div className=' w-full flex flex-col items-start text-start bg-white px-[20px] lg:px-[30px]'>
            {/**Source Info */}
            <div className=' w-full flex flex-col-reverse gap-3 xm:flex-row xm:gap-0 items-start xm:items-center justify-between'>
              <div className='flex flex-col gap-2'>
                <h1 className=' text-[14px] sm:text-[18px] lg:text-[25px] font-bold'>{viewHistory.departure_city} - {viewHistory.arrival_city}</h1>
                <p className=' font-bold text-[14px] leading-[16.98px]'>Request from {viewHistory.sender_name}</p>
              </div>
              {/**Weight */}
              <div className={`font-bold hidden xm:flex items-center justify-center text-[#EB0728] text-center text-[16px] sm:text-[25px] sm:leading-[30.33px]`}>{viewHistory.item_weight}kg</div>
            </div>

            {/**Date */}
            <div className=' text-[14px] md:text-[16px] md:leading-[19.41px] mt-[8px] flex flex-col xs:flex-row justify-between w-full xs:w-auto xs:justify-normal gap-[16px] xm:gap-[23px]'>
              <div className=' items-start md:items-center xm:justify-center xm:text-center flex xs:flex-col sm:flex-row gap-[2px] md:gap-[7px]'>
                <p className='text-[#AAAAAA]'>Departure date:</p>
                <p className=' font-bold'>{convertDateFormat(viewHistory.departure_date)}</p>
              </div>
              <div className=' items-start md:items-center xm:justify-center xm:text-center flex xs:flex-col sm:flex-row gap-[2px] md:gap-[7px]'>
                <p className='text-[#AAAAAA]'>Arrival date:</p>
                <p className=' font-bold'>{convertDateFormat(viewHistory.arrival_date)}</p>
              </div>
            </div>

            {/**Small device amount */}
            <div className={`font-bold flex mt-4 xm:mt-0 xm:hidden items-center justify-center text-[#EB0728] text-center text-[16px] sm:text-[25px] sm:leading-[30.33px]`}>${PriceCalculator(viewHistory.item_weight).senderCost}</div>

          </div>

          <div className=' text-[16px] leading-[19.41px] mt-[20px] w-full bg-[#E5E5E580] rounded-b-[20px] flex flex-col gap-[8px] items-start text-start pt-[18px] pb-[26px] px-[20px] lg:px-[30px]'>
            <p className=' text-[#AAAAAA]'>Item description</p>
            <p className=' font-bold'>{viewHistory?.item_description}</p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ViewHistory
