import React, { useEffect, useRef, useState } from 'react'
import baseUrl from '../../baseUrl'
import leftArrow from './assets/leftArrow.svg'
import rightArrow from './assets/rightArrow.svg'
import axios from 'axios'
import PriceCalculator from '../../priceCalculator'
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../../features/pages/sessionSlice';
import weightIcon from './assets/weightIcon.svg'

function convertDateFormat(dateString) {
    // Split the string into parts
    const parts = dateString?.split('-');
    // Reorder the parts and join them with a "/"
    if (parts) {
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }
}


const NewRequest = ({ access_token, airports, newRequest, setCongratulations, setConfirmDeclineRequest }) => {
    const dispatch = useDispatch()
    const [counter, setCounter] = useState(1)

    // Time tracking
    const [remainingTime, setRemainingTime] = useState("00:00:00");
    const intervalRef = useRef();
     //disable
     const [disabled, setDisabled] = useState(false);


    const dictionary = newRequest.reduce((obj, item, index) => {
        const departure_airport = airports?.filter((air) => air.id === item.departure_airport)
        const arrival_airport = airports?.filter((air) => air.id === item.arrival_airport)

        if (departure_airport?.length > 0 && arrival_airport?.length > 0) {
            item['from_city_code'] = departure_airport[0]?.city
            item['to_city_code'] = arrival_airport[0]?.city
        }
        obj[index + 1] = item; // Start keys from 1 instead of 

        return obj;
    }, {});
    const handleCounterIncrease = () => {
        if (counter < newRequest?.length) {
            setCounter(counter + 1)
        }
    }
    const handleCounterDecrease = () => {
        if (counter > 1) {
            setCounter(counter - 1)
        }
    }

    const hanleAccept = (item) => {
        const data = JSON.stringify({
            flight_book: item.flight_book,
            item_description: item.item_description,
            item_fee: item.item_fee,
            item_weight: item.item_weight,
            sender: item.sender,
            carrier_acceptance_status: 3,
            agent_approval_inbound: 1
        })
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    setCongratulations(item)
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })
    }

    const handleDecline = (item) => {
        const data = JSON.stringify({
            flight_book: item.flight_book,
            item_description: item.item_description,
            item_fee: item.item_fee,
            item_weight: item.item_weight,
            sender: item.sender,
            carrier_acceptance_status: 2,
        })
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    // setCongratulations(item)
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })
    }

    // const calculateTimeRemaining = (createdAt) => {
    //     const createdDate = new Date(createdAt);
    //     const currentDate = new Date();
    //     const oneHourLater = new Date(createdDate.getTime() + 3600 * 1000);

    //     if (currentDate >= oneHourLater) {
    //         return "00:00:00";
    //     }

    //     const secondsRemaining = Math.floor((oneHourLater - currentDate) / 1000);
    //     const hours = Math.floor(secondsRemaining / 3600);
    //     const minutes = Math.floor((secondsRemaining % 3600) / 60);
    //     const seconds = secondsRemaining % 60;

    //     const paddedHours = String(hours).padStart(2, '0');
    //     const paddedMinutes = String(minutes).padStart(2, '0');
    //     const paddedSeconds = String(seconds).padStart(2, '0');

    //     return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    // };

    const calculateTimeRemaining = (createdAt) => {
      const createdDate = new Date(createdAt);
      const currentDate = new Date();
      const eightHoursLater = new Date(createdDate.getTime() + 8 * 3600 * 1000);

      if (currentDate >= eightHoursLater) {
        return "00:00:00";
      }

      const secondsRemaining = Math.floor(
        (eightHoursLater - currentDate) / 1000
      );
      const hours = Math.floor(secondsRemaining / 3600);
      const minutes = Math.floor((secondsRemaining % 3600) / 60);
      const seconds = secondsRemaining % 60;

      const paddedHours = String(hours).padStart(2, "0");
      const paddedMinutes = String(minutes).padStart(2, "0");
      const paddedSeconds = String(seconds).padStart(2, "0");

      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    };


    useEffect(() => {
        const updateRemainingTime = () => {
            const timeLeft = calculateTimeRemaining(dictionary[counter]?.payment_date);
            setRemainingTime(timeLeft);

            if (timeLeft === "00:00:00") {
                setDisabled(true)
                handleDecline(dictionary[counter]);
            }
            else{
                setDisabled(false)
            }
        };

        intervalRef.current = setInterval(updateRemainingTime, 1000);

        // Initial call to set the time without delay
        updateRemainingTime();

        return () => clearInterval(intervalRef.current);
    }, [dictionary[counter]?.payment_date, dictionary[counter], handleDecline]);

    return (
        <div className=' flex flex-col text-center mt-4 w-full h-[563px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-switchBar rounded-[30px] py-[65px] px-2 xm:px-4 sm:px-5 md:px-[25px] lg:px-[80px] xl:px-[30px]'>
            {/**Weight and Duration */}
            <div className=' px-5 xm:px-8 sm:px-[30px] lg:px-0 w-full flex gap-6 sm:gap-4 items-center justify-between'>
                {/**Weight */}
                <div className='flex flex-col xl:gap-[7px] items-start text-start'>
                    <div className='flex items-center gap-1 sm:gap-2 xl:gap-[12px]'>
                        <img src={weightIcon} alt="weightIcon" className=' w-[18px] h-[24px] sm:w-[20px] sm:h-[26px] xl:w-[26.6px] xl:h-[38px]' />
                        <h1 className=' font-bold text-[18px] sm:text-[20px] md:text-[25px] lg:text-[25px] xl:text-[40px] xl:leading-[48.52px]'>{dictionary[counter]?.item_weight}kg</h1>
                    </div>
                    <p className=' text-[14px] sm:text-[16px] lg:text-[14px] xl:text-[16px] text-[#AAAAAA] xl:leading-[19.41px]'>space booked out of {dictionary[counter]?.maximum_space}kg</p>
                </div>

                {/**Duration */}
                <div className='flex flex-col xl:gap-[5px] items-end text-end text-[14px] sm:text-[16px] lg:text-[14px] xl:text-[16px] xl:leading-[19.41px]'>
                    <h1 className='font-bold text-[#EB0728]'>{remainingTime}</h1>
                    <p className='text-[#AAAAAA]'>Your request will expire in</p>
                </div>
            </div>

            <div className=' flex items-center justify-center sm:justify-between mt-[35px]'>
                <div>
                    <img onClick={() => handleCounterDecrease()} src={leftArrow} alt="" className={` ${newRequest?.length <= 1 || counter <= 1 ? 'opacity-10 cursor-not-allowed' : 'opacity-100 cursor-pointer'} min-w-[30px] min-h-[30px]  w-[30px] h-[30px] xm:w-[43.43px] xm:h-[43.43px]`} />
                </div>

                <div className=' w-full xm:px-3 md:px-0 md:w-auto lg:w-full lg:px-[30px] xl:px-0 flex flex-col items-center'>
                    <div className=' pt-[18px] xm:pt-[25px] border-solid border-[1px] border-[#C1C1C180] rounded-[20px] flex flex-col items-start w-full  xl:w-[500px]'>
                        <div className=' w-full flex flex-col items-start text-start bg-white px-[20px] lg:px-[30px]'>
                            {/**Source Info */}
                            <div className=' w-full flex flex-col-reverse gap-3 xm:flex-row xm:gap-0 items-start xm:items-center justify-between'>
                                <div className='flex flex-col gap-[7px]'>
                                    <p className=' text-[14px] sm:text-[16px] sm:leading-[19.41px]'><span className='text-[#AAAAAA]'>Incoming request</span> from {dictionary[counter]?.sender_name}</p>
                                    <h1 className=' text-[14px] sm:text-[18px] lg:text-[25px] font-bold'>{dictionary[counter]?.from_city_code} - {dictionary[counter]?.to_city_code}</h1>
                                </div>
                                {/**Amout */}
                                <div className={` self-start xm:self-auto bg-[#EB0728] h-[30px] lg:h-[40px] px-[16px] lg:px-[18px] rounded-[80px] text-center text-[16px] lg:text-[20px] font-bold flex items-center justify-center text-[#FFFFFF]`}>${PriceCalculator(dictionary[counter]?.item_weight)?.senderCost - 25}</div>
                            </div>

                            {/**Date */}
                            <div className=' text-[14px] md:text-[16px] md:leading-[19.41px] mt-[19px] flex flex-col xm:flex-row justify-between w-full xs:w-auto xs:justify-normal gap-[16px] xm:gap-[23px]'>
                                <div className=' items-start md:items-center xm:justify-center xm:text-center flex xm:flex-col sm:flex-row gap-[2px] md:gap-[7px]'>
                                    <p className='text-[#AAAAAA]'>Departure date:</p>
                                    <p className=' font-bold'>{convertDateFormat(dictionary[counter]?.departure_date)}</p>
                                </div>
                                <div className=' items-start md:items-center xm:justify-center xm:text-center flex xm:flex-col sm:flex-row gap-[2px] md:gap-[7px]'>
                                    <p className='text-[#AAAAAA]'>Arrival date:</p>
                                    <p className=' font-bold'>{convertDateFormat(dictionary[counter]?.arrival_date)}</p>
                                </div>
                            </div>
                        </div>

                        <div className=' text-[16px] leading-[19.41px] mt-[20px] w-full bg-[#E5E5E580] rounded-b-[20px] flex flex-col gap-[8px] items-start text-start pt-[18px] pb-[26px] px-[20px] lg:px-[30px]'>
                            <p className=' text-[#AAAAAA]'>Item description</p>
                            <p className=' font-bold'>{dictionary[counter]?.item_description}</p>
                        </div>

                    </div>
                    <div className='flex gap-3 xs:gap-[16px] xm:gap-[24px] mt-[18px]'>
                        <button disabled={disabled} onClick={() => { hanleAccept(dictionary[counter]) }} className=' disabled:opacity-30 disabled:cursor-not-allowed w-[80px] h-[30px] xs:w-[119px] xs:h-[40px] xm:w-[171px] xm:h-[45px] rounded-[30px] bg-[#4FBF26] flex items-center justify-center text-[14px] xm:text-[18px] text-white'>Accept</button>
                        <button disabled={disabled} onClick={() => setConfirmDeclineRequest(dictionary[counter])} className='disabled:opacity-30 disabled:cursor-not-allowed w-[80px] h-[30px] xs:w-[119px] xs:h-[40px] xm:w-[171px] xm:h-[45px] rounded-[30px] bg-[#EB0728] flex items-center justify-center text-[14px] xm:text-[18px] text-white'>Decline</button>
                    </div>
                </div>

                <div>
                    <img onClick={() => handleCounterIncrease()} src={rightArrow} alt="" className={` ${newRequest?.length <= 1 || counter === newRequest?.length ? 'opacity-10 cursor-not-allowed' : 'opacity-100 cursor-pointer'}  min-w-[30px] min-h-[30px] w-[30px] h-[30px] xm:w-[43.43px] xm:h-[43.43px]`} />
                </div>
            </div>
        </div>
    )
}

export default NewRequest
