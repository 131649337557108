import React, { useEffect, useState } from 'react'
import goodifly from "../assets/goodifly.png"
import successIcon from "../assets/success.svg"
import baseUrl from '../../baseUrl'
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../../features/pages/sessionSlice';

const ConfirmItemReceived = ({ access_token, item, setConfirmItemReceived }) => {
  const dispatch = useDispatch()

  const [confirmFlight, setConfirmFlight] = useState(null)
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };

  const hanleReceive = (item) => {
    const data = JSON.stringify({
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_weight: item.item_weight,
      item_fee: item.item_fee,
      sender: item.sender,
      sender_approval: true
    })
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response) {
          setConfirmFlight(item)
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        else {
          if (error?.response?.data) {
            alert(error?.response?.data?.detail)
            console.log(error?.response?.data)
          }
        }

      })
  }

  useEffect(() => {
    scrollToTop()
    if (confirmFlight) {
      const timeout = setTimeout(() => {
        // Set 'delivered' to null after 3 seconds
        setConfirmItemReceived(null)
        setConfirmFlight(null);
        window.location.reload()
      }, 1000); // 1000 milliseconds = 3 seconds

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeout);
    }
  }, [confirmFlight]);
  return (
    <>
      {
        confirmFlight ?
          <div className='bg-white w-full xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center text-left pl-[25px] rounded-[40px] gap-[13px]'>
            <img src={successIcon} alt="" />
            <div className='flex flex-col'>
              <h1 className=' text-[18px] font-bold'>Success!</h1>
              <h1 className='text-[14px] text-[#707077]'>You have successfully confirmed item received!</h1>
            </div>
          </div> :

          <div className=' bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[418px] sm:w-[528px] flex flex-col items-center justify-center pt-[44px] sm:pt-[98px] pb-[44px] sm:pb-[105px]'>
            <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
            <h1 className=' text-[16px] sm:text-[18px] font-bold mt-[31px] sm:mt-[47px]'>Confirm item received</h1>
            <h1 className=' text-[#707070] text-[14px] sm:text-[16px] mt-[8px] sm:mt-[7px]'>Item received! Ready to confirm?</h1>
            <div className=' mt-[15px] sm:mt-[41px] gap-[12px] sm:gap-[24px] flex'>
              <button onClick={() => setConfirmItemReceived(null)} className=' w-[120px] h-[40px] sm:w-[171px] sm:h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>Cancel</button>
              <button onClick={() => hanleReceive(item)} className=' w-[120px] h-[40px] sm:w-[171px] sm:h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Confirm</button>
            </div>
          </div>
      }
    </>
  )
}

export default ConfirmItemReceived
