import React, { useEffect, useState } from 'react'
import ConfirmDeclineRequest from './ConfirmDeclineRequest';

const ConfirmDeclineRequestModal = ({newRequest, confirmDeclineRequest, setConfirmDeclineRequest, setListFlightAfterDecline}) => {
    const [confirmDecline, setConfirmDecline] = useState(null)
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // For smooth scrolling
        });
    };
    
    // const hideModal = (event) =>{
    //     const modal = document.getElementById('modal');
    //     // Check if the clicked element is outside of the modal
    //     if (event.target !== modal && !modal.contains(event.target)) {
    //       setConfirmDeclineRequest(null); // Call the function to close the modal
    //       if(confirmDecline){
    //         window.location.reload()
    //       }
    //     }
    // }
    useEffect(() => {
        scrollToTop()
    }, []);
    return (
        <div>
            <div
                // onClick={hideModal}
                className="  pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 ">
                    {/*content*/}
                    <div className=" cursor-default border-0  relative flex flex-col w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] outline-none focus:outline-none">
                        <ConfirmDeclineRequest newRequest={newRequest} confirmDeclineRequest={confirmDeclineRequest} setConfirmDeclineRequest={setConfirmDeclineRequest} setListFlightAfterDecline={setListFlightAfterDecline} />
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default ConfirmDeclineRequestModal

