import React from 'react'
import goodifly from '../assets/goodifly.png'
import closeIcon from './assets/close.svg'
import {UnableFlightFalse} from '../../../features/flight/unableFlightSlice'
import { useDispatch } from 'react-redux';

const UnableToBookYourFlight = () => {
  const dispatch = useDispatch()
  return (
    <div className=' px-5 sm:px-[45px] bg-white rounded-[30px] w-full h-full flex flex-col items-center pt-[44px] sm:pt-[67px] pb-[30px] sm:pb-[40px]'>
      <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
      <img src={closeIcon} alt="" className=' mt-[31px] w-[35px] h-[35px]' />
      <h1 className=' mt-[16px] font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] '>Unable to Book Flight</h1>
      <h1 className=' mt-[5px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>Apologies, you can't currently book your own flight.
        Our team is here to help—Please contact support for assistance.</h1>

      <button onClick={()=> dispatch(UnableFlightFalse())} className=' flex items-center justify-center text-center mt-[80px] sm:mt-[107px] w-full h-[40px] rounded-[30px] bg-[#EB0728] text-white text-[16px] leading-[19.41px]'>Go to dashboard</button>
    </div>
  )
}

export default UnableToBookYourFlight
