import React from 'react'
import NoMethod from './NoMethod';

const NoMethodModal = ({totalBalance, setWhereTo, access_token, setNoMethod, setAddWithrawalType, bankAdded, mobileMoneyAdded, setAddWithrawalMethod, setReviewWithdrawalMethod}) => {
    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setNoMethod(false); // Call the function to close the modal
            setWhereTo(null)
        }
    }
    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 w-full xm:w-auto">
                    {/*content*/}
                    <div className=" cursor-default border-0  relative flex flex-col text-center w-full  xm:w-[418px] sm:w-[518px] h-[350px] sm:h-[500px] outline-none focus:outline-none">
                        <NoMethod totalBalance={totalBalance} setWhereTo={setWhereTo} access_token={access_token} setNoMethod={setNoMethod} setAddWithrawalType={setAddWithrawalType} bankAdded={bankAdded} mobileMoneyAdded={mobileMoneyAdded} setAddWithrawalMethod={setAddWithrawalMethod} setReviewWithdrawalMethod={setReviewWithdrawalMethod}/>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default NoMethodModal

