import React from 'react'
import amazon from './assets/amazon_available.svg'


// const categories = [
//   { name: "Electronics & Accessories", link: "https://eletdihfduhs.com" },
//   { name: "Baby", link: "https://eletdihfduhs.com" },
//   { name: "Beauty & Personal Care", link: "https://eletdihfduhs.com" },
//   { name: "Clothing, Shoes & Jewellery", link: "https://eletdihfduhs.com" },
//   { name: "Clothing, Shoes & Jewellery", link: "https://eletdihfduhs.com" },
// ];

const categories = [
  { name: "Electronics & Accessories", link: "https://amzn.to/3BaO5ep" },
  { name: "Baby", link: "https://amzn.to/4f5K5d0" },
  { name: "Baby", link: "https://amzn.to/49jefYY" },
  { name: "Baby", link: "https://amzn.to/3ZzeoEh" },
  { name: "USB-C Charger", link: "https://amzn.to/4eZ0Gzk" },
];





const Amazon = () => {

  return (

    <div className=' flex-col w-full max-w-[330px]  lg:max-w-full sm:flex-wrap  h-[430px] border  border-[#C1C1C180] rounded-[30px] border-solid py-[27px] px-[31px]'>
      <img src={amazon} alt="" className=' pb-[20px]' />

      <div className='text-[16px] font-bold leading-[19.41px]'>
        <h1 className=' flex flex-wrap gap-2 mb-[12px]'>Electronics & Accessories <span className='font-normal'><a className='text-[#177EC2] underline' href='https://amzn.to/3BaO5ep'>https://amzn.to/3BaO5ep</a></span></h1>
        <h1 className='mb-[12px] flex  flex-wrap gap-2 '>Electronics & Accessories<span className='font-normal   '><a className='text-[#177EC2] underline' href='https://amzn.to/4eZ0Gzk'>https://amzn.to/3Yi4UNr</a></span></h1>
        <h1 className='mb-[12px] flex  flex-wrap gap-2 '>Baby<span className='font-normal   '><a className='text-[#177EC2] underline' href='https://amzn.to/4f5K5d0'>https://amzn.to/4f5K5d0</a></span></h1>
        <h1 className='mb-[12px] flex flex-wrap gap-2'>Baby <span className='font-normal '><a className='text-[#177EC2] underline' href='https://amzn.to/49jefYY'>https://amzn.to/49jefYY</a></span></h1>
        <h1 className=' mb-[12px] flex  flex-wrap gap-2'> Baby<span className='font-normal   '><a className='text-[#177EC2] underline' href='https://amzn.to/3ZzeoEh'>https://amzn.to/3ZzeoEh</a></span></h1>

      </div>
    </div>
  )
}

export default Amazon
