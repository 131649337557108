// import React, { useEffect, useRef } from 'react';
// import baseUrl from '../baseUrl'
// import profile from './assets/profile.jpg'
// import arrowDown from './assets/arrowDown.svg'
// import callIcon from './assets/call.svg'
// import settingsIcon from './assets/settings.svg'
// import logoutIcon from './assets/logout.svg'
// import withdrawalIcon from './assets/withdrawal.svg'
// import deliveryIcon from './assets/delivery.svg'
// import earningIcon from './assets/earning.svg'
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// function AdminProfileDropdown({access_token, isOpenDropDown, setIsOpenDropDown, setDropDownSwitch }) {
//     const dropdownRef = useRef(null);
//     const navigate = useNavigate()
//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpenDropDown(false);
//             }
//         }

//         // Attach the event listener on component mount
//         document.addEventListener('mousedown', handleClickOutside);

//         // Clean up the event listener on component unmount
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     const toggleDropdown = () => {
//         setIsOpenDropDown(!isOpenDropDown);
//     };

//     const LogOut = async () => {
//         const data = {}
//         let config = {
//             method: 'post',
//             maxBodyLength: Infinity,
//             url: `${baseUrl}/logout/`,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': access_token,
//             },
//             data: data
//         };
//         try {
//             await axios.request(config)
//                 .then((response) => {
//                     console.log(response)
//                 }).catch(error => {
//                     console.log("Axios Catch: => ", error)
//                 })
//         }
//         catch (error) {
//             console.log(error)
//         }

//         window.localStorage.clear()
//         navigate("/")
//         window.location.reload()
//     }

//     const handleManageWithdrawals = async () =>{
//         window.localStorage.setItem("sjhkytadytdfdajstvdhjastcjyasftuysafyujtasyfctjhfsaygutgygjyustfdfgydtsfdytstayfateyzytrr", 0)
//         setDropDownSwitch(0)
//         setIsOpenDropDown(!isOpenDropDown);
//     }
//     const handleManageDeliveryMiles = async () =>{
//         window.localStorage.setItem("sjhkytadytdfdajstvdhjastcjyasftuysafyujtasyfctjhfsaygutgygjyustfdfgydtsfdytstayfateyzytrr", 1)
//         setDropDownSwitch(1)
//         setIsOpenDropDown(!isOpenDropDown);
//     }

//     return (
//         <div ref={dropdownRef} className="relative inline-block text-left">
//             <div>
//                 <button onClick={toggleDropdown} className=' w-[90px] h-[45px] bg-[#D9D9D9] rounded-[50px] flex items-center'>
//                     <div className=' w-full flex items-center justify-center'>
//                         <img src={arrowDown} alt="" className={` ${isOpenDropDown ? "rotate-180" : ""} w-[14.58px] h-[7.29px]`} />
//                     </div>
//                     <div className=' w-full flex flex-row-reverse'>
//                         <img src={profile} alt="" className=' w-[45px] h-[45px] rounded-[45px]' />
//                     </div>
//                 </button>
//             </div>

//             {isOpenDropDown && (
//                 <div className=" origin-top-right absolute right-0 mt-2 w-[251px] rounded-[20px] shadow-input bg-white ring-1 ring-black ring-opacity-5">
//                     <div
//                         className=" flex flex-col pt-[26px] pb-[30px] gap-[10px]"
//                         role="menu"
//                         aria-orientation="vertical"
//                         aria-labelledby="options-menu"
//                     >
//                         {/* <button onClick={()=>navigate('/earnings')} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
//                             <img src={earningIcon} alt="" />
//                             <h1 className=' text-[14px]'>Earnings</h1>
//                         </button>
//                         <button onClick={()=>navigate('/contact')} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
//                             <img src={callIcon} alt="" />
//                             <h1 className=' text-[14px]'>Contact support</h1>
//                         </button>

//                         <button onClick={()=>navigate('/settings')} className=" pl-[20px] w-full flex  hover:bg-gray-100 gap-[17px]">
//                             <img src={settingsIcon} alt="" />
//                             <h1 className=' text-[14px]'>Settings</h1>
//                         </button> */}
//                         <button onClick={handleManageWithdrawals} className=" pl-[20px] w-full flex items-center  hover:bg-gray-100 gap-[17px]">
//                             <img src={withdrawalIcon} alt="" />
//                             <h1 className=' text-[14px]'>Manage withdrawals</h1>
//                         </button>
//                         <button onClick={handleManageDeliveryMiles} className=" pl-[20px] w-full flex items-center  hover:bg-gray-100 gap-[17px]">
//                             <img src={deliveryIcon} alt="" />
//                             <h1 className=' text-[14px]'>Manage Delivery Miles</h1>
//                         </button>

//                         <button onClick={LogOut} className=" pl-[20px] w-full flex items-center  hover:bg-gray-100 gap-[17px]">
//                             <img src={logoutIcon} alt="" />
//                             <h1 className=' text-[14px]'>Logout</h1>
//                         </button>

//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default AdminProfileDropdown;

import React, { useEffect, useRef } from "react";
import baseUrl from "../baseUrl";
import profile from "./assets/profile.jpg";
import arrowDown from "./assets/arrowDown.svg";
// import callIcon from "./assets/call.svg"; // Unused import
// import settingsIcon from "./assets/settings.svg"; // Unused import
import logoutIcon from "./assets/logout.svg";
import withdrawalIcon from "./assets/withdrawal.svg";
import deliveryIcon from "./assets/delivery.svg";
// import earningIcon from "./assets/earning.svg"; // Unused import
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AdminProfileDropdown({
  access_token,
  isOpenDropDown,
  setIsOpenDropDown,
  setDropDownSwitch,
}) {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenDropDown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpenDropDown]); // Add setIsOpenDropDown to the dependency array

  const toggleDropdown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  const LogOut = async () => {
    const data = {};
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/logout/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      data: data,
    };
    try {
      await axios.request(config);
    } catch (error) {
      console.log("Axios Catch: => ", error);
    }

    window.localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const handleManageWithdrawals = async () => {
    window.localStorage.setItem(
      "sjhkytadytdfdajstvdhjastcjyasftuysafyujtasyfctjhfsaygutgygjyustfdfgydtsfdytstayfateyzytrr",
      0
    );
    setDropDownSwitch(0);
    setIsOpenDropDown(!isOpenDropDown);
  };

  const handleManageDeliveryMiles = async () => {
    window.localStorage.setItem(
      "sjhkytadytdfdajstvdhjastcjyasftuysafyujtasyfctjhfsaygutgygjyustfdfgydtsfdytstayfateyzytrr",
      1
    );
    setDropDownSwitch(1);
    setIsOpenDropDown(!isOpenDropDown);
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div>
        <button
          onClick={toggleDropdown}
          className=" w-[90px] h-[45px] bg-[#D9D9D9] rounded-[50px] flex items-center"
        >
          <div className=" w-full flex items-center justify-center">
            <img
              src={arrowDown}
              alt=""
              className={` ${
                isOpenDropDown ? "rotate-180" : ""
              } w-[14.58px] h-[7.29px]`}
            />
          </div>
          <div className=" w-full flex flex-row-reverse">
            <img
              src={profile}
              alt=""
              className=" w-[45px] h-[45px] rounded-[45px]"
            />
          </div>
        </button>
      </div>

      {isOpenDropDown && (
        <div className=" origin-top-right absolute right-0 mt-2 w-[251px] rounded-[20px] shadow-input bg-white ring-1 ring-black ring-opacity-5">
          <div
            className=" flex flex-col pt-[26px] pb-[30px] gap-[10px]"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              onClick={handleManageWithdrawals}
              className=" pl-[20px] w-full flex items-center hover:bg-gray-100 gap-[17px]"
            >
              <img src={withdrawalIcon} alt="" />
              <h1 className=" text-[14px]">Manage withdrawals</h1>
            </button>
            <button
              onClick={handleManageDeliveryMiles}
              className=" pl-[20px] w-full flex items-center hover:bg-gray-100 gap-[17px]"
            >
              <img src={deliveryIcon} alt="" />
              <h1 className=" text-[14px]">Manage Delivery Miles</h1>
            </button>

            <button
              onClick={LogOut}
              className=" pl-[20px] w-full flex items-center hover:bg-gray-100 gap-[17px]"
            >
              <img src={logoutIcon} alt="" />
              <h1 className=" text-[14px]">Logout</h1>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminProfileDropdown;
