import React, { useEffect, useRef, useState } from 'react'
import baseUrl from '../baseUrl.js'
import { useDispatch } from 'react-redux'
import { LogInTrue, LogInFalse } from '../../features/nav/logInSlice'
import { SignUpTrue } from '../../features/nav/signUpSlice'
import './LogIn.css'
import google from './assets/google.png'
import goodifly from './assets/goodifly.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import errorIcon from './assets/error.svg'
import codeSentIcon from './assets/condeSent.svg'
import correctIcon from './assets/correct.svg'
import { useGoogleLogin } from '@react-oauth/google';
import visibility_on from './assets/visibility_on.svg'
import visibility_off from './assets/visibility_off.svg'


const LogIn = () => {
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState("")
    const [restRequestError, setRestRequestError] = useState("")
    const [email, setEmail] = useState("")
    const [passowrd, setPassowrd] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(0)
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("")
    const navigate = useNavigate()

    const [toVerify, setToVerify] = useState(false);
    const [verifyError, setVerifyError] = useState("");
    const [codes, setCodes] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [seconds, setSeconds] = useState(9 * 60 + 59);

    const [newPassowrd, setNewPassowrd] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [newPassError, setNewPassError] = useState("")
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [googleTokenResponse, setGoogleTokenResponse] = useState()
    const [strength, setStrength] = useState(0);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    // Encode the data before storing it
    function encodeData(data) {
        return btoa(data); // Base64 encoding
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = JSON.stringify({
            email: email.toLocaleLowerCase(),
            password: passowrd
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/login/`,
            // url: `http://127.0.0.1:8000/api/login/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        try {
            axios.request(config)
                .then((response) => {
                    const access_token = response?.data?.access_token
                    const data = response?.data
                    if (access_token) {
                        dispatch(LogInFalse())
                        navigate("/dashboard", { state: { data: data } })
                        window.localStorage.setItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy", encodeData(JSON.stringify(data)))
                    }
                })
                .catch((error) => {
                    //Catch User is not confirmed Exception 
                    console.log(error)
                    console.log(error?.response?.data)
                    if (error?.response?.data?.email) {
                        setErrorMessage(error?.response?.data?.email)
                    }
                    else if (error?.response?.data?.detail === 'Email is not verified') {
                        setErrorMessage("Email is not verified")
                        setTimeout(() => {
                            setToVerify(true);
                        }, 2000); // 2000 milliseconds (2 seconds)
                    }
                    else if (error?.response?.data?.detail) {
                        setErrorMessage(error?.response?.data?.detail)
                    }
                    else if (error?.response?.data?.message) {
                        setErrorMessage(error?.response?.data?.message)
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }
    const toForgotPassword = () => {
        setForgotPassword(1)
    }
    const backToLogin = () => {
        setForgotPassword(0)
    }

    const verify = async () => {

        let code = ""
        codes.map(c => {
            return code += c
        })
        if (code === "") {
            setVerifyError("Code is Required")
        }
        else if (code.length < 6) {
            setVerifyError("Invalid Code")
        }
        else {

            let code = ""
            codes.map(c => {
                return code += c
            })
            const data = JSON.stringify({
                otp: code
            })

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${baseUrl}/verify-email/`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            await axios.request(config)
                .then((response) => {
                    const feedback = response.data
                    console.log(feedback)
                    if (feedback.message) {
                        // dispatch(SignUpFalse())
                        // dispatch(LogInTrue())
                        setErrorMessage(feedback.message)
                        setToVerify(false)
                    }
                    else {
                        setVerifyError(feedback.message)
                    }

                })
                .catch((error) => {
                    console.log(error?.response?.data?.message)
                    if (error?.response?.data?.message) {
                        setVerifyError(error?.response?.data?.message)
                    }
                    // if (error?.response?.data?.error?.slice(18, 40) === "(ExpiredCodeException)") {
                    //     setVerifyError(error?.response?.data?.error?.slice(83))
                    // }
                    // else if (error?.response?.data?.error?.slice(85) === "User cannot be confirmed. Current status is CONFIRMED") {
                    //     dispatch(SignUpFalse())
                    //     dispatch(LogInTrue())
                    // }
                    // else if (error?.response?.data?.error === "An error occurred (CodeMismatchException) when calling the ConfirmSignUp operation: Invalid verification code provided, please try again.") {
                    //     setVerifyError("You have entered a wrong verification code")
                    // }
                    // else {
                    //     console.log(error)
                    //     if (error?.response?.data?.error) {
                    //         setVerifyError(error?.response?.data?.error)
                    //     }
                    //     else {
                    //         setVerifyError(error.message + "\n Try Again")
                    //     }
                    // }
                })
        }
    }
    const handleInputChange = (e, index) => {
        const newCodes = [...codes];
        newCodes[index] = e.target.value;
        setCodes(newCodes);

        // Move focus to the next input field if there is a value
        if (e.target.value && index < codes.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text/plain').slice(0, 6);
        const newCodes = pastedText.split('');
        setCodes(newCodes);
    };

    const resendCode = async () => {
        const data = JSON.stringify({
            email: email.toLocaleLowerCase(),
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/resend-verification-email/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios.request(config)
            .then((response) => {
                const feedback = response.data

                console.log(feedback)
                if (feedback.message) {
                    setCodes(['', '', '', '', '', ''])
                    setVerifyError("Code Sent")
                    setSeconds(9 * 60 + 59)
                }
                else {
                    setVerifyError(feedback.message)
                }

            })
            .catch((error) => {
                console.log(error)
                setVerifyError(error.message + "\n Try Again")
            })
    }

    const resetRequestPassword = async (event) => {
        event.preventDefault();
        const data = JSON.stringify({
            email: forgotPasswordEmail.toLocaleLowerCase()
        })
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/password-reset-request/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios.request(config)
            .then((response) => {
                const feedback = response.data
                if (feedback?.message === "We have sent you a code to reset your password") {
                    setRestRequestError(feedback?.message)
                    setTimeout(() => {
                        setForgotPassword(2)
                    }, 3000)
                }
                else {
                    setRestRequestError(feedback?.message)
                }
                // if (feedback?.message === "We have sent you a code to reset your password") {
                //     // dispatch(SignUpFalse())
                //     // dispatch(LogInTrue())
                //     setForgotPassword(2)
                // }
                // else {
                //     if (feedback?.message) {
                //         setRestRequestError(feedback.message)
                //     } else if (feedback?.error === "User not found") {
                //         setRestRequestError("We cannot find an account with this email")
                //     }
                //     else {
                //         setRestRequestError(feedback.error)
                //     }
                // }

            })
            .catch((error) => {
                console.log(error?.response?.data)
                if (error?.response?.data?.email) {
                    setRestRequestError(error?.response?.data?.email[0])
                }
                else if (error?.response?.data?.message) {
                    setRestRequestError(error?.response?.data?.message)
                }
                // if (error?.response?.data?.error === "User not found") {
                //     setRestRequestError("We cannot find an account with this email")
                // }
                else {
                    setRestRequestError(error.message)
                }
            })
    }

    const resendRequestPasswordCode = async (event) => {
        event.preventDefault();
        const data = JSON.stringify({
            email: forgotPasswordEmail.toLocaleLowerCase()
        })
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/password-reset-request/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios.request(config)
            .then((response) => {
                const feedback = response.data

                if (feedback?.message === "We have sent you a code to reset your password") {
                    // dispatch(SignUpFalse())
                    // dispatch(LogInTrue())
                    setCodes(['', '', '', '', '', ''])
                    setVerifyError("Code Sent")
                    setSeconds(9 * 60 + 59)
                }
                else {
                    setRestRequestError(feedback.message)
                }

            })
            .catch((error) => {
                if (error?.data?.data?.error) {
                    setVerifyError(error?.data?.data?.error)
                }
                else if (error?.response?.data?.message) {
                    setVerifyError(error?.response?.data?.message)
                }
                else if (error?.data?.error) {
                    setVerifyError(error?.data?.error)
                }
                else {
                    setVerifyError(error.message)
                }
            })
    }

    const resetVerification = () => {
        let code = ""
        codes.map(c => {
            return code += c
        })
        if (code === "") {
            setVerifyError("Code is Required")
        }
        else if (code.length < 6) {
            setVerifyError("Invalid Code")
        }
        else {
            setForgotPassword(3)
        }
    }

    const handlePasswordChange = (newPassword) => {
        // Regular expressions to check for lowercase, uppercase, and special characters
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numeric = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        // Check if the password meets the criteria
        const containsLowercase = lowercaseRegex.test(newPassword);
        const containsUppercase = uppercaseRegex.test(newPassword);
        const containsNumeric = numeric.test(newPassword)
        const containsSpecialChar = specialCharRegex.test(newPassword);

        // You can further use these boolean values to display error messages or change styles.
        // console.log('Contains lowercase:', containsLowercase);
        // console.log('Contains uppercase:', containsUppercase);
        // console.log('Contains special character:', containsSpecialChar);
        if (!containsLowercase) {
            return "Password Must Contain atleast 1  lowercase"
        }
        else if (!containsUppercase) {
            return "Password Must Contain atleast 1 uppercase"
        }
        else if (!containsNumeric) {
            return "Password Must Contain atleast 1 numeric"
        }
        else if (!containsSpecialChar) {
            return "Password Must Contains atleast 1 special character"
        }
        else {
            return ""
        }
    };

    const changePassword = async () => {
        let passwordError = handlePasswordChange(newPassowrd)
        if (newPassowrd.length < 1) {
            setNewPassError("Password is Required")
        }
        else if (newPassowrd.length < 8) {
            setNewPassError("Password is too short")
        }
        else if (passwordError.length > 0) {
            setNewPassError(passwordError)
        }
        else if (confirmPassword.length < 1) {
            setNewPassError("Confirm Password")
        }
        else if (confirmPassword !== newPassowrd) {
            setNewPassError("Password Mismatch")
        }
        else {
            setNewPassError("")
            let code = ""
            codes.map(c => {
                return code += c
            })

            const data = {
                "otp": `${code}`,
                "password": `${newPassowrd}`
            }
            console.log(data)
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${baseUrl}/password-reset/`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            await axios.request(config)
                .then((response) => {
                    const feedback = response.data
                    if (feedback.message === "Password reset successful") {

                        setForgotPassword(4)
                    }
                    else {
                        setNewPassError(feedback.message)
                    }

                }).catch((error) => {
                    console.log(error.response.data)
                    if (error?.response?.data?.message) {
                        setNewPassError(error?.response?.data?.message)
                    }
                    // if (error?.response?.data?.error === "An error occurred (CodeMismatchException) when calling the ConfirmForgotPassword operation: Invalid verification code provided, please try again.") {
                    //     setForgotPassword(2)
                    //     setVerifyError("Invalid verification code provided, please try again")
                    // }
                    // else if (error?.response?.data?.error === "An error occurred (LimitExceededException) when calling the ConfirmForgotPassword operation: Attempt limit exceeded, please try after some time.") {
                    //     setNewPassError("Attempt limit exceeded, please try after some time.")
                    // }
                    // else if (error?.response?.data?.error) {
                    //     setNewPassError(error?.response?.data?.error)
                    // }
                    // else {
                    //     setNewPassError(error.message + "\n Try Again")
                    // }
                })
        }
    }

    const handleGoogleLoginClick = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setGoogleTokenResponse(tokenResponse)
            try {
                axios.get(
                    "https://www.googleapis.com/oauth2/v3/userinfo",
                    {
                        headers: {
                            Authorization: `Bearer ${tokenResponse.access_token}`,
                        }
                    }
                ).then((response) => {
                    setEmail(response?.data?.email)
                    const data = JSON.stringify({
                        access_token: tokenResponse?.access_token
                    })
                    axios.post(`${baseUrl}/google/`, data, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                        .then((response) => {
                            const access_token = response?.data?.access_token
                            const data = response?.data
                            if (access_token) {
                                dispatch(LogInFalse())
                                navigate("/dashboard", { state: { data: data } })
                                window.localStorage.setItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy", encodeData(JSON.stringify(data)))
                            }
                            else {
                                setErrorMessage(response?.data?.message)
                                dispatch(LogInTrue())
                            }
                        })
                        .catch((error) => {
                            // console.log(error?.response?.data)
                            if(error?.response?.data){
                                if(error?.response?.data?.message){
                                    if(error?.response?.data?.message==="Account not verified"){
                                        setErrorMessage("Email is not verified")
                                        setTimeout(() => {
                                            setToVerify(true);
                                        }, 2000); // 2000 milliseconds (2 seconds)
                                    }
                                    else if(error?.response?.data?.message==="Account not found"){
                                        setErrorMessage(error?.response?.data?.message)
                                        setTimeout(() => {
                                            dispatch(LogInFalse())
                                            dispatch(SignUpTrue())
                                        }, 2000); // 2000 milliseconds (2 seconds)
                                    }
                                    else{
                                        setErrorMessage(error?.response?.data?.message)
 
                                    }
                                }
                                else{
                                    console.log("Endpoint Error: => ",error)
                                }
                            }else{
                                console.log("Endpoint Error: => ",error)
                            }
                        })
                }).catch(error=>{
                    console.log("Google Erro: => ", console.log(error))
                })
            }
            catch (err) {
                console.log(err)
            }
        }
    });

    const tester = (value) => {
        let criteriaMet = 0;

        if (value.length >= 8) criteriaMet++;
        if (/[A-Z]/.test(value)) criteriaMet++;
        if (/[a-z]/.test(value)) criteriaMet++;
        if (/\d/.test(value)) criteriaMet++;
        if (/[!@#$%^&*]/.test(value)) criteriaMet++;

        return criteriaMet
    }

    const newPasswordOnChange = (value) => {
        setNewPassowrd(value)
        setStrength(tester(value));
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
            // document.body.removeChild(script);
        };

    }, [seconds, googleTokenResponse]);


    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format the time to display leading zeros (e.g., 05:03)
    const formattedTime = String(minutes).padStart(2, '0') + ':' + String(remainingSeconds).padStart(2, '0');



    return (
        <div className='flex'>
            {forgotPassword === 0 ?

                !toVerify ?
                    <div className='  '>
                        <div className=' mb-[28px]'>
                            <h1 className='text-[18px] leading-[21.83px] font-bold pb-[4px]'>Welcome to Goodifly</h1>
                            <h1 className=' text-[16px] leading-[19.41px] text-[#707070] font-normal'>Login details</h1>
                        </div>
                        <div className=''>
                            {/**Log in */}
                            <div className=' box-border flex flex-wrap '>
                                {errorMessage.length > 0 ? <h1 className={` ${errorMessage === "Email verified successfully" ? "bg-[#D6FBC9] border-[#4FBF26] text-[#4FBF26]" : "bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]"} mt-[23px] mb-[8px] py-[5px] flex justify-center items-center text-center rounded-[20px] border-solid border-[1px] w-full -mt-[20px] text-[12px]`}>
                                    {errorMessage === "Email verified successfully" ?
                                        <img src={codeSentIcon} alt="" className='mr-[5px]' />
                                        :
                                        <img src={errorIcon} alt="" className='mr-[5px]' />}
                                    {errorMessage}
                                </h1> : null}

                                <div className=' box-border m-0 basis-full flex-grow-0 max-w-full '>
                                    {/** Email Form */}
                                    <div className='box-border m-0 basis-full flex-grow-0 max-w-full'>
                                        <form method='post' onSubmit={handleSubmit}>
                                            <div className=' box-border flex flex-wrap items-center'>
                                                {/** Email */}
                                                <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                                                    <div className=' box-border flex flex-wrap w-full'>
                                                        <div className='w-full flex items-start'>
                                                            <input value={email} onChange={(e) => setEmail(e.target.value)} required type="email" name="email" id="email" data-cy="email" placeholder="Email" className=' m-0 outline-none text-[16px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] leading-[19.41px] rounded-[50px] pl-5 ' />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/** Password */}
                                                <div className=' mt-[12px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                                                    <div className=' box-border flex flex-wrap w-full'>
                                                        <div className='w-full flex items-start'>
                                                            <input value={passowrd} onChange={(e) => setPassowrd(e.target.value)} required type={showPassword ? "text" : "password"} name="password" id="password" data-cy="password" placeholder="Password" className=' m-0 outline-none text-[16px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] leading-[19.41px] rounded-[50px] pl-5 ' />
                                                            <div onClick={togglePasswordVisibility} className='-ml-[39px] mt-[10px] cursor-pointer '>
                                                                {showPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/**Forget Password */}
                                                <h1 onClick={() => toForgotPassword()} className=' text-[12px] leading-[14.56px] font-normal mt-[6px] cursor-pointer text-[#707070]'>Forgot password?</h1>
                                                {/** Login button */}
                                                <button type="submit" className=' mt-[18px] bg-[#EB0728] py-[10px] relative border-none rounded-[50px] select-none text-white text-[16] w-full leading-[19.41px]'>Login</button>

                                                <h1 className=' w-full flex justify-center text-center text-[#707070] text-[14px] leading-[16.98px] font-bold mt-[32px]'>OR LOGIN WITH</h1>

                                                {/**Login With Google */}
                                                <div onClick={()=>{setErrorMessage(''); handleGoogleLoginClick()}} className=' cursor-pointer gap-[7px] mt-[10px] w-full flex items-center justify-center text-center rounded-[50px] border-[1px] border-[#C1C1C1]'>
                                                    <img src={google} alt="" className='w-[24px] h-[24px]' />
                                                    <h1 className='text-[14px]  py-[10px] leading-[19.12px] text-[#707070]'>Sign in with Google</h1>
                                                </div>

                                                {/**Sign Up */}
                                                <h1 className=' whitespace-nowrap w-full text-center text-[#707070] font-bold leading-[15.56px] text-[12px] mt-[10px] '>Don’t have a Goodifly account? <span onClick={() => {
                                                    dispatch(LogInFalse())
                                                    dispatch(SignUpTrue())
                                                }} className=' text-[#EB0728]  cursor-pointer'>Sign up</span></h1>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    // <div>
                    //     {enterCode ?
                    <div className=' '>
                        {/**Enter Code to Verify */}
                        <div className=' w-full flex flex-col items-center justify-center text-center'>
                            <img src={goodifly} alt="" className=' h-[33px]  w-[107px]' />
                            {verifyError?.length > 0 ?
                                <h1 className={` ${verifyError === "Code Sent" ? "bg-[#D6FBC9] border-[1px] border-[#4FBF26] text-[#4FBF26]" : "bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]"}  py-[5px] flex justify-center items-center text-center rounded-[20px]  border-solid border-[1px]  w-full mt-[15px] text-[12px] `}>
                                    {verifyError === "Code Sent" ?
                                        <img src={codeSentIcon} alt="" className='mr-[5px]' />
                                        :
                                        <img src={errorIcon} alt="" className='mr-[5px]' />}
                                    {verifyError}
                                </h1> : null}
                            <h1 className=' mt-[25px] text-[18px] font-bold'>Enter your verification code</h1>
                            <h1 className=' mt-3 text-[14px] text-[#707070]'>
                                We just sent a verification code to your email
                            </h1>
                            <div className='flex items-center justify-center mt-[19px]'>
                                {codes.map((code, index) => (
                                    <input
                                        key={index}
                                        type="tel"
                                        className="w-[35.17px] h-[35.17px] text-2xl border-[1px] border-[#AAAAAA] rounded-lg text-center mx-2"
                                        maxLength="1"
                                        value={code}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onPaste={(e) => handlePaste(e)}
                                        ref={(input) => (inputRefs.current[index] = input)} // Assign the ref
                                    />
                                ))}
                            </div>
                            <h1 className=' mt-[18.83px] text-[#707070] text-[14px]'>Code expires in <span className=' text-[#EB0728] font-bold'>{formattedTime}</span></h1>
                            <h1 className=' mt-[27px] text-[12px] text-[#707070]'>Didn’t get a code? <span onClick={resendCode} className=' cursor-pointer font-bold text-[#EB0728]'>Click to resend</span></h1>
                            {/** Rsesnd button */}
                            <button onClick={verify} type="button" className=' mt-[10px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[1rem] w-full '>Verify</button>
                        </div>
                    </div>
         
                :
                forgotPassword === 1 ?
                    <form method='post' onSubmit={resetRequestPassword}>
                        <div className=' '>
                            <div className=' w-full flex flex-col items-center justify-center text-center'>
                                <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                                {restRequestError.length > 0 ? <h1 className={` ${restRequestError === 'We have sent you a code to reset your password' ? 'bg-[#D6FBC9] border-[#4FBF26] text-[#4FBF26]' : 'bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]'} py-[5px] flex justify-center items-center text-center rounded-[20px] border-solid border-[1px] w-full mt-[15px] text-[12px]  `}>
                                    {restRequestError === "We have sent you a code to reset your password" ?
                                        <img src={codeSentIcon} alt="" className='mr-[5px]' />
                                        :
                                        <img src={errorIcon} alt="" className='mr-[5px]' />}
                                    {restRequestError}
                                </h1> : null}
                                <h1 className=' mt-[25px] text-[18px] font-bold'>Forgot Password?</h1>
                                <h1 className=' mt-1 text-[14px] text-[#707070]'>
                                    No worries, we will send you reset instructions
                                </h1>
                                {/** email */}
                                <div className=' w-full mt-[23px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                                    <div className=' box-border flex flex-wrap w-full'>
                                        <div className='w-full flex items-start'>
                                            <input value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)} required type="email" name="forgotPasswordEmail" id="forgotPasswordEmail" data-cy="forgotPasswordEmail" placeholder="Email" className='placeholder-[#AAAAAA] m-0 text-[16px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-5 ' />
                                        </div>
                                    </div>
                                    {/** reset pasword button */}
                                    <button type="submit" className=' mt-[15px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[1rem] w-full '>reset password</button>
                                    <button onClick={() => backToLogin()} className=' mt-[29px] font-bold text-[12px] text-[#707070]'>BACK TO LOGIN</button>
                                </div>

                            </div>
                        </div>
                    </form> : forgotPassword === 2
                        ?

                            <div className=' w-full flex flex-col items-center justify-center text-center'>
                                <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                                {verifyError?.length > 0 ?
                                    <h1 className={` ${verifyError === "Code Sent" ? "bg-[#D6FBC9] border-[1px] border-[#4FBF26] text-[#4FBF26]" : "bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]"}  py-[5px] flex justify-center items-center text-center rounded-[20px]  border-solid border-[1px]  w-full mt-[15px] text-[12px] `}>
                                        {verifyError === "Code Sent" ?
                                            <img src={codeSentIcon} alt="" className='mr-[5px]' />
                                            :
                                            <img src={errorIcon} alt="" className='mr-[5px]' />}
                                        {verifyError}
                                    </h1> : null}

                                <h1 className=' mt-[25px] text-[18px] font-bold'>Enter your verification code</h1>
                                <h1 className=' mt-3 text-[14px] text-[#707070]'>
                                    We just sent a verification code to your email
                                </h1>
                                <div className='flex items-center justify-center mt-[19px]'>
                                    {codes.map((code, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            className="w-[35.17px] h-[35.17px] text-2xl border-[1px] border-[#AAAAAA] rounded-lg text-center mx-2"
                                            maxLength="1"
                                            value={code}
                                            onChange={(e) => handleInputChange(e, index)}
                                            onPaste={(e) => handlePaste(e)}
                                            ref={(input) => (inputRefs.current[index] = input)} // Assign the ref
                                        />
                                    ))}
                                </div>
                                <h1 className=' mt-[18.83px] text-[#707070] text-[14px]'>Code expires in <span className=' text-[#EB0728] font-bold'>{formattedTime}</span></h1>
                                <h1 className=' mt-[27px] text-[12px] text-[#707070]'>Didn’t get a code? <span onClick={resendRequestPasswordCode} className=' cursor-pointer font-bold text-[#EB0728]'>Click to resend</span></h1>
                                {/** Rsesnd button */}
                                <button onClick={resetVerification} type="button" className=' mt-[10px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[1rem] w-full '>Verify</button>
                            </div>
                         :
                        forgotPassword === 3 ?
                            <div className=' '>
                                <div className=' w-full flex flex-col items-center justify-center text-center'>
                                    <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                                    {newPassError.length > 0 ? <h1 className=' py-[5px] flex justify-center items-center text-center rounded-[20px] bg-[#FFDFE4] border-solid border-[1px] border-[#EB0728] w-full mt-[15px] text-[12px] text-[#EB0728]'>
                                        <img src={errorIcon} alt="" className='mr-[5px]' />
                                        {newPassError}
                                    </h1> : null}
                                    <h1 className=' mt-[25px] text-[18px] font-bold'>Change Password</h1>
                                    <h1 className=' mt-3 text-[14px] text-[#707070]'>
                                        Password should be longer than 8 characters
                                    </h1>
                                    <div className=' box-border flex flex-wrap items-center'>
                                        {/** Password */}
                                        <div className=' mt-[15px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className='w-full flex items-start'>
                                                    <input value={newPassowrd} onChange={(e) => newPasswordOnChange(e.target.value)} required type={showNewPassword ? "text" : "password"} name="password" id="password" data-cy="password" placeholder="New Password" className={`
                                                                ${(newPassowrd.length > 0 && handlePasswordChange(newPassowrd) !== "") ||
                                                            (newPassowrd.length < 4 && newPassError === "Password is Required") ||
                                                            (newPassowrd.length < 4 && newPassError === "Password is too short") ||
                                                            newPassError === "Password Mismatch"
                                                            ? " border-[1px] border-[#EB0728]" : ""}  m-0 placeholder-[#AAAAAA] text-[16px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-5 `} />
                                                    <div className=' -ml-[100px] flex mt-4 gap-[5px] pr-[50px]'>
                                                        <div className={` ${newPassowrd.length < 1 ? "bg-[#D9D9D9]" : newPassowrd.length < 6 ? "bg-[#EB0728]" : strength <= 2 ? "bg-[#EB0728]" : strength === 3 ? " bg-yellow-300" : strength >= 4 ? "bg-[#92D16B]" : "bg-[#92D16B]"} w-2 h-2 rounded-[8px]`} />
                                                        <div className={` ${newPassowrd.length < 7 ? "bg-[#D9D9D9]" : strength === 1 ? "bg-[#D9D9D9]" : strength === 2 ? "bg-[#EB0728]" : strength === 3 ? " bg-yellow-300" : strength >= 4 ? "bg-[#92D16B]" : "bg-[#92D16B]"} w-2 h-2 rounded-[8px]`} />
                                                        <div className={` ${newPassowrd.length < 8 ? "bg-[#D9D9D9]" : strength === 3 ? " bg-yellow-300" : strength >= 4 ? "bg-[#92D16B]" : ""} w-2 h-2 rounded-[8px]`} />
                                                        <div className={` ${newPassowrd.length >= 8 && strength >= 4 ? "bg-[#92D16B]" : "bg-[#D9D9D9]"} w-2 h-2 rounded-[8px]`} />
                                                        <div className={` ${newPassowrd.length >= 8 && strength >= 5 ? "bg-[#92D16B]" : "bg-[#D9D9D9]"} w-2 h-2 rounded-[8px]`} />
                                                    </div>
                                                    <div onClick={toggleNewPasswordVisibility} className='-ml-[39px] mt-[10px] cursor-pointer '>
                                                        {showNewPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                                                    </div>
                                                </div>
                                                {handlePasswordChange(newPassowrd) === "Password Must Contain atleast 1 numeric" || newPassError === "Password is Required" || newPassError === "Password is too short" || newPassError === "Password Mismatch"
                                                    || handlePasswordChange(newPassowrd) === "Password Must Contain atleast 1  lowercase" ||
                                                    handlePasswordChange(newPassowrd) === "Password Must Contain atleast 1 uppercase" ||
                                                    handlePasswordChange(newPassowrd) === "Password Must Contains atleast 1 special character" ? <h1 className={`${newPassError.length < 1 ? "hidden" : "flex"} pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left`}>{newPassowrd.length > 0 && handlePasswordChange(newPassowrd) !== "" ? handlePasswordChange(newPassowrd) :
                                                        (newPassowrd.length < 4 && newPassError === "Password is Required") ||
                                                            (newPassowrd.length < 4 && newPassError === "Password is too short") ||
                                                            newPassError === "Password Mismatch" ? newPassError : ""}</h1> : null}
                                               
                                            </div>
                                        </div>

                                        {/** Confirm Password */}
                                        <div className=' mt-[15px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            <div className=' box-border flex flex-wrap w-full'>
                                                <div className='w-full flex items-start'>
                                                    <div className=' w-full'>
                                                        <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required type={showConfirmPassword ? "text" : "password"} name="confirm password" id="confirm_password" data-cy="password" placeholder="Re-enter Password" className={`${newPassError === "Confirm Password" || newPassError === "Password Mismatch" ? " border-[1px] border-[#EB0728]" : ""}  m-0 placeholder-[#AAAAAA] text-[15px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-[20px] `} />
                                                        {newPassError === "Confirm Password" || newPassError === "Password Mismatch" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{newPassError}</h1> : null}
                                                        {newPassError === "Confirm Password" || newPassError === "Password Mismatch" ?
                                                            <div className=' w-full flex flex-row-reverse z-50 pb-[33px] -mt-[3.3rem] pr-4'>
                                                                <img src={errorIcon} alt="" className=' z-[60]' />
                                                            </div> :
                                                            null}
                                                    </div>
                                                    <div onClick={toggleConfirmPasswordVisibility} className='-ml-[39px] mt-[10px] cursor-pointer '>
                                                        {showConfirmPassword ? <img src={visibility_on} alt="" /> : <img src={visibility_off} alt="" />}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <button onClick={changePassword} type="button" className=' mt-[10px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[1rem] w-full '>change password</button>
                                    <button onClick={() => backToLogin()} className=' mt-[29px] font-bold text-[12px] text-[#707070]'>BACK TO LOGIN</button>
                                </div>
                            </div> :
                            forgotPassword === 4 ?
                                <div className=' '>
                                    <div className=' w-full flex flex-col items-center justify-center text-center'>
                                        <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                                        <img src={correctIcon} alt="" className=' mt-[31px]' />
                                        <h1 className=' mt-[16px] text-[18px] font-bold'>Password Reset</h1>
                                        <h1 className=' mt-1 text-[14px] text-[#707070]'>
                                            Your password has been successfully reset<br></br>
                                            Click below to login
                                        </h1>
                                        {/** email */}
                                        <div className=' w-full mt-[16px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                                            {/** login */}
                                            <button onClick={() => backToLogin()} type="button" className=' mt-[15px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[1rem] w-full '>Login</button>
                                        </div>
                                    </div>
                                </div> : ""
            }

        </div>
    )
}

export default LogIn
