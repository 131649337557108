// import React, { useEffect } from 'react'
// import goodifly from "../assets/goodifly.png"
// import successIcon from "../assets/success.svg"
// import baseUrl from '../../baseUrl'
// import axios from 'axios'
// import { useDispatch } from 'react-redux';
// import { sessionTrue } from '../../../features/pages/sessionSlice';

// const CancelRequest = ({ access_token, cancelRequest, setCancelRequest, confirm, setConfirm }) => {
//   const dispatch = useDispatch()

//   const handleCancel = (item) => {
//     let config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/withdrawal-initiate/`,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${access_token}`
//       },
//       data: {
//         withdrawal_id: item?.id,
//         admin_approval: 3
//       }
//     };

//     try {
//       axios.request(config)
//         .then((response) => {
//           if (response?.data) {
//             console.log(response?.data)
//             setConfirm(response?.data)
//           }
//         }).catch((error) => {
//           if (error?.response?.data?.messages) {
//             if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//               dispatch(sessionTrue())
//             }
//           }
//           else {
//             console.log(error)
            
//           }
//         });

//     } catch (error) {
//       console.log(error)
//     }

//   }

//   useEffect(() => {
//     if (confirm) {
//       const timer = setTimeout(() => {
//         setCancelRequest(null)
//         setConfirm(null)
//       }, 3000)
//       return () => clearTimeout(timer);
//     }
//   }, [confirm])

//   return (
//     <>
//       {confirm ?
//         <div className='bg-white xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center text-left pl-[25px] rounded-[40px] gap-[13px]'>
//           <img src={successIcon} alt="" />
//           <div className='flex flex-col'>
//             <h1 className=' text-[18px] font-bold'>Success!</h1>
//             <h1 className='text-[14px] text-[#707077]'>This payment has been cancelled successfully</h1>
//           </div>
//         </div> :
//         <div className=' bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] flex flex-col items-center justify-center pt-[50px] sm:pt-[98px] pb-[60px] sm:pb-[105px]'>
//           <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
//           <h1 className=' text-[18px] font-bold mt-[20px] sm:mt-[47px]'>Cancel request</h1>
//           <h1 className=' w-full flex text-center items-center justify-center text-[#707070] text-[16px] mt-[7px]'>Are you sure you want to cancel this withdrawal request?</h1>
//           <div className=' w-full px-6 mt-[41px] gap-[24px] flex items-center justify-center'>
//             <button onClick={() => setCancelRequest(null)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>No</button>
//             <button onClick={() => handleCancel(cancelRequest)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Yes</button>
//           </div>
//         </div>
//       }
//     </>
//   )
// }

// export default CancelRequest


import React, { useEffect } from "react";
import goodifly from "../assets/goodifly.png";
import successIcon from "../assets/success.svg";
import baseUrl from "../../baseUrl";
import axios from "axios";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../../features/pages/sessionSlice";

const CancelRequest = ({
  access_token,
  cancelRequest,
  setCancelRequest,
  confirm,
  setConfirm,
}) => {
  const dispatch = useDispatch();

  const handleCancel = async (item) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/withdrawal-initiate/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        withdrawal_id: item?.id,
        admin_approval: 3,
      },
    };

    try {
      const response = await axios.request(config);
      if (response?.data) {
        setConfirm(response?.data);
      }
    } catch (error) {
      if (error?.response?.data?.messages) {
        const message = error?.response?.data?.messages[0]?.message;
        if (message === "Token is invalid or expired") {
          dispatch(sessionTrue());
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (confirm) {
      const timer = setTimeout(() => {
        setCancelRequest(null);
        setConfirm(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [confirm, setCancelRequest, setConfirm]);

  return (
    <>
      {confirm ? (
        <div className="bg-white xs:w-[329px] xm:w-[418px] sm:w-[518px] h-[80px] flex items-center text-left pl-[25px] rounded-[40px] gap-[13px]">
          <img src={successIcon} alt="Success Icon" />
          <div className="flex flex-col">
            <h1 className="text-[18px] font-bold">Success!</h1>
            <h1 className="text-[14px] text-[#707077]">
              This payment has been cancelled successfully
            </h1>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] flex flex-col items-center justify-center pt-[50px] sm:pt-[98px] pb-[60px] sm:pb-[105px]">
          <img
            src={goodifly}
            alt="Goodifly Logo"
            className="w-[107px] h-[33px]"
          />
          <h1 className="text-[18px] font-bold mt-[20px] sm:mt-[47px]">
            Cancel request
          </h1>
          <h1 className="w-full flex text-center items-center justify-center text-[#707070] text-[16px] mt-[7px]">
            Are you sure you want to cancel this withdrawal request?
          </h1>
          <div className="w-full px-6 mt-[41px] gap-[24px] flex items-center justify-center">
            <button
              onClick={() => setCancelRequest(null)}
              className="w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]"
            >
              No
            </button>
            <button
              onClick={() => handleCancel(cancelRequest)}
              className="w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]"
            >
              Yes
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CancelRequest;
