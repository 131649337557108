// import React, { useState } from 'react'
// import goodifly from '../assets/goodifly.png'
// import axios from 'axios'
// import baseUrl from '../../baseUrl'
// import { useDispatch } from 'react-redux';
// import { sessionTrue } from '../../../features/pages/sessionSlice';

// const SednerRecipientViewModal = ({ access_token, setViewSednerRecipientViewModal, viewSednerRecipientViewModal, setViewFlaggedViewModal, setViewSuccessModal }) => {
//     const dispatch = useDispatch()

//     //disable states
//     const [disabledReceived, setDisabledReceived] = useState(false)
//     const [disabledApprove, setDisabledApprove] = useState(false)
//     const [disabledComplete, setDisabledComplete] = useState(false)
//     const [disabledRemoveFlag, setDisabledRemoveFlag] = useState(false)

//     const hideModal = (event) => {
//         const modal = document.getElementById('modal');
//         // Check if the clicked element is outside of the modal
//         if (event.target !== modal && !modal.contains(event.target)) {
//             setViewSednerRecipientViewModal(null)
//         }
//     }
//     const handleReceived = async (item) => {
//         setDisabledReceived(true)
//         const data = JSON.stringify({
//             flight_book: item.flight_book,
//             item_description: item.item_description,
//             item_fee: item.item_fee,
//             item_weight: item.item_weight,
//             sender: item.sender,
//             carrier_acceptance_status: 3,
//             agent_approval_inbound: 2
//         })
//         let config = {
//             method: 'patch',
//             maxBodyLength: Infinity,
//             url: `${baseUrl}/booking/${item.id}/`,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${access_token}`
//             },
//             data: data
//         };

//         await axios.request(config)
//             .then((response) => {
//                 if (response?.data?.id) {
//                     setViewSednerRecipientViewModal(null)
//                     setViewSuccessModal("This request is now marked as received.")
//                     setDisabledReceived(false)
//                 }
//             }).catch((error) => {
//                 setDisabledReceived(false)
//                 if (error?.response?.data?.messages) {
//                     if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//                         dispatch(sessionTrue())

//                     }
//                 }
//                 console.log(error)
//             })
//     }

//     const handleApprove = async (item) => {
//         setDisabledApprove(true)
//         const data = JSON.stringify({
//             flight_book: item.flight_book,
//             item_description: item.item_description,
//             item_fee: item.item_fee,
//             item_weight: item.item_weight,
//             sender: item.sender,
//             carrier_acceptance_status: 3,
//             agent_approval_inbound: 3
//         })
//         let config = {
//             method: 'patch',
//             maxBodyLength: Infinity,
//             url: `${baseUrl}/booking/${item.id}/`,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${access_token}`
//             },
//             data: data
//         };

//         await axios.request(config)
//             .then((response) => {
//                 if (response?.data?.id) {
//                     setViewSednerRecipientViewModal(null)
//                     setViewSuccessModal("This request is now approved!.")
//                     setDisabledApprove(false)
//                 }
//             }).catch((error) => {
//                 setDisabledApprove(false)
//                 if (error?.response?.data?.messages) {
//                     if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//                         dispatch(sessionTrue())

//                     }
//                 }
//                 console.log(error)
//             })
//     }

//     const handleComplete = async (item) => {
//         setDisabledComplete(true)
//         const data = JSON.stringify({
//             flight_book: item.flight_book,
//             item_description: item.item_description,
//             item_fee: item.item_fee,
//             item_weight: item.item_weight,
//             sender: item.sender,
//             carrier_acceptance_status: 3,
//             agent_approval_inbound: 4,
//             agent_approval_outbound: 1

//         })
//         let config = {
//             method: 'patch',
//             maxBodyLength: Infinity,
//             url: `${baseUrl}/booking/${item.id}/`,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${access_token}`
//             },
//             data: data
//         };

//         await axios.request(config)
//             .then((response) => {
//                 if (response?.data?.id) {
//                     setViewSednerRecipientViewModal(null)
//                     setViewSuccessModal("This request is now completed!.")
//                     setDisabledComplete(false)
//                 }
//             }).catch((error) => {
//                 setDisabledComplete(false)
//                 if (error?.response?.data?.messages) {
//                     if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//                         dispatch(sessionTrue())

//                     }
//                 }
//                 console.log(error)
//             })
//     }

//     const handleRemoveFlag = async (item) =>{
//         setDisabledRemoveFlag(true)
//         const data = JSON.stringify({
//             flight_book: item?.flight_book,
//             item_description: item?.item_description,
//             item_fee: item?.item_fee,
//             item_weight: item?.item_weight,
//             sender: item?.sender,
//             carrier_acceptance_status: 3,
//             agent_approval_inbound: parseInt(item?.agent_approval_inbound) - 4,
//             agent_inbound_comment: null
//         })
//         let config = {
//             method: 'patch',
//             maxBodyLength: Infinity,
//             url: `${baseUrl}/booking/${item.id}/`,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${access_token}`
//             },
//             data: data
//         };

//         await axios.request(config)
//             .then((response) => {
//                 if (response?.data?.id) {
//                     setViewSednerRecipientViewModal(null)
//                     setViewSuccessModal("The flag has been removed from the request.")
//                     setDisabledRemoveFlag(false)
//                 }
//             }).catch((error) => {
//                 setDisabledRemoveFlag(false)
//                 if (error?.response?.data?.messages) {
//                     if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
//                         dispatch(sessionTrue())

//                     }
//                 }
//                 console.log(error)
//             })

//     }


//     const handleFlagged = async (item) => {
//         setViewSednerRecipientViewModal(null)
//         setViewFlaggedViewModal(item)
//     }

//     return (
//         <div>
//             <div
//                 onClick={hideModal}
//                 className=" cursor-pointer  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
//             >
//                 <div id='modal' className=" w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0 ">
//                     {/*content*/}
//                     <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                         {/**Main Item */}
//                         <div className=' w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]'>
//                             <img src={goodifly} alt="logo" className=' w-[107px] h-[33px]' />
//                             {/**Sender Info */}
//                             <div className=' mt-[27px] flex gap-1'>
//                                 <div className=' flex flex-col gap-1'>
//                                     <h1 className=' font-bold text-[18px] leading-[21.83px]'>{viewSednerRecipientViewModal?.item_sender_name}</h1>
//                                     <p className=' text-[16px] leading-[19.41px]'>{viewSednerRecipientViewModal?.item_sender_number}</p>
//                                 </div>
//                                 <div className=' w-[77px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]'><span>Sender</span></div>
//                             </div>
//                             {/**Item Info */}
//                             <div className='w-full min-h-[141px] rounded-[20px] flex flex-col justify-between border-solid border-[1px] border-[#C1C1C180] mt-[22px] pt-3'>
//                                 {/**Item Type and Price */}
//                                 <div className=' px-4 sm:px-[21px]'>
//                                     <div className='w-full flex items-center justify-between'>
//                                         <div className='flex flex-col gap-[2px]'>
//                                             <p className=' text-[14px] text-[#707070] leading-[16.98px]'>Item type</p>
//                                             {viewSednerRecipientViewModal?.is_electronic === true ? <h1 className=' font-bold text-[16px] leading-[19.41px]'>Electronics</h1> : null}
//                                             {viewSednerRecipientViewModal?.is_clothing === true ? <h1 className=' font-bold text-[16px] leading-[19.41px]'>Clothing</h1> : null}
//                                         </div>
//                                         <h1 className=' font-bold text-[18px] text-[#EB0728] leading-[21.83px]'>{viewSednerRecipientViewModal?.item_weight}kg</h1>
//                                     </div>
//                                 </div>
//                                 {/**Item description */}
//                                 <div className='w-full min-h-[79px] rounded-b-[20px]  px-4 sm:px-[21px] pt-[11px] bg-[#E5E5E580] flex flex-col gap-1'>
//                                     <p className=' text-[14px] text-[#707070] leading-[16.98px]'>Item description</p>
//                                     <h1 className=' font-bold text-[16px] leading-[19.41px]'>{viewSednerRecipientViewModal?.item_description}</h1>
//                                 </div>
//                             </div>
//                             {/**Buttons */}
//                             <div className=' w-full mt-[22px]  px-4 sm:px-[21px] flex gap-[14px] '>
//                                 {
//                                     viewSednerRecipientViewModal?.agent_approval_inbound === 1 ?
//                                         <button disabled={disabledReceived} onClick={() => handleReceived(viewSednerRecipientViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Mark as received</button>
//                                         : viewSednerRecipientViewModal?.agent_approval_inbound === 2 ?
//                                             <button disabled={disabledApprove} onClick={() => handleApprove(viewSednerRecipientViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Approve</button>
//                                             : viewSednerRecipientViewModal?.agent_approval_inbound === 3 ?
//                                                 <button disabled={disabledComplete} onClick={() => handleComplete(viewSednerRecipientViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Complete</button>
//                                                 : null
//                                 }
//                                 {
//                                     viewSednerRecipientViewModal?.agent_approval_inbound < 4 ?
//                                         <button onClick={() => handleFlagged(viewSednerRecipientViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-white border-solid border-[1px] boerder-[#C1C1C1] text-[16px] text-black leading-[19.41px]'>Flag</button>
//                                         : null
//                                 }
//                                 {
//                                     viewSednerRecipientViewModal?.agent_approval_inbound >= 5 ?
//                                         <button disabled={disabledRemoveFlag} onClick={() => handleRemoveFlag(viewSednerRecipientViewModal)} type="button" className=' disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Remove flag</button>
//                                         : null
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
//         </div>
//     )
// }

// export default SednerRecipientViewModal


import React, { useState } from "react";
import goodifly from "../assets/goodifly.png";
import axios from "axios";
import baseUrl from "../../baseUrl";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../../features/pages/sessionSlice";

const SednerRecipientViewModal = ({
  access_token,
  setViewSednerRecipientViewModal,
  viewSednerRecipientViewModal,
  setViewFlaggedViewModal,
  setViewSuccessModal,
}) => {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState({
    received: false,
    approve: false,
    complete: false,
    removeFlag: false,
  });

  const hideModal = (event) => {
    const modal = document.getElementById("modal");
    if (event.target !== modal && !modal.contains(event.target)) {
      setViewSednerRecipientViewModal(null);
    }
  };

  const handleApiRequest = async (
    item,
    updateData,
    successMessage,
    disableKey
  ) => {
    setDisabled((prev) => ({ ...prev, [disableKey]: true }));
    const data = JSON.stringify(updateData);
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response?.data?.id) {
        setViewSednerRecipientViewModal(null);
        setViewSuccessModal(successMessage);
      }
    } catch (error) {
      if (
        error?.response?.data?.messages?.[0]?.message ===
        "Token is invalid or expired"
      ) {
        dispatch(sessionTrue());
      }
      console.error(error);
    } finally {
      setDisabled((prev) => ({ ...prev, [disableKey]: false }));
    }
  };

  const handleReceived = (item) => {
    handleApiRequest(
      item,
      {
        flight_book: item.flight_book,
        item_description: item.item_description,
        item_fee: item.item_fee,
        item_weight: item.item_weight,
        sender: item.sender,
        carrier_acceptance_status: 3,
        agent_approval_inbound: 2,
      },
      "This request is now marked as received.",
      "received"
    );
  };

  const handleApprove = (item) => {
    handleApiRequest(
      item,
      {
        flight_book: item.flight_book,
        item_description: item.item_description,
        item_fee: item.item_fee,
        item_weight: item.item_weight,
        sender: item.sender,
        carrier_acceptance_status: 3,
        agent_approval_inbound: 3,
      },
      "This request is now approved!",
      "approve"
    );
  };

  const handleComplete = (item) => {
    handleApiRequest(
      item,
      {
        flight_book: item.flight_book,
        item_description: item.item_description,
        item_fee: item.item_fee,
        item_weight: item.item_weight,
        sender: item.sender,
        carrier_acceptance_status: 3,
        agent_approval_inbound: 4,
        agent_approval_outbound: 1,
      },
      "This request is now completed!",
      "complete"
    );
  };

  const handleRemoveFlag = (item) => {
    handleApiRequest(
      item,
      {
        flight_book: item?.flight_book,
        item_description: item?.item_description,
        item_fee: item?.item_fee,
        item_weight: item?.item_weight,
        sender: item?.sender,
        carrier_acceptance_status: 3,
        agent_approval_inbound: parseInt(item?.agent_approval_inbound) - 4,
        agent_inbound_comment: null,
      },
      "The flag has been removed from the request.",
      "removeFlag"
    );
  };

  const handleFlagged = (item) => {
    setViewSednerRecipientViewModal(null);
    setViewFlaggedViewModal(item);
  };

  return (
    <div>
      <div
        onClick={hideModal}
        className="cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none scrollbar-hide"
      >
        <div
          id="modal"
          className="w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0"
        >
          <div className="cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]">
              <img src={goodifly} alt="logo" className="w-[107px] h-[33px]" />
              <div className="mt-[27px] flex gap-1">
                <div className="flex flex-col gap-1">
                  <h1 className="font-bold text-[18px] leading-[21.83px]">
                    {viewSednerRecipientViewModal?.item_sender_name}
                  </h1>
                  <p className="text-[16px] leading-[19.41px]">
                    {viewSednerRecipientViewModal?.item_sender_number}
                  </p>
                </div>
                <div className="w-[77px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]">
                  <span>Sender</span>
                </div>
              </div>
              <div className="w-full min-h-[141px] rounded-[20px] flex flex-col justify-between border-solid border-[1px] border-[#C1C1C180] mt-[22px] pt-3">
                <div className="px-4 sm:px-[21px]">
                  <div className="w-full flex items-center justify-between">
                    <div className="flex flex-col gap-[2px]">
                      <p className="text-[14px] text-[#707070] leading-[16.98px]">
                        Item type
                      </p>
                      {viewSednerRecipientViewModal?.is_electronic && (
                        <h1 className="font-bold text-[16px] leading-[19.41px]">
                          Electronics
                        </h1>
                      )}
                      {viewSednerRecipientViewModal?.is_clothing && (
                        <h1 className="font-bold text-[16px] leading-[19.41px]">
                          Clothing
                        </h1>
                      )}
                    </div>
                    <h1 className="font-bold text-[18px] text-[#EB0728] leading-[21.83px]">
                      {viewSednerRecipientViewModal?.item_weight}kg
                    </h1>
                  </div>
                </div>
                <div className="w-full min-h-[79px] rounded-b-[20px] px-4 sm:px-[21px] pt-[11px] bg-[#E5E5E580] flex flex-col gap-1">
                  <p className="text-[14px] text-[#707070] leading-[16.98px]">
                    Item description
                  </p>
                  <h1 className="font-bold text-[16px] leading-[19.41px]">
                    {viewSednerRecipientViewModal?.item_description}
                  </h1>
                </div>
              </div>
              <div className="w-full mt-[22px] px-4 sm:px-[21px] flex gap-[14px]">
                {viewSednerRecipientViewModal?.agent_approval_inbound === 1 && (
                  <button
                    disabled={disabled.received}
                    onClick={() => handleReceived(viewSednerRecipientViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Mark as received
                  </button>
                )}
                {viewSednerRecipientViewModal?.agent_approval_inbound === 2 && (
                  <button
                    disabled={disabled.approve}
                    onClick={() => handleApprove(viewSednerRecipientViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Approve
                  </button>
                )}
                {viewSednerRecipientViewModal?.agent_approval_inbound === 3 && (
                  <button
                    disabled={disabled.complete}
                    onClick={() => handleComplete(viewSednerRecipientViewModal)}
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Complete
                  </button>
                )}
                {viewSednerRecipientViewModal?.agent_approval_inbound < 4 && (
                  <button
                    onClick={() => handleFlagged(viewSednerRecipientViewModal)}
                    type="button"
                    className="w-full h-[40px] rounded-[80px] bg-white border-solid border-[1px] border-[#C1C1C1] text-[16px] text-black leading-[19.41px]"
                  >
                    Flag
                  </button>
                )}
                {viewSednerRecipientViewModal?.agent_approval_inbound >= 5 && (
                  <button
                    disabled={disabled.removeFlag}
                    onClick={() =>
                      handleRemoveFlag(viewSednerRecipientViewModal)
                    }
                    type="button"
                    className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]"
                  >
                    Remove flag
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  );
};

export default SednerRecipientViewModal;