import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FlightTrackFalse } from '../../features/flight/flightTrackSlice'
import TotalSenderPrice from '../totalSenderPrice'
import goodifly from './assets/goodifly.png'
import profile from './assets/profile.jpg'
import planeIcon from './assets/plane.svg'
import globe from './assets/globe.svg'
import fly from './assets/fly.svg'
import planeFly from './assets/planeFly.svg'

import { convertTo12HourFormat, formatDate } from '../ConvertDateAndTime'


const DateFormatConverter = (inputDate) => {
  const dateObject = new Date(inputDate);

  // Define options for toLocaleDateString
  const options = { year: '2-digit', month: 'short', day: '2-digit' };

  // Convert date to required format (e.g., "25 AUG 23")
  const formattedDate = dateObject.toLocaleDateString('en-GB', options).toUpperCase().replace(/ /g, ' ').replace(',', '');
  return formattedDate
}


const DateFormatForUpcoming = (inputDate) => {
  const dateObject = new Date(inputDate);

  // Define options for toLocaleDateString
  const options = { year: "numeric", month: 'short' };

  // Get the day as a number to determine the correct suffix
  const day = dateObject.getDate();

  // Function to determine the correct ordinal suffix for a day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // handles special case for 11th, 12th, 13th, 14th, ...19th
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  // Convert date to required format (e.g., "25th AUG 2023")
  const formattedDate = day + getOrdinalSuffix(day) + ' ' + dateObject.toLocaleDateString('en-GB', options)
    .replace(/ /g, ' ')
    .replace(',', '') + ' '

  return formattedDate;
}




const FlightTrack = ({ flight, setConfirmItemReceived }) => {
  const dispatch = useDispatch()
  // const dynamicWidth = "20%"
  const [dynamicWidth, setDynamicWidth] = useState('0%');

  const [timeRemaining, setTimeRemaining] = useState('');
  const startDate = flight?.departure_date
  const startTime = flight?.departure_time
  const endDate = flight?.arrival_date
  const endTime = flight?.arrival_time


  useEffect(() => {
    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);
    const totalFlightDuration = end - start; // total flight duration in milliseconds

    const intervalId = setInterval(() => {
      const now = new Date();

      if (now < start) {
        setTimeRemaining('Not started');
        setDynamicWidth('0%');
      } else if (now >= start && now <= end) {
        const timeDiff = end - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // total hours remaining
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const seconds = Math.floor((timeDiff / 1000) % 60);
        setTimeRemaining(formatTime(hours, minutes, seconds));

        // Calculate the percentage of the flight completed
        const completed = ((totalFlightDuration - timeDiff) / totalFlightDuration) * 100;
        setDynamicWidth(`${completed}%`);
      } else {
        setTimeRemaining('Ended');
        setDynamicWidth('100%');
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate, startTime, endDate, endTime]); // Dependencies array

  const formatTime = (hours, minutes, seconds) => {
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return `${hours}:${minutes}:${seconds}`;
  };



  if (flight?.status === 'Upcoming') {
    return (
      <div className='pt-[33px]'>
        <div className=' w-full flex flex-col items-center justify-center text-center px-[20px] sm:px-[25px]'>
          <img src={goodifly} alt="" className=' w-[94px] h-[29px] sm:w-[107px] sm:h-[33px]' />
          <h1 className=' mt-6 text-[16px] sm:text-[18px] font-bold sm:leading-[21.83px]'>Getting Ready For Takeoff!</h1>
          <h1 className=' mt-[3px] font-normal text-[#707070] text-[12px] sm:text-[15px] sm:leading-[18.2px]'>Your package sets off on the <span className=' font-bold text-[#EB0728]'>{DateFormatForUpcoming(flight?.departure_date)}</span></h1>
          <img src={globe} alt="" className=' mt-[26px] sm:w-[114px] sm:h-[79px]' />

          <div className=' pt-[3px] w-full flex gap-2 text-center items-center justify-between'>

            <div className='flex flex-1 flex-col'>
              <h1 className=' text-[#AAAAAA] text-[10px] leading-[12.13px]'>{flight?.from_city}</h1>
              <h1 className=' font-bold text-[18px] leading-[21.83px]'>{flight?.from_city_code}</h1>
              <h1 className='  text-[#AAAAAA] text-[10px] leading-[12.13px]'>{formatDate(flight?.departure_date)}</h1>
              <h1 className='text-[#AAAAAA] text-[10px] leading-[12.13px]'>{convertTo12HourFormat(flight?.departure_time)}</h1>
            </div>

            <img src={planeFly} alt="planeFly" className='flex flex-1' />

            <div className='flex flex-1 flex-col'>
              <h1 className=' text-[#AAAAAA] text-[10px] leading-[12.13px]'>{flight?.to_city}</h1>
              <h1 className=' font-bold text-[18px] leading-[21.83px]'>{flight?.to_city_code}</h1>
              <h1 className='  text-[#AAAAAA] text-[10px] leading-[12.13px]'>{formatDate(flight?.arrival_date)}</h1>
              <h1 className='text-[#AAAAAA] text-[10px] leading-[12.13px]'>{convertTo12HourFormat(flight?.arrival_time)}</h1>
            </div>
          </div>
        </div>

        <div className=' gap-3 rounded-b-[30px] mt-[28px] px-[28px] sm:pl-[30px] sm:pr-[25px] bg-[#E5E5E5] py-[24px] flex items-center justify-between'>
          <div className='flex flex-col gap-[3px]'>
            <h1 className=' font-bold text-[12px] sm:text-[16px] sm:leading-[19.41px]'>{flight?.sender_name}</h1>
            <div className='flex gap-[9px] text-[#707070] text-[10px] xm:text-[13px] leading-[15.77px]'>
              {flight.is_electronic === true ? <span>Electronics</span> : null}
              {flight.is_clothing === true ? <span>Clothing</span> : null}
              <h1>{flight?.item_description}</h1>
              <h1>{flight?.item_weight}kg</h1>
            </div>
          </div>
          <h1 className=' font-bold text-[#EB0728] text-[25px] sm:text-[35px]'>${TotalSenderPrice(flight?.item_weight)}</h1>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className='py-[42px]'>
        <div className=' px-[28px] sm:px-[45px]'>
          <div className=' flex items-center justify-between'>
            {/**Profile */}
            <div className='flex items-center gap-[11px]'>
              <img src={profile} alt="" className=' w-[40px] h-[40px] sm:w-[51px] sm:h-[51px] rounded-[40px] sm:rounded-[51px]' />
              <div className='flex flex-col'>
                <h1 className=' font-bold text-[14px] sm:text-[16px] sm:leading-[19.41px]'>{flight?.carrier_name}</h1>
                <p className=' font-normal text-[12px] sm:text-[14px] text-[#AAAAAA] sm:leading-[16.98px]'>Carrier</p>
              </div>
            </div>
            {/**Goodifly logo */}
            <img src={goodifly} alt="" className=' w-[94px] h-[29px] sm:w-[107px] sm:h-[33px]' />
          </div>

          {/**Tracking Period */}
          {timeRemaining === "Ended" ? <h1 className=' mt-[39px] text-[12px] sm:text-[16px] font-normal'>Flight completed <span className=' font-bold text-[#4FBF26]'>successfully</span></h1> : <h1 className=' mt-[6px] text-[12px] sm:text-[16px] font-normal'>Remaining flight hours <span className=' font-bold text-[#EB0728]'>{timeRemaining}</span></h1>}

          {/**Tracking Dates */}
          <div className='flex justify-between mt-[24px] sm:mt-[31px] text-[12px] sm:text-[14px] text-[#707070] font-normal'>
            <h1>{DateFormatConverter(flight?.departure_date)}</h1>
            <h1>{DateFormatConverter(flight?.arrival_date)}</h1>
          </div>

          {/**Tracking bar */}
          <div className=' w-full h-[4px] mt-[5px] flex rounded-[20px] bg-[#E5E5E5]'>
            <div className={`rounded-tl-[20px] rounded-bl-[20px]  bg-[#EB0728]`} style={{ width: dynamicWidth }} />
            <div className=' flex items-center ml-[-1.8px]'>
              <img src={planeIcon} alt="" className=' w-[20.49px] h-[20.49px] sm:w-[26.67px] sm:h-[26.67px]' />
            </div>
          </div>

          {/**Date Info */}
          <div className=' mt-[33px]  w-full flex gap-[10px] items-center justify-between'>
            {/**Departure Info */}
            <div className=' text-[12px] sm:text-[14pxpx] sm:leading-[16.98px] flex flex-col gap-[2px] items-center'>
              <h1 className='  text-[#AAAAAA]'>{flight?.from_city}</h1>
              <h1 className=' font-bold text-[14px] xm:text-[16px] sm:text-[18px] sm:leading-[21.83px]'>{flight?.from_city_code}</h1>
              <h1 className='  text-[#AAAAAA]'>{formatDate(flight?.departure_date)}</h1>
              <h1 className='  text-[#AAAAAA]'>{convertTo12HourFormat(flight?.departure_time)}</h1>
            </div>

            <img src={planeFly} alt="planeFly" className=' w-[100px] sm:w-auto' />

            {/**Arrival Info */}
            <div className=' text-[12px] sm:text-[14pxpx] sm:leading-[16.98px] flex flex-col gap-[2px] items-center'>
              <h1 className=' text-[#AAAAAA]'>{flight?.to_city}</h1>
              <h1 className=' font-bold text-[14px] xm:text-[16px] sm:text-[18px] sm:leading-[21.83px]'>{flight?.to_city_code}</h1>
              <h1 className=' text-[#AAAAAA]'>{formatDate(flight?.arrival_date)}</h1>
              <h1 className=' text-[#AAAAAA]'>{convertTo12HourFormat(flight?.arrival_time)}</h1>
            </div>
          </div>

          {/**Fee and Weight */}
          <div className='flex justify-between text-[14px] sm:text-[16px] sm:leading-[19.41px] mt-[44px]'>
            <div className='flex flex-col gap-[5px]'>
              <h1 className=' text-[#AAAAAA]'>Fee:</h1>
              <h1>${TotalSenderPrice(flight?.item_weight)}</h1>
            </div>
            <div className='flex flex-col gap-[5px]'>
              <h1 className=' text-[#AAAAAA]'>Weight:</h1>
              <h1>{flight?.item_weight}kg</h1>
            </div>
          </div>
        </div>

        {/**Line */}
        <div className=' mt-[29px] w-full border-solid border-[1px] border-[#C1C1C180]' />

        <div className=' mt-[18px] px-[28px] sm:px-[45px]'>
          {/**Item Description */}
          <div className='flex flex-col gap-[5px]'>
            <h1 className=' text-[12px] sm:text-[14px] text-[#AAAAAA] sm:leading-[16.98px]'>Item description</h1>
            <h1 className=' font-bold text-[14px] sm:text-[16px] sm:leading-[19.41px]'>{flight?.item_description}</h1>
          </div>
          <button onClick={() => { dispatch(FlightTrackFalse()); setConfirmItemReceived(flight); }} disabled={timeRemaining !== "Ended" ? true : false} className={` mt-[31px] w-full h-[40px]  sm:h-[45px] rounded-[30px] text-white text-[12px] sm:text-[16px] sm:leading-[19.41px] text-center ${timeRemaining !== "Ended" ? " cursor-not-allowed disabled:bg-[#F4C5CC] bg-[#F4C5CC]" : "bg-[#EB0728]"}`}>Confirm Item received</button>
        </div>
      </div>
    )
  }
}

export default FlightTrack
