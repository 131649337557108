import React, { useEffect, useState } from 'react'
import NavBar from '../../components/Nav/NavBar'
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import { useSelector } from 'react-redux'
import faqview from './assets/faq_view.svg'
import faqclose from './assets/faq_close.svg'
import Footer from '../../components/Footer/Footer'
import CustomerNav from '../../components/Nav/CustomerNav'


const formatAnswer = (answer) => {
  if (typeof answer === 'string') {
    return <p>{answer}</p>;
  }

  if (Array.isArray(answer)) {
    return (
      <>
        {answer.map((part, index) => {
          switch (part.type) {
            case 'bold':
              return <span key={index} className="font-bold">{part.content}</span>;
            case 'heading':
              return <span key={index} className="font-bold ">{part.content}</span>;
            case 'list':
              return (
                <ul key={index} className= "ml-10 list-disc ">
                  {part.content.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              );
            case 'innerlist':
              return (
                <ul key={index} className= "ml-20 list-disc ">
                  {part.content.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
            );
            case 'newline':
              return <p key={index} className="my-4"></p>;

            case 'link':
              return <a href="mailto:contactus@goodifly.com" className=" underline text-[#EB0728] lg:w-[779px] text-[16px] sm:text-[18px] font-normal lg:leading-[21.83px] leading-[19.41px]">
                <span key={index} >{part.content}</span>
              </a> ;    
          
            case 'indent':
              return <p key={index} className="ml-5"></p>;
            case 'text':
            default:
              return <span key={index}> {part.content}</span>;
          }
        })}
      </>
    );
  }

  return null;
};


// Individual FAQ Item component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false); // State to track if answer is visible

  const toggleAnswer = () => {
    setIsOpen(!isOpen); // Toggle the state when button is clicked
  };

  return (

    <div
      onClick={toggleAnswer} 
      className={`border lg:w-[560px] flex flex-col items-start justify-center rounded-[10px] mx-[29px] md:mx-[122px] lg:mx-0 lg:pl-[23px] pl-[20px] mb-[25px] cursor-pointer overflow-hidden transition-all duration-200 ease-out  ${
        isOpen ? 'py-[15px] lg:py-[19px] md:py-[19px]' : 'py-[15px] md:py-[19px] lg:py-[19px] h-auto' 
      }`}
      style={{ transitionProperty: 'border-color, border-width' }} >
      {/* Question and icon */}
      <div className="flex items-center justify-bectween w-full">
        <h1 className="lg:text-[18px] text-[16px] font-bold md:text-[18px] flex-grow lg:leading-[21.83px] md:leading-[21.83px] leading-[19.41px] ">
          {question}
        </h1>
        <img
          src={isOpen ? faqclose : faqview} alt={isOpen ? 'open' : 'close'} className=" pr-[18px]  "/>
      </div>

      {/* Conditionally render the answer */}
      <div
        className={`flex justify-between items-center ${
          isOpen ? 'h-auto opacity-100' : 'max-h-0 opacity-0'
        }`}>
        <span className="lg:mt-[34px] mt-[20px] lg:text-[18px] text-[16px] font-normal sm:text-[18px] flex-grow lg:mr-[60px]  mr-[30px] lg:leading-[21.83px] leading-[19.41px] "> {formatAnswer(answer)}</span>
     </div>
    </div>
    
  );
};


const Faq = () => {
  const [validUser, setValidUser] = useState(false)
  const [userID, setUserID] = useState()
  const [username, setUsername] = useState("")
  const [access_token, setAccessToken] = useState()
  const [refresh_token, setRefreshToken] = useState()
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [swithStatus, setSwitchStatus] = useState(false)
  const [senderSwitch, setSenderSwitch] = useState(0)
  const logInModal = useSelector((state) => state.logInModal.value)
  const signUpModal = useSelector((state) => state.signUpModal.value)







  const faqSender = [
    
    { question: 'What is Goodifly?', answer: 'Goodifly is a peer-to-peer delivery platform that makes international shipping affordable and convenient. It connects travelers with unused luggage space to people who need to ship items abroad. It\'s a win-win: travelers earn extra cash by carrying your package, while you save money compared to traditional shipping services.'},
    {
      question: 'In what locations is Goodifly operative in?',
      answer: [
        { type: 'text', content: 'Goodifly is currently serving routes between ' },
        { type: 'bold', content: 'Accra, Ghana' },
        { type: 'text', content: ' and ' },
        { type: 'bold', content: 'New York, USA' },
        { type: 'text', content: " only, and we're expanding our network to serve even more locations soon." }
      ]
    },
    {
      question: 'What delivery options does Goodifly offer?',
      answer: [
        { type: 'text', content: 'Goodifly offers two main delivery options:' },
        { type: 'newline' },
        { type: 'heading', content: 'Send & Receive a shipment:' },
        { type: 'text', content: 'This allows you to send your own items or receive items from others anywhere within our operational zones (currently Accra, Ghana and New York, USA). To send and receive your own items:' },
        { type: 'newline' },
        { type: 'list', content: [
          'Find a flight with a traveler willing to carry your item.',
          'Book the flight by providing details about your item and making payment.',
          'Goodifly\'s local agents will then:']},
          {type:'innerlist', content:[
          'Inspect your item to ensure compliance with customs regulations.',
          'Arrange for pickup and delivery to the carrier at the origin city.',
          'Upon arrival in the destination city, the local agent will receive the item from the carrier and arrange delivery to the recipient.'
        ]},
        { type: 'newline' },
        { type: 'heading', content: 'Shop on Marketplace:' },
        { type: 'text', content: 'This lets you shop for items from select online marketplaces and have them delivered internationally to your doorstep. Goodifly handles the purchase, international shipping, and local delivery through our network. To request marketplace deliveries:' },
        { type: 'newline' },
        { type: 'list', content: [
          'Find a flight that qualifies for marketplace deliveries.',
          'Share links of your favorite items from select marketplaces (currently Amazon US) and make payment to book the flight.',
          'Goodifly will facilitate the purchase from the marketplace, handle international shipping, and arrange local delivery through our agents.'
        ]}
      ]
    },
    // { question: 'How does Goodifly work? ', answer: 'Goodifly offers two main delivery options: Send & Receive a shipment: This allows you to send your own items or receive items from others anywhere within our operational zones (currently Accra, Ghana and New York, USA). To send and receive your own items: Find a flight with a traveler willing to carry your item. Book flight by providing details about your item and making payment Goodifly\'s local agents will then:Inspect your item to ensure compliance with customs regulations. Arrange for pickup and delivery to the carrier at the origin city. Upon arrival in the destination city, the local agent will receive the item from the carrier and arrange delivery to the recipient. Shop on Marketplace: This lets you shop for items from select online marketplaces and have them delivered internationally to your doorstep. Goodifly handles the purchase, international shipping, and local delivery through our network. To request for marketplace deliveries: Find a flight that qualifies for marketplace deliveries. Share links of your favorite items from select marketplaces (currently Amazon US) and make payment to book flight Goodifly will facilitate the purchase from the marketplace, handle international shipping, and arrange local delivery through our agents.' },
    { question:' What kind of items can I send?',answer:'Currently, Goodifly only allows for clothing items. We\'re working on expanding the range of acceptable items in the near future. Items need to fit in a suitcase or regular traveling bag'},
    { question:'Where can I shop from using marketplace deliveries?',answer:'Currently, you can use Goodifly to request items from Amazon in the US to be delivered to Accra, Ghana. We\'ll be adding more marketplaces and delivery destinations soon!'},
    { question:'How are fares calculated?',answer:'Fares are calculated based on the weight of your item and current market rates for excess baggage. This ensures you get a competitive price.'},
    { question:'How do I book a shipment on Goodifly? ',
      answer:[{type: 'text', content:'Ready to ship with Goodifly? Here\'s how to book your flight in a few easy steps: '},  { type: 'newline' }, {type:'list', content:[' Find a carrier: Search for flights traveling from your origin city to your destination.',
         'Choose a carrier with space: As you browse flights, you\'ll see the minimum and maximum weight each carrier can carry.', 
         'Select a flight with a carrier who has enough space for your item.',
         'Book and provide details: During booking, enter the weight, description, and recipient information.' ,
         'Finalize your shipment: complete your payment to confirm your booking.']}, { type: 'newline' }, { type:'text', content:'Here\'s a tip: Always double-check weight limits to ensure a smooth booking process. If your item falls below the minimum, you can still book the flight, but you\'ll be charged the fee for the minimum weight.'}]},
    { question:'What happens if there\'s a problem with my delivery, such as a lost package or damaged item?',
      answer:[{type:'text', content:'We understand that unforeseen situations can occur, and we\'re here to help. In the unlikely event of a lost package, damaged item, or other delivery issue, please report the situation to '}, {type:'link', content:' contactus@goodifly.com'},{type:'text', content: ' immediately. We\'ll work diligently to resolve the issue and ensure your satisfaction. '}]}
  ];

  const faqCarrier = [
    { question: 'How do I list my flight with Goodifly?', answer: [{type:'text', content:'Listing your flight with Goodifly is easy! Just follow these steps:'}, { type: 'newline' }, {type:'list', content:[
      'Sign up on Goodifly and click on “Become a Carrier” on your dashboard',
      'You’ll be directed to the Carrier dashboard. Click “List flight” and provide your flight details.',
      'We\'ll verify your information and then list your flight for users looking for carriers.' ]}]},
    { question: 'How much can I earn on Goodifly?', answer: 'The amount you can earn on Goodifly depends on the weight of the item and current demand. Carriers typically earn between $15-$20 per kilogram delivered. You can also earn more by carrying heavier items. ' },
    { question: ' How do I guarantee that Goodifly packages I send are safe?', answer: 'Goodifly prioritizes the safety and security of your packages. All items are thoroughly inspected by our local agents before being handed over to travelers.' },
    { question:'What happens if my flight gets changed or cancelled?',answer:[{type:'text', content:'If you encounter any problems with your flight, please contact us at ' }, {type:'link', content:'contactus@goodifly.com' }, {type:'text', content:'immediately. '}]},
    { question:'How can I receive payments?', answer:[{type:'text', content:'You can receive payments through your linked mobile money wallet or bank account. To add a withdrawal method, follow these steps: '},   { type: 'newline' }, { type:'list', content:['Go to your carrier dashboard.', 'Click the profile navigation and select "Earnings".' , 'Select "Manage Withdrawal method" on your earnings page to add your mobile money wallet or bank account.', 'Save your changes. Once you\'ve added a withdrawal method, you can request a payout directly from your dashboard.']}]},
    // {question:'',answer:''}
  ];


   // Decode the data after retrieving it
   function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  useEffect(()=>{
    scrollToTop()
    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      setValidUser(true)
      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setUsername(parsedData?.first_name)
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      setUserID(parsedData?.id)
    }
  }, [])
  return (
    <div className='flex flex-col h-screen'>
      {validUser ? (
        <CustomerNav
          component="faq"
          userID={userID}
          refresh_token={refresh_token}
          access_token={access_token}
          swithStatus={swithStatus}
          setSwitchStatus={setSwitchStatus}
          isOpenDropDown={isOpenDropDown}
          setIsOpenDropDown={setIsOpenDropDown}
        />
      ) : (
        <NavBar />
      )}

      {logInModal ? <LogInModal /> : null}
      {signUpModal ? <SignUpModal /> : null}
       

  {/* FAQ section */}
      
  <div >
      <p className=" flex  items-center justify-center text-center lg:pt-[176px]  px-[93px] md:w-full text-[25px]  pt-[130px]  lg:mb-[69px] mb-[35px] lg:text-[35px] xl:text-[50px] font-bold lg:leading-[42.46px] leading-[30.33px]" >
            Frequently Asked Questions
      </p>

      {/* Sender Carrier Switch */}
      <div className=' flex lg:hidden whitespace-nowrap w-full justify-center  items-start mb-[35px]'>
        <div className={` ${senderSwitch !== 1 ? "pr-[36px]" : "pl-[25px]"} w-fit flex gap-[25px] md:gap-[24px] items-center rounded-[50px] md:rounded-[80px] border-solid border-[1px] border-[#C1C1C150] shadow-searchBar py-[5px] px-[6px] text-[12px] leading-[14.56px] `}>
          <div onClick={() => setSenderSwitch(0)} className={` ${senderSwitch === 0 ? "bg-[#EB0728] text-white px-4 md:pb-[11px] md:pt-[14px] md:pl-[25px] md:pr-[27px]  py-[8px]  w-full  md:h-[40px] h-[30px] rounded-[30px] flex items-center justify-center  text-center" : " "} font-bold sm:font-bold cursor-pointer`}>For Senders

          </div>
          <div onClick={() => setSenderSwitch(1)} className={` ${senderSwitch === 1 ? "bg-[#EB0728] text-white px-4 md:pb-[11px] md:pt-[14px] md:pl-[25px] md:pr-[27px]  py-[8px] w-full  md:h-[40px] h-[30px] rounded-[30px] flex items-center justify-center text-center" : " "} font-bold sm:font-bold cursor-pointer`}>For Carriers 

          </div>
        </div>
  </div>
         

      {/**Large Devices */}
  <div className=" hidden   lg:flex lg:flex-nowrap  lg:items-baseline lg:justify-evenly lg:px-[0]">
    <div className="flex text-left ">
          <div className="  flex flex-col ">
                <h1 className="mb-[15px]  font-bold lg:text-[18px] text-[16px] leading-[21.83px] ">
                     For Senders
                </h1>
                <span>{faqSender.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                     ))}</span>

          </div>
    </div>

        <div className="flex text-left">
          <div className="  flex flex-col">
              <h1 className="h-[22px] mb-[15px]  font-bold text-[18px] leading-[21.83px]">
                     For Carriers
              </h1>
              <span>{faqCarrier.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                     ))}</span>
          </div>
        </div>
  </div>
 
         {/**Small and Medium Devices */}
    <div className=" lg:hidden w-full flex flex-wrap md:flex-nowrap items-baseline justify-evenly">
      {senderSwitch=== 0?
      <div className="flex text-left ">
        <div className="  flex flex-col">
          {faqSender.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}

        </div>
      </div>: 
          
      <div className="flex text-left">
        <div className="  flex flex-col">
            {faqCarrier.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
        </div>
      </div>
    }
  </div>
      </div>

        {/* FAQ Section End*/}

        <div className="flex flex-col w-full items-center justify-center text-center gap-[19px]  ">
          <h1 className=" lg:w-[837px]  lg:mt-[102px] lg:h-[42px] lg:mb-[14px] mb-[11px] text-[25px] lg:text-[35px] xl:text-[50px] font-bold lg:leading-[42.46px] leading-[30.33px]">
          Still have a question?
          </h1>
          <h1 className=" lg:w-[652px] lg:mb-[14px] text-[16px] sm:text-[18px] font-normal lg:leading-[21.83px] leading-[19.41px] mx-[42px]">
          If you cannot find answers to your questions in our FAQs, you can always contact us.
          We will answer to you shortly
          </h1>
          <a href="mailto:contactus@goodifly.com" className=" underline text-[#EB0728] lg:w-[779px] text-[16px] sm:text-[18px] font-normal lg:leading-[21.83px] leading-[19.41px]">
            <p>contactus@goodifly.com</p>
          </a>        
        </div>
<Footer />
</div>
  );}

export default Faq
