import React from 'react'
import fly from './assets/fly.svg'
import { convertTo12HourFormat, formatDate } from '../ConvertDateAndTime'
import TotalSenderPrice from '../totalSenderPrice'

const BookedFlight = ({ airports, flight, viewFlightTrack }) => {
    const departure_airport = airports?.filter((air) => air.id === flight.departure_airport)
    const arrival_airport = airports?.filter((air) => air.id === flight.arrival_airport)
    if (departure_airport && arrival_airport) {
        flight['departure_airportCode'] = departure_airport[0]?.code
        flight['arrival_airportCode'] = arrival_airport[0]?.code
        flight['from_city'] = departure_airport[0]?.city
        flight['from_city_code'] = departure_airport[0]?.city_code
        flight['to_city'] = arrival_airport[0]?.city
        flight['to_city_code'] = arrival_airport[0]?.city_code
        flight['departure_country'] = departure_airport[0]?.country
        flight['arrival_country'] = arrival_airport[0]?.country
    }
    return (
        <div className='  flex flex-col w-full max-w-[350px] rounded-[30px] border-solid border-[1px] border-[#C1C1C180] shadow-adminLogIn bg-[#FFFFFF]'>
            <div className=' p-[22px] flex flex-col w-full items-center'>
                <div className=' w-full flex justify-between'>
                    <div className=' w-full text-left items-start justify-start flex flex-col gap-[1px]'>
                        <h1 className='font-bold text-[14px] leading-[16.98px]'>{flight?.carrier_name}</h1>
                        <h1 className=' flex gap-[9px] text-[10px] leading-[12.13px] text-[#AAAAAA]'>
                            {flight.is_electronic === true ? <span>Electronics</span> : null}
                            {flight.is_clothing === true ? <span>Clothing</span> : null}
                            <span>{flight.item_weight}kg</span>
                            <span>${TotalSenderPrice(flight.item_weight)}</span>
                        </h1>
                    </div>

                    <div className={` ${flight.status === "Active" ? "bg-[#D6FBC9] border-[#4FBF26] text-[#4FBF26]" : "bg-[#FFDFE4] border-[#EB0728] text-[#EB0728]"} min-w-[80px] px-[12px] h-[25px]  rounded-[50px] border-[1px] flex items-center justify-center text-center text-[12px] leading-[14.56px] font-bold`}>{flight.status}</div>
                </div>

                <div className=' mt-4 w-full flex gap-[10px] items-center justify-between'>
                    {/**Departure Info */}
                    <div className=' text-[10px] leading-[12.13px] flex flex-col gap-[2px] items-center'>
                        <h1 className='  text-[#AAAAAA]'>{flight?.from_city}</h1>
                        <h1 className=' font-bold text-[16px] leading-[19.41px]'>{flight?.from_city_code}</h1>
                        <h1 className='  text-[#AAAAAA]'>{formatDate(flight?.departure_date)}</h1>
                        <h1 className='  text-[#AAAAAA]'>{convertTo12HourFormat(flight?.departure_time)}</h1>
                    </div>

                    <img src={fly} alt="fly" className=' w-[100px]' />

                    {/**Arrival Info */}
                    <div className=' text-[10px] leading-[12.13px] flex flex-col gap-[2px] items-center'>
                        <h1 className=' text-[#AAAAAA]'>{flight?.to_city}</h1>
                        <h1 className=' font-bold text-[16px] leading-[19.41px]'>{flight?.to_city_code}</h1>
                        <h1 className=' text-[#AAAAAA]'>{formatDate(flight?.arrival_date)}</h1>
                        <h1 className=' text-[#AAAAAA]'>{convertTo12HourFormat(flight?.arrival_time)}</h1>
                    </div>
                </div>

                {/**View Button */}
                <button onClick={() => viewFlightTrack(flight)} className=' mt-[38px] w-full h-[40px] rounded-[40px] bg-[#EB0728] flex items-center justify-center text-center text-white text-[16px] sm:text-[18px]'>View</button>
            </div>
        </div>
    )
}

export default BookedFlight
