import React, { useState } from 'react';
import baseUrl from '../baseUrl';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { sessionTrue } from '../../features/pages/sessionSlice';


function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns a zero-based index
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const PaymentProcessing = ({ setCancelRequest, setApproveRequest, access_token, setRequestInfo, newRequest, completedRequest }) => {
  const dispatch = useDispatch()
  const [switchNav, setSwitchNav] = useState(0)

  return (
    <div className="bg-white pt-[100px] sm:pt-[163px]">
      <h1 className=" xm:text-[20px] md:text-[25px] lg:text-[35px] xl:text-[50px] xl:leading-[60.65px] font-bold">Admin Payment Processing</h1>

      {/** */}
      <div className='w-full flex flex-col mt-[47px]'>
        {/** switch */}
        <div className=' whitespace-nowrap sm:px-[4.375rem] md:px-[5rem]  flex w-full justify-center  items-start'>
          <div className={` ${switchNav !== 2 ? "sm:pr-[34px]" : ""} ${switchNav !== 0 ? " sm:pl-[32px]" : ""} bg-[#E5E5E5] w-fit h-[45px] sm:h-[65px] rounded-[80px] p-[10px] flex gap-3 sm:gap-[37px] items-center`}>
            <div onClick={() => setSwitchNav(0)} className={` ${switchNav === 0 ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center" : ""} text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}>NEW ( {newRequest?.length} )</div>
            <div onClick={() => setSwitchNav(1)} className={` ${switchNav === 1 ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center" : " sm:ml-[43px]"} text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}>COMPLETED ( {completedRequest?.length} )</div>
            {/* <div onClick={() => setSwitchNav(2)} className={` ${switchNav === 2 ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center" : " sm:ml-[43px]"} text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}>HISTORY ( {history?.length} )</div> */}
          </div>
        </div>


        {/**newRequest */}
        {switchNav === 0 ?
          <div className='w-full flex flex-col'>
            {
              newRequest?.length > 0 ?
                <div className=' w-full h-[514px] mt-4 bg-[#E5E5E5] rounded-[30px] px-[20px] sm:px-[53px] pb-[30px] overflow-x-hidden overflow-y-scroll'>
                  {

                    newRequest?.map((item) => (
                      <div key={item.id} className=" w-full flex flex-col xs:flex-row  justify-between items-center border-solid border-b-[#C1C1C1] border-b-[1px] py-[30px] gap-[25px] xs:gap-0">

                        <div className=' w-full flex flex-col lg:flex-row lg:items-center justify-start lg:text-center gap-[15px] sm:gap-[20px] md:gap-[25px]'>
                          <h1 onClick={() => setRequestInfo(item)} className=" cursor-pointer font-bold text-xs xm:text-[14px] sm:text-[18px] sm:leading-[21.83px]">{item?.first_name} {item?.last_name}</h1>
                          <div className='flex gap-[15px] sm:gap-[20px] md:gap-[25px] text-[#707070] text-[12px] sm:text-[16px] sm:leading-[19.41px]'>
                            <p>{`${formatDate(item?.created_at)}`}</p>
                            <p>{`${item?.type === "ghipss" ? "Bank Transfer" : "Mobile money"}`}</p>
                          </div>
                        </div>

                        <div className="w-full flex xs:flex-col lg:flex-row xs:items-end lg:items-center justify-between xs:justify-end text-center gap-[15px] sm:gap-[21px]">
                          <h1 className="font-bold  text-xs xm:text-[14px] sm:text-[18px] sm:leading-[21.83px]">${item?.withdrawal_amount?.toLocaleString()}</h1>
                          <div className="flex gap-[11px]">
                            <button onClick={() => setApproveRequest(item)} className=" w-[60px] xm:w-[80px] sm:w-[90px] md:w-[122px] h-[25px] xm:h-[35px] md:h-[40px] flex items-center text-center justify-center bg-[#EB0728] text-[#FFFFFF] text-xs xm:text-[14px] sm:text-[16px] md:text-[18px] sm:leading-[21.83px] rounded-[50px]">Approve</button>
                            <button onClick={() => setCancelRequest(item)} className=" w-[60px] xm:w-[80px] sm:w-[90px] md:w-[122px] h-[25px] xm:h-[35px] md:h-[40px] flex items-center text-center justify-center bg-[#FFFFFF] text-[#000000] text-xs xm:text-[14px] sm:text-[16px] md:text-[18px] sm:leading-[21.83px] rounded-[50px]">Cancel</button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div> :
                <div className=' mt-[113px] w-full flex flex-col items-center justify-center text-center'>
                  <h1 className=' font-bold text-[25px] leading-[30.33px]'>No new request</h1>
                  <h1 className=' mt-[16px] text-[18px] text-[#707070] leading-[21.83px]'>The new request is currently empty! All new requests will appear here once they are made</h1>
                </div>
            }
          </div>
          :



          //Completed 
          completedRequest?.length > 0 ?
            <div className=' w-full h-[514px] mt-4 bg-[#E5E5E5] rounded-[30px] px-[20px] md:px-[30px] lg:px-[53px] pb-[30px] overflow-x-hidden overflow-y-scroll'>
              {completedRequest?.map((item) => (

                //Small devices
                <div key={item.id} className=" w-full flex flex-col   border-solid border-b-[#C1C1C1] border-b-[1px] py-[15px] gap-[20px] sm:py-[30px] ">
                  <div className=' w-full flex items-center justify-between sm:justify-center'>
                    <div className=' w-fit flex sm:hidden h-[40px] text-xs xm:text-[14px] md:text-[16px] leading-[19.41px] items-center justify-center text-center sm:px-[20px] lg:px-[30px] sm:bg-[#D9D9D9] rounded-[50px]'>
                      {
                        item?.admin_approval === 3 ?
                          <h1 className='font-bold text-[#EB0728]'>CANCELLED</h1>
                          : item?.admin_approval === 4 ?
                            <h1 className='font-bold text-[#EB0728]'>FAILED</h1>
                            : item?.admin_approval === 5 ?
                              <h1 className='font-bold text-[#EB0728]'>REVERSED</h1>
                              : item?.admin_approval === 6 ?
                                <div className='flex gap-[15px] lg:gap-[20px] '>
                                  <h1 className=' text-[#4FBF26] font-bold'>PAID</h1>
                                  <h1 className=' text-[#707070]'>{formatDate(item?.updated_at)}</h1>
                                </div>
                                :
                                <h1 className='font-bold text-[#70707080] text-opacity-50'>PROCESSING</h1>

                      }
                    </div>

                    <div className=" w-auto flex sm:hidden  items-center justify-between xs:justify-end text-center gap-[15px] sm:gap-[21px]">
                      <h1 className="font-bold  text-xs xm:text-[14px] sm:text-[16px] md:text-[18px] sm:leading-[21.83px]">${item?.withdrawal_amount?.toLocaleString()}</h1>
                    </div>
                  </div>

                  <div className=' w-full flex justify-between items-center gap-[25px] xs:gap-3'>
                    <div className=' w-auto flex flex-col lg:flex-row lg:items-center justify-start lg:text-center gap-[15px] sm:gap-[20px] md:gap-[25px]'>
                      <h1 onClick={() => setRequestInfo(item)} className=" cursor-pointer font-bold text-xs xm:text-[14px] sm:text-[18px] sm:leading-[21.83px]">{item?.first_name} {item?.last_name}</h1>
                      <div className='flex gap-[15px] md:gap-[20px] text-[#707070] text-[12px] sm:text-[14px] md:text-[16px] sm:leading-[19.41px]'>
                        <p>{`${formatDate(item?.created_at)}`}</p>
                        <p>{`${item?.type === "ghipss" ? "Bank Transfer" : "Mobile money"}`}</p>
                      </div>
                    </div>

                    {/**Larger devices */}
                    <div className=' hidden sm:flex w-auto h-[40px]  text-xs xm:text-[14px] md:text-[16px] leading-[19.41px] items-center justify-center text-center px-[20px] lg:px-[30px] bg-[#D9D9D9] rounded-[50px]'>
                      {
                        item?.admin_approval === 3 ?
                          <h1 className='font-bold text-[#EB0728]'>CANCELLED</h1>
                          : item?.admin_approval === 4 ?
                            <h1 className='font-bold text-[#EB0728]'>FAILED</h1>
                            : item?.admin_approval === 5 ?
                              <h1 className='font-bold text-[#EB0728]'>REVERSED</h1>
                              : item?.admin_approval === 6 ?
                                <div className='flex gap-[15px] lg:gap-[20px] '>
                                  <h1 className=' text-[#4FBF26] font-bold'>PAID</h1>
                                  <h1 className=' text-[#707070]'>{formatDate(item?.updated_at)}</h1>
                                </div>
                                :
                                <h1 className='font-bold text-[#70707080] text-opacity-50'>PROCESSING</h1>

                      }
                    </div>

                    <div className=" w-auto  flex  items-center justify-between xs:justify-end text-center gap-[15px] sm:gap-[21px]">
                      <h1 className="font-bold hidden sm:flex text-xs xm:text-[14px] sm:text-[16px] md:text-[18px] sm:leading-[21.83px]">${item?.withdrawal_amount?.toLocaleString()}</h1>
                      <div className="flex gap-[11px]">
                        <button onClick={() => setRequestInfo(item)} className=" w-[60px] xm:w-[80px] sm:w-[90px] md:w-[122px] h-[25px] xm:h-[35px] md:h-[40px] flex items-center text-center justify-center bg-[#EB0728] text-[#FFFFFF] text-xs xm:text-[14px] sm:text-[16px] md:text-[18px] sm:leading-[21.83px] rounded-[50px]">View</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div> :
            <div className=' mt-[113px] w-full flex flex-col items-center justify-center text-center'>
              <h1 className=' font-bold text-[25px] leading-[30.33px]'>No completed request</h1>
              <h1 className=' mt-[16px] text-[18px] text-[#707070] leading-[21.83px]'>The completed request is currently empty! All completed requests will appear here once they are made</h1>
            </div>
        }
      </div>
    </div>
  );
};

export default PaymentProcessing;
