import React from "react";
import SendPackageButton from "./SendPackageButton";
import ReceivePackageButton from "./ReceivePackageButton";
import goodifly from "../assets/goodifly.png";
import MarketPlaceButton from "./MarketPlaceButton";



const PackageModal = ({ flight, setPackageDirection, isMarketPlace, setIsMarketPlace }) => {
  const qualifiesForMarketplace = () => {
    const currentDateTime = new Date();
    const departureDateTime = new Date(
      `${flight?.departure_date}T${flight?.depart_time}`
    );
    const differenceInTime = departureDateTime - currentDateTime;
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    return flight?.from_city !== "Accra" && differenceInDays >= 7;
  };

  return (
    <div className=" min-h-[335px] sm:min-h-[430px] bg-white rounded-[30px] p-[20px] xs:p-[30px] xm:p-[50px] sm:p-[56px]  flex flex-col gap-[23px] sm:gap-[44px] items-center shadow-input">
      <img src={goodifly} alt="Goodifly Logo" className=" h-[33px] w-[107px]" />

      <div className="w-full flex flex-col gap-[20px] sm:gap-[30px]">
        {/* Send Package Component */}
        {/* <SendPackageButton
          flight={flight}
          setPackageDirection={setPackageDirection}
        /> */}

        {/* Receive Package Component */}
        {/* <ReceivePackageButton
          flight={flight}
          setPackageDirection={setPackageDirection}
        /> */}

        {/* Market Place Package Component */}

        {qualifiesForMarketplace() && (
          <MarketPlaceButton
            flight={flight}
            setPackageDirection={setPackageDirection}
          />
        )}
      </div>
    </div>
  );
};

export default PackageModal;