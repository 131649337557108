import React from "react";
import marketButtonImg from "../assets/marketButton.svg";
import arrowIcon from "../assets/arrow_right.svg";
import { useNavigate } from "react-router-dom";

const MarketPlaceButton = ({ flight, setPackageDirection }) => {

  const navigate = useNavigate()
  const handleMarketPlace = () => {
    setPackageDirection("market");
    navigate("/booking", { state: { flight: flight, packageDirection: "market"} });
  }
  return (
    <div
      onClick={() => handleMarketPlace()}
      className=" cursor-pointer w-full flex  justify-between border-solid border-[1px] border-[#C1C1C180] rounded-[20px] items-center  pl-[9px] pr-[14px] sm:pl-[24px] sm:pr-[24px] py-[9px] sm:py-[21px]"
    >
      <div className="w-full flex items-center">
        {/* Receive Package Icon */}
        <img
          src={marketButtonImg}
          alt="Open Box"
          className="h-[60px] w-[65px]"
        />
        {/* Receive Package Content */}
        <div className="flex flex-col gap-[2px]">
          <h2 className="font-bold text-[14px] leading-[16.98px] sm:text-[16px] sm:leading-[19.41px]">Marketplace delivery</h2>
          <p className=" text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]">Request marketplace delivery</p>
        </div>
      </div>
      <img
        src={arrowIcon}
        alt="Arrow Icon"
        className=" w-6 h-6 sm:h-8 sm:w-8"
      />
    </div>
  );
};

export default MarketPlaceButton;
