import React from 'react'
import goodifly from './assets/goodifly.png'
import successIcon from './assets/success.svg'
import { useDispatch } from 'react-redux';
import {SwitchTrue} from '../../features/nav/switchStatusSlice'
import { useNavigate } from 'react-router-dom';

const BookComplete = ({setBookingComplete}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div className=' smallDevice w-full sm:w-[528px] rounded-[30px] shadow-input bg-white pt-[65px] pb-[46px] px-[46px] relative'>
            <div className=' w-full flex flex-col items-center justify-center text-center'>
                <img src={goodifly} alt="" className=' h-[33px] w-[107px]' />
                <img src={successIcon} alt="" className=' w-[35px] h-[35px] mt-[34px]' />
                <h1 className=' mt-[15px] text-[18px] font-bold'>Booking Complete</h1>
                <h1 className=' mt-[12px] text-[16px]'>
                    Your request has been sent to the carrier. Manage
                    and track your booking here
                </h1>

                    <button onClick={()=>{setBookingComplete(false); dispatch(SwitchTrue()); window.localStorage.setItem("Swdiuusidsjdgiusguysgussdigsuyigusdysgudygdfssdfdyust", true); navigate("/dashboard") }} className=' mt-[73px] w-full h-[40px] rounded-[30px] bg-[#EB0728] flex items-center justify-center text-[18px] text-white'>View booking</button>
            </div>
        </div>
    )
}

export default BookComplete
