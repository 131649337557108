import React from "react";
import PriceCalculator from "../../components/priceCalculator";
// import { BookFlightFalse } from "../../features/flight/bookFlightSlice";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convertTo12HourFormat,
  formatDate,
} from "../../components/ConvertDateAndTime";
import fly from "../../components/CustomerDashboard/assets/fly.svg";
import profile from "../../components/CustomerDashboard/assets/profile.jpg";

const BookingInfo = ({ value, maxValue, disabled, flight,luggageSpaceAmount, serviceCharge, totalCharge }) => {
  // const dispatch = useDispatch(); 50 + 24%(155)
  const navigate = useNavigate();

  return (
    <div className=" pt-[20px] pb-[24px] sm:pt-[33px] sm:pb-[38px] w-full xl:max-w-[450px] rounded-[30px] bg-white relative border shadow-adminLogIn">
      {/**Location and Carrier Info */}
      <div className=" px-[20px] sm:px-[36px] mb-[50px] flex flex-col sm:flex-row justify-between sm:items-center text-black">
        <div className=" flex flex-col w-full items-center">
          {/**Profile and Name and Address */}
          <div className=" w-full flex justify-between">
            <div className=" w-full text-left items-start justify-start flex flex-col gap-[1px]">
              <div className="flex gap-[16px] items-center">
                <img
                  src={profile}
                  alt="profile"
                  className=" w-[100px] lg:w-[80px]"
                />
                <div className="flex flex-col gap-[3px]">
                  <h1 className="font-bold text-[18px] leading-[21.83px]">
                    {flight?.username}
                  </h1>
                  <p className=" text-[#707070] leading-[19.41px]">
                    {flight?.carrier_city} , {flight?.carrier_country}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-[40px] w-full flex gap-[10px] items-center justify-between">
            {/**Departure Info */}
            <div className=" text-[14px] leading-[16.98px] flex flex-col gap-[2px] items-center">
              <h1 className="  text-[#AAAAAA]">
                {flight?.from_city}
              </h1>
              <h1 className=" font-bold text-[18px] leading-[21.83px]">
                {flight?.from_city_code}
              </h1>
              <h1 className="  text-[#AAAAAA]">
                {formatDate(flight?.departure_date)}
              </h1>
              <h1 className="  text-[#AAAAAA]">
                {convertTo12HourFormat(flight?.depart_time)}
              </h1>
            </div>

            <img src={fly} alt="fly" className=" w-[100px] sm:w-[180px] xl:w-[100px]" />

            {/**Arrival Info */}
            <div className=" text-[14px] leading-[16.98px] flex flex-col gap-[2px] items-center">
            <h1 className="  text-[#AAAAAA]">
                {flight?.to_city}
              </h1>
              <h1 className=" font-bold text-[18px] leading-[21.83px]">
                {flight.to_city_code}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {formatDate(flight?.arrival_date)}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {convertTo12HourFormat(flight?.arrival_time)}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <hr className="w-full border-solid border-[1px] border-[#C1C1C180] m-0 p-0 my-0 py-0" />

      {/**Prices */}
      <div className="w-full px-[20px] sm:px-[36px] mt-[26px] flex flex-col gap-[34px]">

        <div className="flex flex-col gap-[15px] text-[16px] leading-[19.41px]">
          <div className="flex justify-between text-center items-center ">
            <p className="text-[#AAAAAA]">Luggage space:</p>
            <p>
              {value >= 1 && value <= maxValue
                ? "$" + luggageSpaceAmount
                : "$0"}
            </p>
          </div>
          <div className="flex justify-between text-center items-center ">
            <p className="text-[#AAAAAA]">Goodifly service charge:</p>
            <p>
              {value >= 1 && value <= maxValue
                ? "$" + serviceCharge.toFixed(2)
                : "$0"}
            </p>
          </div>
        </div>


        <div className="flex justify-between items-center font-bold text-center text-[18px] leading-[21.83px]">
          <p>Total after charges:</p>
          <p>
            {value >= 1 && value <= maxValue
              ? "$" + totalCharge.toFixed(2)
              : "$0"}
          </p>
        </div>
      </div>

      {/**Buttons */}
      <div className=" w-full px-[20px] sm:px-[36px] mt-[39px] flex flex-col gap-[14px]">
        <button
          disabled={disabled}
          type="submit"
          className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed text-white bg-[#EB0728] px-[22px] pt-[12px] pb-[14px] rounded-[30px]"
        >
          Book now
        </button>
        <button
          className="underline text-[#707070]"
          onClick={() => navigate("/dashboard")}
        >
          Go Back
        </button>
      </div>
      
    </div>
  );
};

export default BookingInfo;
