import React, { useEffect, useState } from 'react'
import goodifly from './assets/goodifly.png'
import { useNavigate } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchTrue, SwitchFalse } from '../../features/nav/switchStatusSlice'
import { useLocation } from 'react-router-dom';

const CustomerNav = ({ component, access_token, isOpenDropDown, setIsOpenDropDown , increaseHeight
  }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showBackground, setShowBackground] = useState(false);
  // const [increaseHeight, setIncreaseHeight] = useState(false)

  const switchStatus = useSelector((state) => state.switchStatus.value)

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Determine the scroll position
  //     const scrollPosition = window.scrollY;
  //     const screenWidth = window.innerWidth

  //     if (scrollPosition > 150 && screenWidth >= 840) {
  //       setShowBackground(true)
  //     }
  //     else if (scrollPosition > 130 && screenWidth >= 750 && screenWidth < 840) {
  //       setShowBackground(true)
  //     }
  //     else if (scrollPosition > 130 && screenWidth >= 640 && screenWidth < 750) {
  //       setShowBackground(true)
  //     }
  //     else {
  //       setShowBackground(false);
  //     }

  //     // On a mobile the padding bottom need to be increased
  //    if (scrollPosition > 90 && screenWidth >= 200 && screenWidth < 640) {
  //     setIncreaseHeight(true)
  //     }
  //     else{
  //       setIncreaseHeight(false)
  //     }
  //   };

  //   // Add the scroll event listener
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const someStatus = window.localStorage.getItem("Swdiuusidsjdgiusguysgussdigsuyigusdysgudygdfssdfdyust")
    if (someStatus === "true") {
      dispatch(SwitchTrue())
    }
    else {
      dispatch(SwitchFalse())
    }
  }, [])

  return (
    <>
      <div className={`bg-white pb-[46px] z-50   fixed flex flex-col w-full  pt-[33px] sm:pt-[2rem] px-[29px] sm:px-[4.375rem] md:px-[5rem] ${increaseHeight?"h-[311px] lg:h-[170px]":""}`}>

      {/* <div className={` bg-white  z-[60]   fixed flex flex-col w-full  pt-[31px] sm:pt-[2rem] px-[2rem] lg:px-[5rem]`}> */}
        <div className='flex w-full gap-5 justify-between'>
          <img src={goodifly} alt="" onClick={component === "booking" ? () => { dispatch(SwitchFalse()); window.localStorage.setItem("Swdiuusidsjdgiusguysgussdigsuyigusdysgudygdfssdfdyust", false) } : () => { navigate('/dashboard'); dispatch(SwitchFalse()); window.localStorage.setItem("Swdiuusidsjdgiusguysgussdigsuyigusdysgudygdfssdfdyust", false) }} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[190px] sm:h-[58.29px]" />
          <div className='flex gap-2 lg:gap-5 justify-center items-center'>
            <button onClick={() => navigate('/dashboard/carrier')} className=' hidden sm:flex text-[#ffffff] text-center items-center justify-center text-[16px] lg:text-[1.125rem]  bg-[#EB0728] w-[10rem] lg:w-[11.25rem] h-[2.5rem] lg:h-[2.813rem] rounded-[40px] flex-shrink-0'>Become a Carrier</button>
            {/**large devices */}
            <div className=' relative z-90 flex gap-5 justify-center items-center '>
              <button onClick={() => { navigate('/dashboard'); dispatch(SwitchTrue()); window.localStorage.setItem("Swdiuusidsjdgiusguysgussdigsuyigusdysgudygdfssdfdyust", true) }} className=' hidden xm:flex text-[1.125rem] font-bold '>Bookings</button>
              <ProfileDropdown type="customer" access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} className="absolute z-70 " />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerNav
