import React from 'react'
import goodifly from './assets/goodifly.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ListFlightTrue } from '../../features/flight/listFlightSlice';
import ProfileDropdown from './ProfileDropdown';
import {SwitchFalse} from '../../features/nav/switchStatusSlice'


const CarrierNav = ({access_token, isOpenDropDown, setIsOpenDropDown}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()



  return (
    <div className={`bg-white z-50 pb-[37px]  fixed flex flex-col w-full  pt-[33px] sm:pt-[2rem] px-[2rem] md:px-[5rem] `}>
      <div className='flex w-full justify-between'>
      <img src={goodifly} alt="" onClick={() => navigate("/dashboard/carrier")} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[190px] sm:h-[58.29px]" />
        <div className='flex gap-5 justify-center items-center'>
          <button onClick={()=>{dispatch(SwitchFalse()); window.localStorage.setItem("Swdiuusidsjdgiusguysgussdigsuyigusdysgudygdfssdfdyust", false); navigate("/dashboard")}}  className=' hidden md:flex text-[#ffffff] text-center items-center justify-center text-[1.125rem]  bg-[#EB0728] w-[11.25rem] h-[2.813rem] rounded-[40px] flex-shrink-0'>Send a package</button>
          {/**large devices */}
          <div className=' relative z-[90] flex gap-5 justify-center items-center '>
            <button onClick={() => dispatch(ListFlightTrue())}  className=' hidden xm:flex text-[1.125rem] font-bold '>List Flight</button>
            <ProfileDropdown type="carrier" access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown}/>
          </div>
        </div>
      </div>
    </div>

  )
}

export default CarrierNav
