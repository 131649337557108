import React from 'react'
import CountrySelector from './CountrySelector'

const Finish = ({ isSubmitting,
    address, setAddress,
    address2, setAddress2,
    digitalAddress, setDigitalAddress,
    setCountry,
    city, setCity,
    zipCode, setZipCode,
    finishError,
}) => {

    return (
        <div className=' box-border flex flex-wrap items-center '>
            {/** address */}
            <div className=' box-border m-0 basis-full flex-grow-0 max-w-full'>
                <div className=' box-border flex flex-wrap w-full'>

                    <input value={address} onChange={(e) => setAddress(e.target.value)} required type="text" name="address" id="address" data-cy="address" placeholder="Address" className='outline-none placeholder-[#AAAAAA] m-0 text-[16px] leading-[19.41px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-[20px] ' />
                </div>
            </div>



            {/** optional address */}
            <div className=' mt-[14px]  box-border m-0 basis-full flex-grow-0 max-w-full'>
                <div className=' box-border flex flex-wrap w-full'>
                    <div className='w-full flex items-start'>
                        <input value={address2} onChange={(e) => setAddress2(e.target.value)} type="text" name="address2" id="address2" data-cy="address2" placeholder="Address 2 (Optional)" className=' outline-none placeholder-[#AAAAAA] m-0 text-[16px] leading-[19.41px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-[20px] ' />
                    </div>
                </div>
            </div>

            {/** digital address and country */}
             <div className=' mt-[14px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                    <div className='  flex flex-row gap-[17px] w-full'>
                        <input value={digitalAddress} onChange={(e) => setDigitalAddress(e.target.value)} type="text" name="digital address" id="digital_address" data-cy="digital address" placeholder="Digital Address" className='outline-none  w-full  placeholder-[#AAAAAA] m-0 text-[16px] leading-[19.41px] text-[#707070] font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-[20px] ' />
                       
                    
                    <div className='flex flex-col gap-[5px] w-full'> 
                            <div className={` ${finishError === "Country is requied" ? "border-[1px] border-[#EB0728] w-full rounded-[30px]" : " w-full"}`}>
                                <CountrySelector setUserCountry={setCountry} component="signup" />

                            </div>
                            {finishError === "Country is requied" ? <h1 className=' pl-[20px] w-full text-[#EB0728] text-[12px] leading-[14.56px] text-left'>{finishError}</h1> : null}
                    </div>
                  
                 
                    
                </div>
            </div> 
            {/** city and zip code */}
            <div className=' mt-[14px] box-border m-0 basis-full flex-grow-0 max-w-full'>
                <div className=' box-border flex flex-wrap w-full'>
                    <div className=' flex-row w-full flex items-start gap-[17px]'>
                        <input value={city} onChange={(e) => setCity(e.target.value)} required type="text" name="city" id="city" data-cy="city" placeholder="City" className='outline-none placeholder-[#AAAAAA] m-0 text-[16px] leading-[19.41px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-[20px] ' />
                        <input value={zipCode} onChange={(e) => setZipCode(e.target.value)} type="number" name="zip code" id="zip_code" data-cy="zip code" placeholder="Zip Code" className='outline-none appearance-none placeholder-[#AAAAAA] m-0 text-[16px] leading-[19.41px] text-[#707070] w-full font-normal bg-[#E5E5E580] h-[40px] rounded-[30px] pl-[20px] ' />
                    </div>
                </div>
            </div> 
            {/** finish button */}
            <button id='finish'   disabled={isSubmitting} type="submit" className={`mt-[34px] bg-[#EB0728] h-[40px] relative border-none rounded-[30px] select-none text-white text-[16px] leading-[1.41px] w-full ${isSubmitting ? "bg-[#F4C5CC] cursor-not-allowed" : ""} `}>Finish</button>

        </div>
    )
}

export default Finish
