import React from 'react'
import goodifly from './assets/goodifly.png'
import AdminProfileDropdown from './AdminProfileDropdown'

const AminNav = ({access_token, isOpenDropDown, setIsOpenDropDown, setDropDownSwitch}) => {
  return (
    <div className={`bg-white z-50   fixed flex flex-col w-full  pt-[1rem] sm:pt-[49px] px-[1rem] xm:px-[2rem]  `}>
      <div className='flex w-full justify-between'>
      <img src={goodifly} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[190px] sm:h-[58.29px]" />
        <div className='flex gap-5 justify-center items-center'>
          {/**large devices */}
          <div className=' relative z-[90] flex gap-5 justify-center items-center '>
            <AdminProfileDropdown access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} setDropDownSwitch={setDropDownSwitch}/>
         </div> 
        </div>
      </div>
    </div>
  )
}

export default AminNav
