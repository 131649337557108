import React, { useEffect, useState } from 'react'
import PriceCalculator from '../../components/priceCalculator';
import baseUrl from '../../components/baseUrl';
import HomeNav from '../../components/Nav/HomeNav'
import Selector from '../../components/Selector/Selector';
import search from './assets/search.svg'
import Flight from '../../components/Flight/Flight';
import alex from '../../components/Flight/assets/alex.jpg'
import Footer from '../../components/Footer/Footer';
import Amazon from '../../components/Advertisements/AmazonAd';
import { useSelector } from 'react-redux';
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import axios from 'axios';
import BookFlightModal from '../../components/Flight/BookFlightModal';
import no_flight from './assets/no_flight.png'
import NoFlight from './NoFlight';
import placesImage from './assets/places.png'
import small_screen_placesImage from './assets/sm_places.png'




const Home = ({ airports }) => {
  const logInModal = useSelector((state) => state.logInModal.value)
  const signUpModal = useSelector((state) => state.signUpModal.value)
  const bookFlightModal = useSelector((state) => state.bookFlight.value)
  const selectedFlight = useSelector((state) => state.bookFlight.selectedFlight)

  //Fixing the search bar
  const [fixSearch, setFixSearch] = useState(false)

  const [fromSelectedAirport, setFromSelectedAirport] = useState("")
  const [toSelectedAirport, setToSelectedAirport] = useState("")
  const [fligtData, setFlightData] = useState()
  const [allFlight, setAllFlight] = useState()
  const [justify, setJustify] = useState(false)
  
    //Is marketplace
    const [isMarketPlace, setIsMarketPlace] = useState(false)


  // Function to handle scroll event
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const screenwidth = window.innerWidth

    // Check if the first div is scrolled up 
    if (scrollPosition > 620 && screenwidth >= 1100) {
      setFixSearch(true)
    }
    else if (scrollPosition > 580 && screenwidth >= 980 && screenwidth < 1100) {
      setFixSearch(true)
    }
    else if (scrollPosition > 500 && screenwidth >= 840 && screenwidth < 980) {
      setFixSearch(true)
    }
    else if (scrollPosition > 450 && screenwidth >= 750 && screenwidth < 840) {
      setFixSearch(true)
    }
    else if (scrollPosition > 400 && screenwidth >= 640 && screenwidth < 750) {
      setFixSearch(true)
    }
    else if (scrollPosition > 500 && screenwidth >= 200 && screenwidth < 640) {
      setFixSearch(true)
    }
    else {
      setFixSearch(false)
    }

    // if ((screenwidth < 365 && screenwidth >= 259) && scrollPosition > 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "-140px"
    //   }
    // }
    // else if ((screenwidth < 259 && screenwidth >= 227) && scrollPosition > 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "-150px"
    //   }
    // }
    // else if (screenwidth < 227 && scrollPosition > 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "-190px"
    //   }
    // }
    // else if (screenwidth < 365 && scrollPosition <= 150) {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = "41px"
    //   }
    // }
    // else {
    //   let searchDiv = document.getElementById("search")
    //   if (searchDiv) {
    //     searchDiv.style.marginTop = ""
    //   }
    // }

  };

  const quickSearch = () => {
    setJustify(true);
    const filteredFlight = allFlight?.filter(
      (flight) => flight.departure_airport.toLowerCase().includes(fromSelectedAirport?.toLowerCase()) && flight.arrival_airport.toLowerCase().includes(toSelectedAirport?.toLowerCase())
    );
    // Update the state based on the filtered data
    setFlightData(filteredFlight);
  };


  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/flight/`).catch((error) => {
        console.log(error)
      })

      if (response.data) {
        const tempData = [];
        await Promise.all(
          response.data.filter(item => item.booking_status === false).map(async (f) => {
            const departureResponse = await axios.get(`${baseUrl}/airport/${f.departure_airport}/`);
            const departureData = departureResponse.data;

            const arrivalResponse = await axios.get(`${baseUrl}/airport/${f.arrival_airport}/`);
            const arrivalData = arrivalResponse.data;

            // const userResponse = await axios.get(`${baseUrl}/users/${f.carrier}/`);
            // const userData = userResponse.data;

            const temp = {
              user_img: alex,
              airport_img: decodeURIComponent(arrivalData.image).slice(8),
              from_city_code: departureData.city_code,
              from_city: departureData.city,
              from_country: departureData.country,
              departure_airport: departureData.name,
              departure_code: departureData.code,
              to_city_code: arrivalData.city_code,
              to_city: arrivalData.city,
              to_country: arrivalData.country,
              arrival_airport: arrivalData.name,
              arrival_code: arrivalData.code,
              departure_date: f.departure_date,
              arrival_date: f.arrival_date,
              depart_time: f.departure_time,
              arrival_time: f.arrival_time,
              minimum_space:f.minimum_space, 
              maximum_space:f.maximum_space,
              price: PriceCalculator(f.maximum_space).senderCost,
              username: f.carrier_name,
              booking_status: f.booking_status,
            };

            tempData.push(temp);
          })
        );
        setAllFlight(tempData)
        setFlightData(tempData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if ((fromSelectedAirport.length === 0 && toSelectedAirport.length > 0) || (fromSelectedAirport.length > 0 && toSelectedAirport.length === 0)) {
      quickSearch()
    }
    if (fromSelectedAirport.length === 0 && toSelectedAirport.length === 0) {
      setFlightData(allFlight)
    }
  }, [fromSelectedAirport, toSelectedAirport])


  useEffect(() => {
    scrollToTop()
    fetchData();
    // const api = "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json";

    // axios.get(api)
    //   .then((response) => {
    //     setAirpottCities(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust 768px for your breakpoint

  useEffect(() => {
    // Function to handle window resize events
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust 768px for your breakpoint
    };

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [islg, setIslg] = useState(window.innerWidth >1027); // Adjust 768px for your breakpoint

  useEffect(() => {
    // Function to handle window resize events
    const handleResize = () => {
      setIslg(window.innerWidth >1023); // Adjust 768px for your breakpoint
    };

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


 {/**Auto Refresh */}
  // useEffect(() => {
  //   const handleMouseOver = () => {
  //     fetchData();
  //   };

  //   document.addEventListener('mouseover', handleMouseOver);

  //   return () => {
  //     document.removeEventListener('mouseover', handleMouseOver);
  //   };
  // }, []);

  if (fligtData > 0 || allFlight) {
    return (
      <div className=' min-h-screen w-full  '>
        <div>
        <HomeNav area="Home" />
        {logInModal ?
          <LogInModal /> : null}
        {signUpModal ? <SignUpModal /> : null}
        {bookFlightModal ? <BookFlightModal flight={selectedFlight} /> : null}</div>
        <div className='lg:px-[102px] md:px-[51px] px-[29px] sm:px-[20px]'>
        <div className='   z-40  w-full pt-[115px]  sm:pt-[167px]  sm:px-[4.375rem] md:px-[5rem]  '>

          {/**Places Pictures */}
          <div className=' w-full flex items-center justify-center'>
            <img src={placesImage} alt="places" className=' hidden sm:flex' />
            <img src={small_screen_placesImage} alt="small_screen_placesImage" className=' flex sm:hidden' />
          </div>

          <div className=' w-full flex flex-col gap-3 items-center justify-center text-center'>
            <h1 className=' text-[30px] sm:text-[30px] md:text-[3.125rem] font-bold leading-[36.39px] lg:mb-[12px]'>Turn flights into deliveries</h1>
            <h1 className='text-[16px] font-normal leading-[19.41px] sm:text-[1.125rem]'>Taking logistics to another altitude</h1>
          </div>

          {/**Search bar moble*/}
          <div className='lg:hidden w-full flex flex-col  justify-center mt-[31px]'>
            <div id='search' className={` ${fixSearch ? "top-[115px] fixed z-50 left-0 px-[29px]" : ""} sm:pl-[10px] sm:py-[8px] sm:pr-[8px] w-full  sm:w-auto sm:h-[50px] flex flex-col sm:flex-row gap-[9px] sm:gap-0  rounded-[40px] sm:border-solid sm:border-[1px] sm:border-[#C1C1C180] sm:shadow-searchBar`}>
             
                <Selector options={airports} setCity={setFromSelectedAirport} placeholder="From:" />
              
                <Selector options={airports} setCity={setToSelectedAirport} placeholder="To:" />
                <button onClick={() => quickSearch()} className=' items-center justify-center hidden sm:flex w-[35px]  h-[35px] rounded-[40px] bg-[#EB0728]'>
                <img src={search} alt="" className=' w-full h-full' />
              </button>
              
              <button onClick={() => quickSearch()} className=' mt-[12px] w-full  flex sm:hidden h-[40px] bg-[#EB0728] text-white text-[16px] items-center justify-center text-center rounded-[60px]'>
                Search Flights
              </button>
            </div>
          </div>

           {/**Search bar large*/}
           <div className=' hidden w-full lg:flex flex-col items-center justify-center mt-[31px]'>
            <div id='search' className={` ${fixSearch ? "top-[106px] fixed z-50 px-[2rem] sm:px-0" : ""} sm:pl-[10px] sm:py-[8px] sm:pr-[8px] w-full  sm:w-auto sm:h-[50px] flex flex-col sm:flex-row gap-[9px] sm:gap-0 items-center rounded-[40px] sm:border-solid sm:border-[1px] sm:border-[#C1C1C180] sm:shadow-searchBar`}>
             
              <div className='w-full ml-[33px] sm:w-auto shadow-adminLogIn sm:shadow-none border-solid border-[1px] border-[#C1C1C180] rounded-[60px] px-4 sm:px-0 sm:rounded-none sm:border-none'>
                <Selector options={airports} setCity={setFromSelectedAirport} placeholder="From:" />
              </div>

              <hr className=' hidden mr-[24px] sm:flex w-[1px] h-[30px] bg-[#C1C1C180]'></hr>

              <div className='w-full sm:w-auto shadow-adminLogIn sm:shadow-none border-solid border-[1px] border-[#C1C1C180] rounded-[60px] px-4 sm:px-0 sm:rounded-none sm:border-none'>
                <Selector options={airports} setCity={setToSelectedAirport} placeholder="To:" />
              </div>

              <button onClick={() => quickSearch()} className=' items-center justify-center hidden sm:flex w-[35px]  h-[35px] rounded-[40px] bg-[#EB0728]'>
                <img src={search} alt="" className=' w-full h-full' />
              </button>

            </div>
          </div>

        </div>


        {/**Flights Container */}
        {
          fligtData?.length > 0 ?
            <div className={`${fixSearch?" mt-[210px] sm:mt-[99px]":"mt-[53px]"} flex flex-col   `}>
              {/**Flights */}
              <div className="flex justify-center items-center ">

              <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-[63px]  md:gap-x-[40px] gap-y-[16px] `}>

              <h1 className='col-span-1 md:col-span-2 lg:col-span-3 text-[16px] leading-[19.45px] font-bold'>Available Flights</h1>

                {fligtData?.sort()?.map((flight, index) => {
                  return  <><Flight key={index} flight={flight} setIsMarketPlace={setIsMarketPlace}/>
                  {(fligtData?.length >=3  && index === 2 && islg) && (
              <div className="col-span-3  ">
                {/* If there's only one flight, show the Amazon box after it */}
                <Amazon />
              </div>
            )}  

               {(fligtData?.length <3  && index === 0 && islg) && (
              <div className="lg:col-span-1 lg:!w-[370px] ">
                {/* If there's only one flight, show the Amazon box after it */}
                <Amazon />
              </div>
            )}  

            {(fligtData?.length >=3 && index === 0 && !islg) && (
             <div className="col-span-1 ">
          
            <Amazon />
          </div>  )}
                  </>
                })}
           
              </div>
              </div>
   
            </div> : 

              <div className={` ${fixSearch ? " mt-80 sm:mt-28" : "mt-[46px]"} gap-[9px] flex flex-col text-center justify-center items-center`}>
                <img src={no_flight} alt="" className=' w-[95px] lg:w-[121px] mb-[5px]  lg:mb-[17px]' />
                <h1 className=' font-bold text-[16px] lg:text-[18px] lg:leading-[19.41px] ]'>There are no available flights</h1>
                <h1 className=' text-[16px] leading-[19.41px]  mb-[20px]'>There are no listed flights. Take the first step
                                 by listing a flight or check back later for updates</h1>

              </div>
            
        }

      </div>
      <Footer />

      </div>
      
    )
  } else {
    return (
      <div className=' w-full h-full'>
        <NoFlight />
      </div>
    )
  }
}

export default Home
