import React, { useEffect, useState } from "react";
import baseUrl from "../../components/baseUrl.js"
import axios from "axios";
import PriceCalculator from "../../components/priceCalculator";
import CarrierPriceCalculator from "../../components/carrierPriceCalculator.js";
import CustomerNav from "../../components/Nav/CustomerNav";
import Footer from "../../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sessionTrue } from "../../features/pages/sessionSlice.js";
import { BookFlightFalse } from "../../features/flight/bookFlightSlice";
import { LogInTrue } from "../../features/nav/logInSlice";
import BookingFields from "./BookingFields.jsx";
import BookingInfo from "./BookingInfo.jsx";
import MarketPlaceOrder from "./MarketPlaceOrder.jsx";

const BookFlight = ({
  airports
}) => {
  const [userID, setUserID] = useState()
  const [username, setUsername] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [access_token, setAccessToken] = useState()
  const [refresh_token, setRefreshToken] = useState()


  const dispatch = useDispatch();
  const location = useLocation();

  // Location data
  const flight = location.state?.flight;
  const packageDirection = location.state?.packageDirection

  const [isElectronics, setIsElectronics] = useState(false);
  const [isClothing, setIsClothing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  // Deviery name
  const [deliveryName, setDeliveryName] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");

  const [value, setValue] = useState(flight?.maximum_space);
  const maxValue = flight?.maximum_space;
  const [disabled, setDisabled] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);


  const toggleElectronics = () => {
    setIsElectronics(!isElectronics);
  };

  const toggleClothing = () => {
    setIsClothing(!isClothing);
  };


  const luggageSpaceAmount = PriceCalculator(value).senderCost

  const serviceCharge = (0.24 * (PriceCalculator(value).senderCost - 25))

  const totalCharge = parseFloat(luggageSpaceAmount) + parseFloat(serviceCharge)


  const initiatePaymentConfig = async (id) => {
    const data = JSON.stringify({
      booking_id: id,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/initiate-payment/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response?.data) {
        window.localStorage.setItem(
          "sdkjuiagiufdwyifdyawefuvwevyd7fuvyaewvtdfvyauewdfewthsuuysdfgduy",
          JSON.stringify(response.data)
        );
        window.location.href = response?.data?.data?.authorization_url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isClothing) {
      setErrorMessage("Item Type is Required!");
    } else if (itemDescription.length < 1) {
      setErrorMessage("Item Description is Required!");
    } else if (itemDescription.split(" ").length < 2) {
      setErrorMessage("Must be at least 2 words!");
    } else if (deliveryNumber.length < 1) {
      setErrorMessage("Phone Number is Required!");
    } else if (deliveryNumber.length >=1 && deliveryNumber.length < 10) {
      setErrorMessage("Invalid Phone Number!");
    } else if (!(value >= 1 && value <= maxValue)) {
      setErrorMessage("Invalid Weight!!");
    } else {
      setErrorMessage("");
      setDisabled(true);
      const data = JSON.stringify({
        flight_book: flight.id,
        is_electronic: isElectronics,
        is_clothing: isClothing,
        item_description: itemDescription,
        item_weight: value,
        item_fee: PriceCalculator(value).finalPrice.toFixed(2),
        sender: userID,
        carrier_earnings: CarrierPriceCalculator(value, PriceCalculator(value).senderCost),
        item_sender_name: packageDirection === "send" ? username : deliveryName,
        item_sender_number: packageDirection === "send" ? phoneNumber : deliveryNumber,
        recipient_name: packageDirection === "receive" ? username : deliveryName,
        recipient_number: packageDirection === "receive" ? phoneNumber : deliveryNumber,
        request_type: packageDirection === "send" ? 1 : 2 
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/booking/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        data: data,
      };
      try {
        await axios.request(config).then(async (response) => {
          if (response?.data) {
            dispatch(BookFlightFalse());
            await initiatePaymentConfig(response.data?.id);
          }
        }).catch((error) => {
          console.log(error)
        })

      } catch (error) {
        if (error?.response?.data?.messages) {
          if (
            error?.response?.data?.messages[0]?.message ===
            "Token is invalid or expired"
          ) {
            dispatch(sessionTrue());
          }
        } else if (error?.response?.data) {
          alert(error?.response?.data);
        }
      }
    }
  };

  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }

  useEffect(() => {
    const some_data = window.localStorage.getItem(
      "jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy"
    );
    if (!some_data) {
      dispatch(BookFlightFalse());
      dispatch(LogInTrue());
    }
    else {
      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setUsername(parsedData?.full_name)
      setPhoneNumber(parsedData?.phone_number);
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      setUserID(parsedData?.id)
    }
  }, []);

  if (flight !== undefined)
    return (
      <div>
        <CustomerNav
          component="package"
          userID={userID}
          refresh_token={refresh_token}
          access_token={access_token}
          isOpenDropDown={isOpenDropDown}
          setIsOpenDropDown={setIsOpenDropDown}
        />

        {packageDirection !== "market" ?
          <div className=" pt-[125px] sm:pt-[163px] px-[2rem] sm:px-[4.375rem] md:px-[5rem]">
            {flight && (
              <div className="">
                <img
                  src={`https://${flight?.airport_img}`}
                  alt="airportImage"
                  className="w-full h-[300px] sm:h-[395px] object-cover object-center rounded-[30px]"
                />
                <div className="mt-[33px]">
                  <h1 className="font-bold text-[#000000] text-[18px] sm:text-[20px] md:text-[25px] lg:text-[25px] xl:text-[35px] xl:leading-[42.46px]">
                    {flight.from_city} - {flight.to_city}
                  </h1>
                  <p className=" mt-[10px] text-[#707070] text-[14px] sm:text-[16px] sm:leading-[19.41px]">
                    {" "}
                    {flight.departure_airport} - {flight.arrival_airport}
                  </p>
                </div>
              </div>
            )}

            <form
              method="post"
              onSubmit={handleSubmit}
              className=" mt-[35px] flex flex-col xl:flex-row gap-[63px] justify-between"
            >
              {/**Fields */}
              <BookingFields
                packageDirection={packageDirection}
                flight={flight}
                errorMessage={errorMessage}
                itemDescription={itemDescription}
                setItemDescription={setItemDescription}
                setValue={setValue}
                phoneNumber={setPhoneNumber}
                deliveryName={deliveryName}
                setDeliveryName={setDeliveryName}
                deliveryNumber={deliveryNumber}
                setDeliveryNumber={setDeliveryNumber}
                toggleClothing={toggleClothing}
                isClothing={isClothing}
                toggleElectronics={toggleElectronics}
                isElectronics={isElectronics}
                value={value}
                maxValue={maxValue}
              />
              {/**Info */}
              <BookingInfo
                value={value}
                maxValue={maxValue}
                disabled={disabled}
                flight={flight}
                luggageSpaceAmount={luggageSpaceAmount}
                serviceCharge={serviceCharge}
                totalCharge={totalCharge}
              />
            </form>
          </div> :

          <MarketPlaceOrder flight={flight} />
        }

        <div>
          {/**Footer */}
          <Footer />
        </div>
      </div>
    );
};

export default BookFlight;
