import React, { useEffect, useState, useRef} from 'react'
import NavBar from '../../components/Nav/NavBar'
import LogInModal from '../../components/LogIn/LogInModal'
import SignUpModal from '../../components/SignUp/SignUpModal'
import { useSelector } from 'react-redux'
import Footer from '../../components/Footer/Footer'
import CustomerNav from '../../components/Nav/CustomerNav'
import emailjs from '@emailjs/browser';
import phoneIcon from './assets/phone.svg'
import mailIcon from './assets/mail.svg'
import callIcon from './assets/call.svg'

const Contact = () => {
    const form = useRef();
    const [validUser, setValidUser] = useState(false)
    const [userID, setUserID] = useState()
    const [username, setUsername] = useState("")
    const [access_token, setAccessToken] = useState()
    const [refresh_token, setRefreshToken] = useState()
    const [isOpenDropDown, setIsOpenDropDown] = useState(false);
    const [swithStatus, setSwitchStatus] = useState(false)
    const logInModal = useSelector((state) => state.logInModal.value)
    const signUpModal = useSelector((state) => state.signUpModal.value)


    // Decode the data after retrieving it
    function decodeData(encodedData) {
        return atob(encodedData); // Base64 decoding
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Smooth scrolling animation
        });
    };


    const sendEmail = (e) => {
        e.preventDefault();

        // Accessing form values using the form reference
        const userName = form.current.user_name.value;
        const userEmail = form.current.user_email.value;
        const message = form.current.message.value;

        // Clear the form fields if needed
        emailjs.send('service_zevrh2u', 'template_unlhswq', {
            from_name: userName,
            message,
            reply_to: userEmail,
            from_email: userEmail,
        }, '5fu6V6xbuhoS5wx0a').then((result) => {
            if (result.text === 'OK') {
                // eslint-disable-next-line no-alert
                alert('Thanks for Contacting Us');
                window.location.reload();
            }
        }, (error) => {
            console.log(error.text); // eslint-disable-line no-console
        });
        form.current.reset();
    };

    useEffect(() => {
        scrollToTop()
        const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
        if (some_data) {
            setValidUser(true)
            const current_Data = decodeData(some_data)
            const parsedData = JSON.parse(current_Data)
            setUsername(parsedData?.first_name)
            setAccessToken(parsedData?.access_token)
            setRefreshToken(parsedData?.refresh_token)
            setUserID(parsedData?.id)
        }
    }, [])
    return (
        <div className='w-full'>
            {validUser ? <CustomerNav component='privacy' userID={userID} refresh_token={refresh_token} access_token={access_token} swithStatus={swithStatus} setSwitchStatus={setSwitchStatus} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} /> : <NavBar />}

            {logInModal ?
                <LogInModal /> : null}
            {signUpModal ? <SignUpModal /> : null}
            <div className=" mb-[260px] w-full px-[2rem] sm:px-[4.375rem] md:px-[5rem]  flex flex-col justify-center items-center">
                <div className=" mt-[60px] sm:mt-[65px] md:mt-[75px] lg:mt-[85px] xl:mt-[99px] text-center">
                    <div className=" mt-[76px] w-full flex  flex-wrap lg:flex-nowrap gap-[16px] sm:gap-[26px] md:gap-[46px] lg:gap-[66px] xl:gap-[153px] justify-center">
                        <div className=" text-center items-center md:items-start md:text-left flex flex-col gap-5">
                            <img src={phoneIcon} alt="" className='  w-[70px] h-[70px] xm:w-[100px] xm:h-[100px] sm:w-[157px] sm:h-[149px]' />
                            <h1 className=" mt-4 text-[18px] xm:text-[25px] sm:text-[50px] lg:text-[30px] xl:text-[50px] font-bold sm:leading-[60.65px]">Contact Support</h1>
                            <h1 className=" mt-[24px] font-bold text-[14px] xm:text-[16px] sm:text-[18px] leading-[19.41px] sm:leading-[21.83px]">Send us a message</h1>
                            <p className=" text-[12px] xm:text-[14px] sm:text-[16px] leading-[19.41px]">
                                Have a specific question or request?
                                Fill out the form below, and we&apos;ll get back to you as soon as possible.
                            </p>
                            <a href="mailto:contactus@goodifly.com" className="flex gap-5">
                                <img src={mailIcon} alt="" />
                                <p>contactus@goodifly.com</p>
                            </a>
                            <a href="tel:+233533373736" className="flex gap-5">
                                <img src={callIcon} alt="" />
                                <p>+233 53 337 3736</p>
                            </a>
                        </div>

                        <form ref={form} onSubmit={sendEmail} className=" mt-[78px] md:mt-0 bg-[#E5E5E5] pt-[31px] pb-[26px] px-[20px] sm:px-[35px] sm:pb-[39px] sm:pt-[38px] rounded-[50px] flex flex-col gap-[22px] w-full  sm:w-[480px] md:w-[550px]">
                            <input required type="text" name="user_name" placeholder="Name" className="  w-full sm:w-[340px] md:w-[466px] outline-none placeholder-[#707070] text-[18px] leading-[21.83px] px-[27px]  h-[45px] rounded-[60px] border-2 border-solid " />
                            <input required type="email" name="user_email" placeholder="Email" className="  w-full sm:w-[340px] md:w-[466px] outline-none placeholder-[#707070] text-[18px] leading-[21.83px] px-[27px]  h-[45px] rounded-[60px] border-2 border-solid " />
                            <textarea required name="message" id="message" placeholder="Message" cols="30" rows="10" className=" w-full sm:w-[340px] md:w-[466px] outline-none placeholder-[#707070] text-[18px] leading-[21.83px] px-[27px] py-6 resize-none rounded-[30px]  border-2 border-solid " />
                            <button type="submit" className="bg-[#EB0728] ml-[2px] h-[45px] rounded-[50px] text-white text-[18px] leading-[21.83px]">Submit</button>
                        </form>

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact
