import React, { useEffect } from 'react'
import goodifly from "../assets/goodifly.png"
import successIcon from "../assets/success.svg"

const Congratulations = ({ newRequest, setCongratulations, congratulations}) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // For smooth scrolling
        });
    };

    const handleViewRequest = () => {
        setCongratulations(null);
        localStorage.setItem('reloadForSwitchStatus', 'true');
        window.location.reload();
      }
      

    useEffect(() => {
        scrollToTop()
    }, []);
    return (
        <div className=' bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] flex flex-col items-center justify-center pt-[65px] pb-[41px]'>
            <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
            <img src={successIcon} alt="" className=' w-[35px] h-[35px] mt-[34px]' />
            <h1 className=' text-[18px] font-bold mt-[15px]'>Congratulations</h1>
            <h1 className=' text-center px-[72px] text-[#707070] text-[16px] mt-[12px]'>You’ve accepted the booking request from 
                <span className=' text-[#EB0728] font-bold'> {congratulations.sender_name} </span>
                Click the button below to track and manage your booking.</h1>
            <button onClick={()=>handleViewRequest()} className=' mt-[59px] text-[16px] text-white w-[82.8%] rounded-[30px] bg-[#EB0728] h-[40px] flex items-center justify-center text-center'>View request</button>
        </div>
    )
}

export default Congratulations
