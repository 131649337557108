import React from 'react'
import goodifly from '../assets/goodifly.png'
import axios from 'axios'
import baseUrl from '../../baseUrl'
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../../features/pages/sessionSlice';

const SednerRecipientOutboundViewModal = ({ access_token, setViewSednerRecipientOutboundViewModal, viewSednerRecipientOutboundViewModal, setViewFlaggedOutboundViewModal, setViewSuccessOutboundModal }) => {
    const dispatch = useDispatch()
    const hideModal = (event) => {
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setViewSednerRecipientOutboundViewModal(null)
        }
    }
    const handleReceived = async (item) => {
        const data = JSON.stringify({
            flight_book: item.flight_book,
            item_description: item.item_description,
            item_fee: item.item_fee,
            item_weight: item.item_weight,
            sender: item.sender,
            carrier_acceptance_status: 3,
            agent_approval_outbound: 2
        })
        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    setViewSednerRecipientOutboundViewModal(null)
                    setViewSuccessOutboundModal("This request is now marked as received.")
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })
    }

    const handleApprove = async (item) => {
        const data = JSON.stringify({
            flight_book: item.flight_book,
            item_description: item.item_description,
            item_fee: item.item_fee,
            item_weight: item.item_weight,
            sender: item.sender,
            carrier_acceptance_status: 3,
            agent_approval_outbound: 3
        })
        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    setViewSednerRecipientOutboundViewModal(null)
                    setViewSuccessOutboundModal("This request is now approved!.")
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })
    }

    const handleComplete = async (item) => {
        const data = JSON.stringify({
            flight_book: item.flight_book,
            item_description: item.item_description,
            item_fee: item.item_fee,
            item_weight: item.item_weight,
            sender: item.sender,
            carrier_acceptance_status: 3,
            agent_approval_outbound: 4,

        })
        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    setViewSednerRecipientOutboundViewModal(null)
                    setViewSuccessOutboundModal("This request is now completed!.")
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })
    }

    const handleRemoveFlag = async (item) => {
        const data = JSON.stringify({
            flight_book: item?.flight_book,
            item_description: item?.item_description,
            item_fee: item?.item_fee,
            item_weight: item?.item_weight,
            sender: item?.sender,
            carrier_acceptance_status: 3,
            agent_approval_outbound: parseInt(item?.agent_approval_outbound) - 4,
            agent_outbound_comment: null
        })
        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${baseUrl}/booking/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response?.data?.id) {
                    setViewSednerRecipientOutboundViewModal(null)
                    setViewSuccessOutboundModal("The flag has been removed from the request.")
                }
            }).catch((error) => {
                if (error?.response?.data?.messages) {
                    if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                        dispatch(sessionTrue())

                    }
                }
                console.log(error)
            })

    }


    const handleFlagged = async (item) => {
        setViewSednerRecipientOutboundViewModal(null)
        setViewFlaggedOutboundViewModal(item)
    }

    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className=" w-full sm:w-auto relative my-6 mx-[2%] sm:mx-0 ">
                    {/*content*/}
                    <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/**Main Item */}
                        <div className=' w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]'>
                            <img src={goodifly} alt="logo" className=' w-[107px] h-[33px]' />
                            {/**Info */}
                            <div className='mt-[27px]  w-full flex justify-between'>
                                {/**Recipient Info */}
                                <div className='flex gap-1'>
                                    <div className=' flex flex-col gap-1'>
                                        <h1 className=' font-bold text-[18px] leading-[21.83px]'>{viewSednerRecipientOutboundViewModal?.recipient_name}</h1>
                                        <p className=' text-[16px] leading-[19.41px]'>{viewSednerRecipientOutboundViewModal?.recipient_number}</p>
                                    </div>
                                    <div className=' w-[77px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]'><span>Recipient</span></div>
                                </div>

                                {/**Sender Info */}
                                {/* <div className='flex gap-1'>
                                    <div className=' flex flex-col gap-1'>
                                        <h1 className=' font-bold text-[18px] leading-[21.83px]'>{viewSednerRecipientOutboundViewModal?.item_sender_name}</h1>
                                        <p className=' text-[16px] leading-[19.41px]'>{viewSednerRecipientOutboundViewModal?.item_sender_number}</p>
                                    </div>
                                    <div className=' w-[77px] h-[25px] rounded-[8px] bg-[#E5E5E5] flex items-center justify-center text-center font-bold text-[14px] leading-[16.98px]'><span>Sender</span></div>
                                </div> */}
                            </div>

                            {/**Item Info */}
                            <div className='w-full min-h-[141px] rounded-[20px] flex flex-col justify-between border-solid border-[1px] border-[#C1C1C180] mt-[22px] pt-3'>
                                {/**Item Type and Price */}
                                <div className=' px-4 sm:px-[21px]'>
                                    <div className='w-full flex items-center justify-between'>
                                        <div className='flex flex-col gap-[2px]'>
                                            <p className=' text-[14px] text-[#707070] leading-[16.98px]'>Item type</p>
                                            {viewSednerRecipientOutboundViewModal?.is_electronic === true ? <h1 className=' font-bold text-[16px] leading-[19.41px]'>Electronics</h1> : null}
                                            {viewSednerRecipientOutboundViewModal?.is_clothing === true ? <h1 className=' font-bold text-[16px] leading-[19.41px]'>Clothing</h1> : null}
                                        </div>
                                        <h1 className=' font-bold text-[18px] text-[#EB0728] leading-[21.83px]'>{viewSednerRecipientOutboundViewModal?.item_weight}kg</h1>
                                    </div>
                                </div>
                                {/**Item description */}
                                <div className='w-full min-h-[79px] rounded-b-[20px]  px-4 sm:px-[21px] pt-[11px] bg-[#E5E5E580] flex flex-col gap-1'>
                                    <p className=' text-[14px] text-[#707070] leading-[16.98px]'>Item description</p>
                                    <h1 className=' font-bold text-[16px] leading-[19.41px]'>{viewSednerRecipientOutboundViewModal?.item_description}</h1>
                                </div>
                            </div>
                            {/**Buttons */}
                            <div className=' w-full mt-[22px]  px-4 sm:px-[21px] flex gap-[14px] '>
                                {
                                    viewSednerRecipientOutboundViewModal?.agent_approval_outbound === 1 ?
                                        <button onClick={() => handleReceived(viewSednerRecipientOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Mark as received</button>
                                        : viewSednerRecipientOutboundViewModal?.agent_approval_outbound === 2 ?
                                            <button onClick={() => handleApprove(viewSednerRecipientOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Approve</button>
                                            : viewSednerRecipientOutboundViewModal?.agent_approval_outbound === 3 ?
                                                <button onClick={() => handleComplete(viewSednerRecipientOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Complete</button>
                                                : null
                                }
                                {
                                    viewSednerRecipientOutboundViewModal?.agent_approval_outbound < 4 ?
                                        <button onClick={() => handleFlagged(viewSednerRecipientOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-white border-solid border-[1px] boerder-[#C1C1C1] text-[16px] text-black leading-[19.41px]'>Flag</button>
                                        : null
                                }
                                {
                                    viewSednerRecipientOutboundViewModal?.agent_approval_outbound >= 5 ?
                                        <button onClick={() => handleRemoveFlag(viewSednerRecipientOutboundViewModal)} type="button" className=' w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px]'>Remove flag</button>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default SednerRecipientOutboundViewModal
