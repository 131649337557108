import React, { useEffect, useState } from 'react'
import PaymentProcessing from '../../components/Admin/PaymentProcessing'
import AdminNav from '../../components/Nav/AminNav'
import Footer from '../../components/Footer/Footer'
import AdminLogin from '../../components/LogIn/AdminLogin'
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { sessionTrue } from '../../features/pages/sessionSlice';
import baseUrl from '../../components/baseUrl'
import RequestInfoModal from '../../components/Admin/RequestInfo/RequestInfoModal'
import ApproveRequestModal from '../../components/Admin/ApproveRequest/ApproveRequestModal'
import CancelRequestModal from '../../components/Admin/CancelRequest/CancelRequestModal'
import DeliveryMiles from '../../components/Admin/DeliveryMiles'

import alex from '../../components/Flight/assets/alex.jpg'
import PriceCalculator from '../../components/priceCalculator'


const Admin = () => {
  const dispatch = useDispatch()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [carrierID, setCarrierID] = useState("")
  const [username, setUsername] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userCountry, setUserCountry] = useState("")
  const [access_token, setAccessToken] = useState()
  const [refresh_token, setRefreshToken] = useState()

  ///

  //admiin switch
  const [dropDownSwitch, setDropDownSwitch] = useState(0)

  // Withdrawals
  const [withdrawals, setWithrawals] = useState()
  const [newRequest, setNewRequest] = useState()
  const [completedRequest, setCompletedRequest] = useState()

  //Delivery Miles Data
  const [deliveryLocation, setDeliveryLocation] = useState('')
  const [inboundData, setInboundData] = useState([])
  const [outboundData, setOutboundData] = useState([])

  // Pop Ups
  const [requestInfo, setRequestInfo] = useState(null)
  const [approveRequest, setApproveRequest] = useState(null)
  const [cancelRequest, setCancelRequest] = useState(null)


  // Decode the data after retrieving it
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };


  const fetchWithdrawals = async (token) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/withdrawal/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: {}
    };

    try {
      axios.request(config)
        .then((response) => {
          if (response?.data) {
            setNewRequest(response?.data?.filter((item) => item.admin_approval === 1))
            setCompletedRequest(response?.data?.filter((item) => item.admin_approval !== 1))

          }
        }).catch((error) => {
          if (error?.response?.data?.messages) {
            if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
              dispatch(sessionTrue())
            }
          }
          else {
            // console.log(error)
            
          }
        });

    } catch (error) {
      // console.log(error.message)
    }
  }


  // flights
  const fetchFlightData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/flight/`).catch((error) => {
        console.error(error);
        return null;
      });

      if (response?.data) {
        const airportCache = {};
        const tempAllFlights = [];
        const tempBookedFlights = [];

        for (const f of response.data) {
          const departureKey = f.departure_airport;
          const arrivalKey = f.arrival_airport;

          // Fetch and cache departure airport data if not already cached
          if (!airportCache[departureKey]) {
            const departureResponse = await axios.get(`${baseUrl}/airport/${departureKey}/`).catch(console.error);
            airportCache[departureKey] = departureResponse?.data || {};
          }
          // Fetch and cache arrival airport data if not already cached
          if (!airportCache[arrivalKey]) {
            const arrivalResponse = await axios.get(`${baseUrl}/airport/${arrivalKey}/`).catch(console.error);
            airportCache[arrivalKey] = arrivalResponse?.data || {};
          }

          // Generate flight data
          const temp = {
            id: f.id,
            user_img: alex, // Assuming 'alex' is a variable defined elsewhere
            airport_img: decodeURIComponent(airportCache[arrivalKey].image).slice(8),
            from_city: airportCache[departureKey].city,
            from_city_code: airportCache[departureKey].city_code,
            from_country: airportCache[departureKey].country,
            departure_airport: airportCache[departureKey].name,
            departure_code: airportCache[departureKey].code,
            to_city: airportCache[arrivalKey].city,
            to_city_code: airportCache[arrivalKey].city_code,
            to_country: airportCache[arrivalKey].country,
            arrival_airport: airportCache[arrivalKey].name,
            arrival_code: airportCache[arrivalKey].code,
            departure_date: f.departure_date,
            arrival_date: f.arrival_date,
            depart_time: f.departure_time,
            arrival_time: f.arrival_time,
            minimum_space: f.minimum_space,
            maximum_space: f.maximum_space,
            price: PriceCalculator(f.maximum_space).senderCost,
            username: f.carrier_name,
            carrier_id: f.carrier,
            carrier_city: f.carrier_city,
            carrier_country: f.carrier_country,
            booking_status: f.booking_status,
            terminal: f.terminal,
          };

          tempBookedFlights.push(temp);
          if (!f.booking_status) {
            tempAllFlights.push(temp);
          }
        }
        window.localStorage.setItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfadminbduvuydfvuykjfduybfuebyryeu', JSON.stringify(tempBookedFlights.sort()))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Fetching Bookings
  const fetchBookings = async (token, id) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: {}
    };

    await axios.request(config).then((response) => {
      const flightData = JSON.parse(window.localStorage.getItem('jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfadminbduvuydfvuykjfduybfuebyryeu'))
      const payment_successful = response?.data?.filter(item => item?.payment_status === "Success")
      const res = payment_successful.map(item => {
        // Find the corresponding flight by flight_id
        const flight = flightData?.find(flight => item?.flight_book === flight?.id);
        // Merge the item and flight data

        const { id: FlightId, ...restOfMatchingFlight } = flight;
            // Return a new object that merges 'd' with the rest of the matching flight
            // and includes the renamed 'FlightId'
            return {
              ...item, // Keep the 'id' from 'd'
              FlightId, // Include the 'id' from the matching flight, but renamed
              ...restOfMatchingFlight // Spread the rest of the properties of the matching flight
            };
      });
      setInboundData(res?.filter((item) => item?.agent_approval_inbound >= 1))
      setOutboundData(res?.filter((item) => item?.agent_approval_outbound >= 1))

    }).catch((error) => {
      if (error?.response?.data?.messages) {
        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
          dispatch(sessionTrue())

        }
      }
      else {
        console.log(error)
        // 
      }
    })

  }

  useEffect(() => {
    scrollToTop()
    fetchFlightData()
    const some_data = window.localStorage.getItem("adiuiusgyudgsuwhywguyegerugerguyrgruygeygruyerdjhjhfjdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      const current_Data = decodeData(some_data)
      const parsedData = JSON.parse(current_Data)
      setCarrierID(parsedData?.id)
      setUsername(parsedData?.full_name)
      setUserCity(parsedData?.city)
      setUserCountry(parsedData?.country)
      setAccessToken(parsedData?.access_token)
      setRefreshToken(parsedData?.refresh_token)
      setIsAdmin(parsedData?.is_staff)
      fetchWithdrawals(parsedData?.access_token)
      fetchBookings(parsedData?.access_token, parsedData?.id)
      const currentDropDownSwitch = window.localStorage.getItem("sjhkytadytdfdajstvdhjastcjyasftuysafyujtasyfctjhfsaygutgygjyustfdfgydtsfdytstayfateyzytrr")
      setDropDownSwitch(parseInt(currentDropDownSwitch))
    }
  }, [])

  //**Auto Refresh */
  useEffect(() => {
    const handleMouseOver = () => {
      const some_data = window.localStorage.getItem("adiuiusgyudgsuwhywguyegerugerguyrgruygeygruyerdjhjhfjdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
      if (some_data) {
        fetchFlightData()
        let i = 1
        while (i <= 3) {
          const current_Data = decodeData(some_data)
          const parsedData = JSON.parse(current_Data)
          setCarrierID(parsedData?.id)
          setUsername(parsedData?.full_name)
          setUserCity(parsedData?.city)
          setUserCountry(parsedData?.country)
          setAccessToken(parsedData?.access_token)
          setRefreshToken(parsedData?.refresh_token)
          setIsAdmin(parsedData?.is_staff)
          fetchWithdrawals(parsedData?.access_token)
          fetchBookings(parsedData?.access_token, parsedData?.id)
          i += 1
        }
      }
    };

    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  return (
    <>
      {
        isAdmin ?
          <div>
            {requestInfo !== null ? <RequestInfoModal requestInfo={requestInfo} setRequestInfo={setRequestInfo} /> : null}
            {approveRequest !== null ? <ApproveRequestModal access_token={access_token} approveRequest={approveRequest} setApproveRequest={setApproveRequest} /> : null}
            {cancelRequest !== null ? <CancelRequestModal access_token={access_token} cancelRequest={cancelRequest} setCancelRequest={setCancelRequest} /> : null}
            <AdminNav access_token={access_token} isOpenDropDown={isOpenDropDown} setIsOpenDropDown={setIsOpenDropDown} setDropDownSwitch={setDropDownSwitch} />
            <div className='w-full px-[1rem] xm:px-[2rem] '>
              {dropDownSwitch === 0 ?
                <PaymentProcessing setCancelRequest={setCancelRequest} setApproveRequest={setApproveRequest} access_token={access_token} setRequestInfo={setRequestInfo} newRequest={newRequest} completedRequest={completedRequest} />
                :
                <DeliveryMiles access_token={access_token} inboundData={inboundData} outboundData={outboundData} setDeliveryLocation={setDeliveryLocation} deliveryLocation={deliveryLocation} />}
            </div>
            <Footer />
          </div> :
          <AdminLogin setIsAdmin={setIsAdmin} />
      }
    </>
  )
}

export default Admin

