import React, { useEffect, useRef } from 'react';
import profile from './assets/profile.jpg'
import arrowDown from './assets/arrowDown.svg'
import callIcon from './assets/call.svg'
import settingsIcon from './assets/settings.svg'
import logoutIcon from './assets/logout.svg'
import earningIcon from './assets/earning.svg'
import bookingIcon from './assets/booking.svg'
import listFlightIcon from './assets/lisFlight.svg'
import faqIcon from './assets/faq_icon.svg'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ListFlightTrue } from '../../features/flight/listFlightSlice';
import {SwitchTrue} from '../../features/nav/switchStatusSlice'


function ProfileDropdown({ type, access_token, isOpenDropDown, setIsOpenDropDown }) {
    const baseUrl = "https://api.goodifly.com"
    const dropdownRef = useRef(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenDropDown(false);
            }
        }

        // Attach the event listener on component mount
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpenDropDown(!isOpenDropDown);
    };

    const LogOut = async () => {
        const data = {}
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/signout/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token,
            },
            data: data
        };
        try {
            await axios.request(config)
                .then((response) => {
                    console.log(response)
                }).catch(error => {
                    console.log("Axios Catch: => ", error)
                })
        }
        catch (error) {
            console.log(error)
        }

        window.localStorage.clear()
        navigate("/")
        window.location.reload()
    }

    return (
        <div ref={dropdownRef} className="z-90 relative inline-block  text-left">
            {/* <div>
                <button onClick={toggleDropdown} className=' w-[90px] h-[45px] bg-[#D9D9D9] rounded-[50px] flex items-center'>
                    <div className=' w-full flex items-center justify-center'>
                        <img src={arrowDown} alt="" className={` ${isOpenDropDown ? "rotate-180" : ""} w-[14.58px] h-[7.29px]`} />
                    </div>
                    <div className=' w-full flex flex-row-reverse'>
                        <img src={profile} alt="" className=' w-[45px] h-[45px] rounded-[45px]' />
                    </div>
                </button>
            </div> */}

<div>
                <button onClick={toggleDropdown} className=' w-[78px] h-[40px] border border-solid rounded-[80px] flex items-center justify-center gap-[8px]'>
                   
                        <img src={arrowDown} alt="" className={` ${isOpenDropDown ? "rotate-180" : ""}`} />
                   
                        <img src={profile} alt="" className=' border border-solid h-[25px] w-[25px] rounded-full ' />
                    
                    
                </button>
            </div>

            {isOpenDropDown && (
                <div className=" z-90 origin-top-right absolute pb-[25px] right-0 mt-[12px] w-[258px]  pt-[30px]  rounded-[20px] shadow-input bg-white ring-1 ring-black ring-opacity-5 leading-[16.98px]">
                    <div
                        className="  flex flex-col gap-[0px]"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {type === "customer" ?
                            <div className=' w-full   gap-[8px]  flex sm:hidden'>
                                <button onClick={() => dispatch(SwitchTrue())} className='px-[27px] py-[11px] flex xm:hidden  gap-[8px] text-center items-center  text-[14px] font-bold flex-shrink-0'> 
                                    <img src={bookingIcon} alt="" />
                                    <h1 className=' text-[14px]'>Bookings</h1>
                                </button>
                            </div>
                            : ""}
                        {type === "carrier" ?
                            <div>
                                <div className=' w-full   gap-[8px]  flex md:hidden'>
                                    <button onClick={() => dispatch(ListFlightTrue())} className='px-[27px] py-[11px] flex xm:hidden gap-[8px] text-center items-center  text-[14px]  font-bold flex-shrink-0'>
                                        <img src={listFlightIcon} alt="" />
                                        <h1 className=' text-[14px]'>List Flight</h1>
                                    </button>
                         
                                </div>
                            </div>
                            : ""}

                        <button onClick={()=>navigate('/earnings')} className="px-[27px] py-[11px]  w-full flex  hover:bg-gray-100 gap-[8px]">
                            <img src={earningIcon} alt="" />
                            <h1 className=' text-[14px]'>Earnings</h1>
                        </button>
                       
                        <button onClick={()=>navigate('/contact')} className=" px-[27px]  py-[11px] w-full flex  hover:bg-gray-100 gap-[8px]">
                            <img src={callIcon} alt="" />
                            <h1 className=' text-[14px]'>Contact support</h1>
                        </button>

                        <button onClick={()=>navigate('/faq')} className=" px-[27px] py-[11px] w-full flex  hover:bg-gray-100 gap-[8px]">
                            <img src={faqIcon} alt="" />
                            <h1 className=' text-[14px] font-normal'>FAQs</h1>
                        </button>

                        <button onClick={()=>navigate('/settings')} className="px-[27px]  py-[11px] w-full flex  hover:bg-gray-100 gap-[8px]">
                            <img src={settingsIcon} alt="" />
                            <h1 className=' text-[14px]'>Settings</h1>
                        </button>

                        <button onClick={LogOut} className=" px-[27px]  py-[11px] w-full flex  hover:bg-gray-100 gap-[8px]">
                            <img src={logoutIcon} alt="" />
                            <h1 className=' text-[14px]'>Logout</h1>
                        </button>

                        {type === "customer" ?
                            <div className='px-[27px]  py-[11px]  w-full  flex-col justify-start  gap-[8px]  flex sm:hidden'>
                                <button onClick={() => navigate('/dashboard/carrier')} className=' text-[#ffffff] text-center items-center justify-center text-[12px] lg:text-[1.125rem] w-86px] bg-[#EB0728] leading-[14.56px] py-[7px] rounded-[60px] flex-shrink-0'>Become a Carrier</button>
                                
                            </div>
                            : ""}

                        {type === "carrier" ?
                            <div>
                                <div className='px-[27px] w-full py-[11px] flex-col justify-start gap-[8px]  flex md:hidden'>
                                    <button onClick={() => navigate('/dashboard')} className=' text-[#ffffff] text-center items-center justify-center text-[12px] lg:text-[1.125rem] w-86px]  bg-[#EB0728] leading-[14.56px] py-[7px]  rounded-[60px] flex-shrink-0'>Send a package</button>
                                </div>
                            </div>
                            : ""}

                    </div>
                </div>
            )}
        </div>
    );
}

export default ProfileDropdown;
