import React from 'react'
import goodifly from "../../assets/goodifly.png"
import bank from '../../assets/bank.svg'
import momo from '../../assets/momo.svg'
import vodaCash from '../../assets/vodaCash.jpg'
import airtelTigo from '../../assets/airtelTigo.jpg'
import baseUrl from '../../../../components/baseUrl'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { sessionTrue } from '../../../../features/pages/sessionSlice';


const ReviewMethod = ({totalBalance, access_token, reviewWithdrawalMethod, setReviewWithdrawalMethod, bankAdded, mobileMoneyAdded, setSuccessWithdrawal }) => {
    const dispatch = useDispatch()

    const handleWithdrawal = async (token) => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/withdrawal/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {
                withdrawal_method: reviewWithdrawalMethod === "Mobile Money" ? mobileMoneyAdded?.id : bankAdded?.id,
                withdrawal_amount: parseFloat(totalBalance),
                user: reviewWithdrawalMethod === "Mobile Money" ? mobileMoneyAdded?.user : bankAdded?.user,
            }
        };
        
        try {
            axios.request(config)
                .then((response) => {
                    if(response?.data){
                        console.log(response)
                        setSuccessWithdrawal(true)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.messages) {
                        if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
                         dispatch(sessionTrue())

                        }
                    }
                    else {
                        console.log(error)
                        
                    }
                });

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className=' px-5 sm:px-[50px] bg-white rounded-[30px] w-full sm:h-full flex flex-col items-center pt-[44px] sm:pt-[48px] pb-[44px] sm:pb-[105px]'>
            <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
            <h1 className=' mt-[26px] font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] '>Withdraw Funds</h1>
            <h1 className=' mt-[6px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>Please review your withdrawal details</h1>

            <h1 className=' mt-[33px] font-bold text-[50px] leading-[60.65px]'>${totalBalance}</h1>

            <div className={` mt-[12px] w-full flex flex-col`}>
                {bankAdded !== null && reviewWithdrawalMethod==="Bank Transfer" ?
                    <div className=' w-full text-left flex flex-wrap gap-4 xf:gap-2 xf:justify-between bg-[#E5E5E5] rounded-[20px] pt-[13px] pb-[15px] px-3 xs:px-[10%]'>
                        <div className='  flex flex-col gap-1'>
                            <h1 className=' font-bold text-[14px] leading-[16.98px]'>Transfer to</h1>
                            <h1 className=' text-[#AAAAAA] text-[16px] leading-[19.41px]'>{bankAdded?.name}</h1>
                        </div>
                        <div className='  flex flex-col gap-1'>
                            <h1 className=' font-bold text-[14px] leading-[16.98px]'>Account</h1>
                            <h1 className=' text-[#AAAAAA] text-[16px] leading-[19.41px]'>{bankAdded?.account_number}</h1>
                        </div>
                    </div>
                    : null}
                {mobileMoneyAdded !== null && reviewWithdrawalMethod==="Mobile Money" ?
                    <div className=' w-full text-left flex flex-wrap gap-4 xf:gap-2 xf:justify-between bg-[#E5E5E5] rounded-[20px] pt-[13px] pb-[15px] px-3 xs:px-[10%]'>
                        <div className='  flex flex-col gap-1'>
                            <h1 className=' font-bold text-[14px] leading-[16.98px]'>Transfer to</h1>
                            <h1 className=' text-[#AAAAAA] text-[16px] leading-[19.41px]'>{mobileMoneyAdded?.name}</h1>
                        </div>
                        <div className='  flex flex-col gap-1'>
                            <h1 className=' font-bold text-[14px] leading-[16.98px]'>Account</h1>
                            <h1 className=' text-[#AAAAAA] text-[16px] leading-[19.41px]'>{mobileMoneyAdded?.account_number}</h1>
                        </div>
                    </div> : null}
            </div>
            <div className='w-full mt-[60px] flex flex-col xs:flex-row items-center gap-4 xs:gap-0 justify-between'>
                <button onClick={() => setReviewWithdrawalMethod(null)} className=' h-[45px] w-[130px] sm:w-[171px] border-[#AAAAAA] border-[2px] rounded-[30px] text-[18px] leading-[21.83px]'>Cancel</button>
                <button onClick={()=>{setReviewWithdrawalMethod(null); handleWithdrawal(access_token);}} className=' h-[45px] w-[130px] sm:w-[171px] rounded-[30px] text-white bg-[#EB0728] text-[18px] leading-[21.83px]'>Withdraw</button>
            </div>
        </div>
    )
}

export default ReviewMethod
