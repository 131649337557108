import { configureStore } from '@reduxjs/toolkit';
import logInReducer from '../features/nav/logInSlice'
import signUpReducer from '../features/nav/signUpSlice'
import switchStatusReducer from '../features/nav/switchStatusSlice'
import listFlightReducer from '../features/flight/listFlightSlice'
import bookFlightReducer from '../features/flight/bookFlightSlice';
import editFlightReducer from '../features/flight/editFlightSlice'
import deleteFlightReducer from '../features/flight/deleteFlightSlice'
import successEditFlightReducer from '../features/flight/successEditFlightSlice'
import successDeleteFlightReducer from '../features/flight/successDeleteFlightSlice'
import flightTrackReducer from '../features/flight/flightTrackSlice'
import flightListSuccessReducer from '../features/flight/flightListSuccessSlice'
import unableFlightReducer from '../features/flight/unableFlightSlice'
import sessionReducer from '../features/pages/sessionSlice'


export const store = configureStore({
  reducer: {
    logInModal:logInReducer,
    signUpModal:signUpReducer,
    listFlight:listFlightReducer,
    bookFlight:bookFlightReducer,
    flightListSuccess:flightListSuccessReducer,
    editFlight:editFlightReducer,
    deleteFlight:deleteFlightReducer,
    successEditFlight:successEditFlightReducer,
    successDeleteFlight:successDeleteFlightReducer,
    flightTrack:flightTrackReducer,
    switchStatus:switchStatusReducer,
    unableFlight:unableFlightReducer,
    session:sessionReducer,
  },
});
