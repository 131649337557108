import React, { useEffect } from 'react'
import { SignUpFalse } from '../../features/nav/signUpSlice'
import SignUp from './SignUp'
import { useDispatch } from 'react-redux'

const SignUpModal = () => {
    const dispatch = useDispatch()

    const hideModal = (event) => {
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            dispatch(SignUpFalse()); // Call the function to close the modal
        }
    }
    // Effect to manage body overflow on modal open/close
    useEffect(() => {
        const handleScrollLock = (isOpen) => {
            if (isOpen) {
                document.body.classList.add('overflow-hidden'); // Disable scrolling
            } else {
                document.body.classList.remove('overflow-hidden'); // Enable scrolling
            }
        };

        // You might want to replace this with the actual condition to check if the modal is open
        const isOpen = true; // Replace this with your modal open state condition

        handleScrollLock(isOpen);

        // Clean up effect when unmounting
        return () => {
            document.body.classList.remove('overflow-hidden'); // Ensure scrolling is enabled on unmount
        };
    }, [dispatch]);

    return (
        <div>

            <div>
                {/* Overlay */}
                <div
                    onClick={hideModal}
                    className="cursor-pointer fixed inset-0 z-[60] bg-black bg-opacity-20" >

                </div>

                {/* Modal */}
                <div
                    id="modal"
                    className="fixed bottom-0 left-0 w-full max-h-[80%] h-[80%] z-[70] bg-white rounded-t-[30px] px-[29px] lg:px-[46px] pt-[31px] pb-[36px]
                                xm:rounded-[30px] transition-transform transform-gpu duration-300 ease-out  sm:w-[60%] overflow-y-auto
                                md:w-[50%] lg:w-[50%] xl:w-[528px] sm:bottom-auto md:bottom-auto lg:bottom-auto md:top-1/2 md:left-1/2 sm:top-1/2 sm:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:h-auto sm:-translate-x-1/2 sm:-translate-y-1/2 sm:h-auto lg:h-auto"
                                        >
                    {/* Modal Content (Login Form) */}
                    <div className="">
                        <SignUp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpModal
