import React from 'react'
import EditBankTransfer from './EditBankTransfer';
const EditBankTransferModal = ({user, banks, access_token, setAddWithrawalMethod, bankAdded, setBankAdded, setEditBankAdded, setManageSucess}) => {
    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setEditBankAdded(false); // Call the function to close the modal
        }
    }
    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 w-full xm:w-auto">
                    {/*content*/}
                    <div className=" cursor-default border-0  relative flex flex-col text-center w-full  xm:w-[418px] sm:w-[518px] h-fit sm:h-[550px] outline-none focus:outline-none">
                        <EditBankTransfer user={user} banks={banks} access_token={access_token} setAddWithrawalMethod={setAddWithrawalMethod} bankAdded={bankAdded} setBankAdded={setBankAdded} setEditBankAdded={setEditBankAdded} setManageSucess={setManageSucess} />
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default EditBankTransferModal

