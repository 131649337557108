import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}


export const sucessDeleteLightSlice = createSlice({
    name: 'sucessDeleteFlight',
    initialState,
    reducers: {
      SuccessDeleteFlightTrue:(state)=>{
        state.value=true
      },
      SuccessDeleteFlightFalse:(state)=>{
        state.value=false
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { SuccessDeleteFlightTrue, SuccessDeleteFlightFalse} = sucessDeleteLightSlice.actions
  
  export default sucessDeleteLightSlice.reducer