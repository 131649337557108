import React, { useEffect, useState } from 'react'
// import { FaBars } from 'react-icons/fa';
import ham from './assets/ham.svg'
import goodifly from './assets/goodifly.png'
import { LogInTrue } from '../../features/nav/logInSlice'
import { SignUpTrue } from '../../features/nav/signUpSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import closeIcon from './assets/closeIcon.svg'

const HomeNav = ({area}) => {
  const navigate = useNavigate()
  const [showBackground, setShowBackground] = useState(false);
  const [increaseHeight, setIncreaseHeight] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
  const [validUser, setValidUser] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };


  const handleCarrier = () => {
    if (validUser) {
      navigate("/dashboard/carrier")
    }
    else {
      dispatch(LogInTrue())
    }
  }

  useEffect(() => {

    const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
    if (some_data) {
      setValidUser(true)
      // const current_Data = decodeData(some_data)
      // const parsedData = JSON.parse(current_Data)
      navigate("/dashboard")
    }
    const handleScroll = () => {
      // Determine the scroll position
      const scrollPosition = window.scrollY;
      const screenWidth = window.innerWidth

      if (scrollPosition > 620 && screenWidth >= 1100) {
        setShowBackground(true)
      }
      else if (scrollPosition > 580 && screenWidth >= 980 && screenWidth < 1100) {
        setShowBackground(true)
      }
      else if (scrollPosition > 500 && screenWidth >= 840 && screenWidth < 980) {
        setShowBackground(true)
      }
      else if (scrollPosition > 450 && screenWidth >= 750 && screenWidth < 840) {
        setShowBackground(true)
      }
      else if (scrollPosition > 400 && screenWidth >= 640 && screenWidth < 750) {
        setShowBackground(true)
      }
      else {
        setShowBackground(false);
      }

      // On a mobile the padding bottom need to be increased
     if (scrollPosition > 500 && screenWidth >= 200 && screenWidth < 640) {
      setIncreaseHeight(true)
      }
      else{
        setIncreaseHeight(false)
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

   
  return (
    <>
      <div className={` ${showBackground?" pb-[85px] ":""} ${increaseHeight?" h-[311px]":""} pb-[37px]  bg-white  z-50   fixed flex flex-col w-full  pt-[33px] sm:pt-[2rem] px-[29px] sm:px-[4.375rem] md:px-[5rem] `}>
        <div className='flex w-full justify-between'>
          <img src={goodifly} alt="" onClick={() => navigate("/")} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[190px] sm:h-[58.29px]" />
          <div className='flex gap-5 justify-center items-center'>
            <button onClick={handleCarrier} className=' hidden sm:flex text-[#ffffff] text-center items-center justify-center text-[16px]  bg-[#EB0728]  h-[40px] rounded-[50px] px-[20px] lg:rounded-[40px] leading-[19.41px] flex-shrink-0'>Become a Carrier</button>
            {/**large devices */}
            <div className={`hidden lg:flex gap-5 justify-center items-center `}>
              <button onClick={() => dispatch(LogInTrue())} className={` ${validUser ? "hidden" : ""} text-[1.125rem] font-bold `}>Login</button>
              <button onClick={() => dispatch(SignUpTrue())} className={` ${validUser ? "hidden" : ""} text-[1.125rem] font-bold `}>Sign up</button>
              <button onClick={() => navigate("/contact")} className='text-[1.125rem] font-bold '>Contact Support</button>
              <button onClick={() => navigate("/faq")} className='text-[1.125rem] font-bold '>FAQs</button>

            </div>
          </div>
          {/**small devices */}
          <div className="lg:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <img  src={ham}    />
            </button> 
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className=" lg:hidden fixed  inset-0 bg-[#00000033] bg-opacity-50 z-[60] flex items-center justify-center">
          <div className=" px-[29px] pt-[33px] bg-white  w-4/5 xm:max-w-[393px] h-full absolute left-0 transform z-[70]">
            {/* Close Button */}
            <div className=' w-full flex items-center justify-between'>
            <img src={goodifly} alt="" onClick={() => navigate("/")} className=" cursor-pointer w-[130px] h-[39.88px] sm:w-[190px] sm:h-[58.29px]" />

              <button
                className="absolute text-white right-5"
                onClick={closeMenu}
              >
                <img src={closeIcon} alt="" srcSet="" className='transition ease-in duration-300' />
              </button>
            </div>
            {/* Your navigation links here */}
            <div className=' mt-[30px] items-start justify-start font-bold text-[18px] flex flex-col gap-[15px] leading-[21.83px] mb-[32px]'>
              <button onClick={() => { dispatch(LogInTrue()); closeMenu() }} >Login</button>
              <button onClick={() => { dispatch(SignUpTrue()); closeMenu() }} >Sign up</button>
              <button onClick={() => navigate("/contact")}>Contact Support</button>
              <button onClick={() => navigate("/faq")}>FAQs</button>
            </div>
            {/**Become a carrier */}
            <button onClick={()=>{handleCarrier(); closeMenu()}} className='w-full sm:hidden mt-[32px] flex text-[#ffffff] text-center items-center justify-center text-[16px]  bg-[#EB0728] w-full h-[40px] rounded-[50px] flex-shrink-0 leading-[19.41px] font-normal'>Become a Carrier</button>
          
          <div className=' ml-[-32px] absolute flex items-center justify-center w-full gap-[5px] bottom-0'>
            <h1 className=' font-normal text-[30px] leading-[36.39px]'>©</h1>
            <h1 className=' font-normal text-[12px] leading-[14.56px]'>2023 Goodifly. All rights reserved.</h1>
          </div>
          </div>
        </div>
      )}
    </>

  )
}

export default HomeNav
