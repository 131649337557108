import React from "react";
import marketPlaceIcon from "./assets/marketplace_icon.svg"
const MarketplaceBadge = () => {
  return (
    <div className="z-10 transform translate-x-[-50%] absolute left-1/2 top-[51%] flex items-center h-[55px] align-middle justify-start bg-white  h-[55px] w-[161px] rounded-[200px] border-[1px] border-[#C1C1C180] px-[7px] py-[7px]  mx-auto">
      
      <div className="relative inline-block flex items-center justify-center  w-[42px] h-[42px] bg-[#EB0728] rounded-[200px]">
      <img
        src={marketPlaceIcon}
        alt=""
        className=" "
      />
      </div>
      <span className=" font-bold text-[14px] leading-[16.98px]  text-[#EB0728] ml-[12px]">
        Marketplace
      </span>
    </div>
  );
};

export default MarketplaceBadge