import React from 'react'
import { useDispatch } from 'react-redux';
import { ListFlightTrue } from '../../../../features/flight/listFlightSlice';
import goodifly from "../assets/goodifly.png"
import baseUrl from '../../../baseUrl'
import axios from 'axios'
import { sessionTrue } from '../../../../features/pages/sessionSlice';



const ListFlightAfterDecline = ({ setListFlightAfterDecline, listFlightAfterDecline, access_token }) => {
  const dispatch = useDispatch()

  const completeDecline = async (item) => {
    const data = JSON.stringify({
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_acceptance_status: 2,
    })
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/flight/${item.flight_book}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response?.data?.id) {
          // setConfirmDecline(item)
          setListFlightAfterDecline(true)
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
        console.log(error)
      })
  }

  const handleDeclined = async (item) => {
    const data = JSON.stringify({
      flight_book: item.flight_book,
      item_description: item.item_description,
      item_fee: item.item_fee,
      item_weight: item.item_weight,
      sender: item.sender,
      carrier_approval: false,
      carrier_acceptance_status: 2,
    })
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/${item.id}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: data
    };

    axios.request(config)
      .then(async (response) => {
        if (response?.data?.id) {
          await completeDecline(item)
        }
      }).catch((error) => {
        if (error?.response?.data?.messages) {
          if (error?.response?.data?.messages[0]?.message === 'Token is invalid or expired') {
            dispatch(sessionTrue())

          }
        }
      })
  }

  const handleNo = async (item) => {
    try {
      await setListFlightAfterDecline(null);
      await handleDeclined(item);
    } catch (error) {
      console.error(error);
    }
  };

  const handleYes = async (item) => {
    try {
      await setListFlightAfterDecline(null);
      await handleDeclined(item);
      await setListFlightAfterDecline(null);
      dispatch(ListFlightTrue());
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <div className=' bg-white rounded-[30px] w-full xs:w-[329px] xm:w-[428px] sm:w-[528px] flex flex-col items-center justify-center pt-[50px] sm:pt-[98px] pb-[60px] sm:pb-[105px]'>
      <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
      <h1 className=' text-[18px] font-bold mt-[20px] sm:mt-[47px]'>List flight</h1>
      <h1 className=' w-full flex text-center items-center justify-center text-[#707070] text-[16px] mt-[7px]'>Would you like to list a flight?</h1>
      <div className=' w-full px-6 mt-[41px] gap-[24px] flex items-center justify-center'>
        <button onClick={() => handleNo(listFlightAfterDecline)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] border-[#AAAAAA] flex items-center justify-center text-center text-[18px]'>No</button>
        <button onClick={() => handleYes(listFlightAfterDecline)} className=' w-full sm:w-[171px] h-[45px] rounded-[30px] border-[2px] bg-[#EB0728] border-[#EB0728] text-white flex items-center justify-center text-center text-[18px]'>Yes</button>
      </div>
    </div>
  )
}

export default ListFlightAfterDecline
