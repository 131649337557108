import React, { useEffect, useState } from 'react'
import goodifly from './assets/goodifly.png'
import PriceCalculator from '../priceCalculator'
import CarrierPriceCalculator from '../carrierPriceCalculator';
function convertDateFormat(dateString) {
  // Split the string into parts
  const parts = dateString?.split('-');
  // Reorder the parts and join them with a "/"
  if (parts) {
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }
}


const ViewAcceptedRequest = ({ viewAcceptedRequest, setViewAcceptedRequest, setConfirmItemDelivered }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const startDate = viewAcceptedRequest?.departure_date
  const startTime = viewAcceptedRequest?.departure_time
  const endDate = viewAcceptedRequest?.arrival_date
  const endTime = viewAcceptedRequest?.arrival_time


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };

  useEffect(() => {
    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    const intervalId = setInterval(() => {
      const now = new Date();

      if (now < start) {
        setTimeRemaining('Not started');
      } else if (now >= start && now <= end) {
        const timeDiff = end - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // total hours remaining
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const seconds = Math.floor((timeDiff / 1000) % 60);
        setTimeRemaining(formatTime(hours, minutes, seconds));
      } else {
        setTimeRemaining('Ended');
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate, startTime, endDate, endTime]); // Dependencies array

  const formatTime = (hours, minutes, seconds) => {
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className=" border-solid border-[1px] border-[#C1C1C180] rounded-[20px] xm:border-none xm:rounded-none w-full flex flex-col sm:mt-4 xl:mt-0 xm:px-4 py-4 xm:pt-0 sm:px-5 lg:px-[25px] xl:px-0">
      {/**Logo and go back button */}
      <div className=" px-4 xm:px-0 w-full flex justify-between">
        <img
          src={goodifly}
          alt="logo"
          className=" w-[76px] h-[23px] md:w-[127px] md:h-[39px]"
        />
        <button
          onClick={() => setViewAcceptedRequest(null)}
          className=" h-[19px] text-[14px] sm:text-[16px] sm:leading-[19.41px] text-[#EB0728] border-solid border-b-[1px] border-b-[#EB0728]"
        >
          Go Back
        </button>
      </div>

      {/**Amount and Approval Info */}
      <div className=" px-4 xm:px-0 w-full mt-[24px] flex flex-col gap-[19px] sm:gap-0 sm:flex-row justify-between">
        <div className="flex flex-col gap-[3px] text-left">
          <h1 className=" font-bold text-[18px] sm:text-[20px] md:text-[25px] lg:text-[25px] xl:text-[40px] xl:leading-[48.52px]">
            $
            {CarrierPriceCalculator(
              viewAcceptedRequest.item_weight,
              PriceCalculator(viewAcceptedRequest.item_weight).senderCost
            )}
          </h1>
          <p className=" text-[16px] text-[#AAAAAA] leading-[19.41px]">
            Earnings includes service charge
          </p>
        </div>
        {viewAcceptedRequest?.carrier_approval === true ? (
          <div className=" h-fit xs:min-w-[180px] w-full xs:w-[180px] bg-[#FFDFE4] text-[#EB0728] text-[10px] leading-[12.13px] font-bold rounded-[30px] border-solid border-[1px]  border-[#EB0728]  py-[6px]">
            Awaiting sender confirmation
          </div>
        ) : null}
      </div>

      {/**Mobile device line */}
      <div className=" mt-[27px] xm:hidden w-full border-solid border-[1px] border-[#C1C1C180] " />

      {/**More Info */}
      <div className=" px-4 mt-[25px] w-full xm:px-3 md:px-0 md:w-auto lg:w-full lg:px-[30px] xl:px-0 flex flex-col items-center">
        <div className=" pt-[18px] xm:pt-[22px] xm:border-solid xm:border-[1px] xm:border-[#C1C1C180] xm:rounded-[20px] flex flex-col items-start w-full ">
          <div className=" w-full flex flex-col items-start text-start bg-white px-[20px] lg:px-[30px]">
            {/**Source Info */}
            <div className=" w-full flex flex-col-reverse gap-3 xm:flex-row xm:gap-0 items-start xm:items-center justify-between">
              <div className="flex flex-col gap-2">
                <h1 className=" text-[14px] sm:text-[18px] lg:text-[25px] font-bold">
                  {viewAcceptedRequest.departure_city} -{" "}
                  {viewAcceptedRequest.arrival_city}
                </h1>
                <p className=" font-bold text-[14px] leading-[16.98px]">
                  Request from {viewAcceptedRequest.sender_name}
                </p>
              </div>
              {/**Weight */}
              <div
                className={`font-bold hidden xm:flex items-center justify-center text-[#EB0728] text-center text-[16px] sm:text-[25px] sm:leading-[30.33px]`}
              >
                {viewAcceptedRequest?.item_weight}kg
              </div>
            </div>

            {/**Date */}
            <div className=" text-[14px] md:text-[16px] md:leading-[19.41px] mt-[8px] flex flex-col xs:flex-row justify-between w-full xs:w-auto xs:justify-normal gap-[16px] xm:gap-[23px]">
              <div className=" items-start md:items-center xm:justify-center xm:text-center flex xs:flex-col sm:flex-row gap-[2px] md:gap-[7px]">
                <p className="text-[#AAAAAA]">Departure date:</p>
                <p className=" font-bold">
                  {convertDateFormat(viewAcceptedRequest.departure_date)}
                </p>
              </div>
              <div className=" items-start md:items-center xm:justify-center xm:text-center flex xs:flex-col sm:flex-row gap-[2px] md:gap-[7px]">
                <p className="text-[#AAAAAA]">Arrival date:</p>
                <p className=" font-bold">
                  {convertDateFormat(viewAcceptedRequest.arrival_date)}
                </p>
              </div>
            </div>

            {/**Small device weight */}
            <div
              className={`font-bold flex mt-4 xm:mt-0 xm:hidden items-center justify-center text-[#EB0728] text-center text-[16px] sm:text-[25px] sm:leading-[30.33px]`}
            >
              {viewAcceptedRequest?.item_weight}kg
            </div>
          </div>

          <div className=" text-[16px] leading-[19.41px] mt-[20px] w-full bg-[#E5E5E580] rounded-b-[20px] flex flex-col gap-[8px] items-start text-start pt-[18px] pb-[26px] px-[20px] lg:px-[30px]">
            <p className=" text-[#AAAAAA]">Item description</p>
            <p className=" font-bold">
              {viewAcceptedRequest?.item_description}
            </p>
          </div>
        </div>
      </div>

      {/**Confirm Button */}
      <div className=" px-4 pt-[22px] w-full xm:px-3 md:px-0 md:w-auto lg:w-full lg:px-[30px] xl:px-0 flex flex-col items-center">
        <button
          onClick={() => {
            scrollToTop();
            setConfirmItemDelivered(viewAcceptedRequest);
          }}
          disabled={
            timeRemaining !== "Ended" ||
            viewAcceptedRequest?.carrier_approval === true
              ? true
              : false
          }
          className={` disabled:bg-[#F4C5CC] disabled:cursor-not-allowed ${
            timeRemaining !== "Ended"
              ? " cursor-not-allowed bg-[#F4C5CC]"
              : "bg-[#EB0728]"
          } w-full h-[35px] sm:h-[45px] rounded-[40px] flex text-center items-center justify-center text-white text-[12px] sm:text-[18px] `}
        >
          Confirmed Item delivered
        </button>
      </div>
    </div>
  );
}

export default ViewAcceptedRequest
