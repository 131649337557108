import React from 'react'
import TotalSenderPrice from '../../totalSenderPrice'
import goodifly from '../assets/goodifly.png'
import profile from '../assets/profile.jpg'
import fly from '../assets/fly.svg'
import { convertTo12HourFormat, formatDate } from '../../ConvertDateAndTime'


const ViewFlightHistory = ({viewFlightHistory}) => {
  console.log(viewFlightHistory)
    return (
        <div className='py-[35px]'>
          <div className=' px-[28px] sm:px-[45px]'>
            <div className=' flex items-center justify-between'>
              {/**Profile */}
              <div className='flex items-center gap-[11px]'>
                <img src={profile} alt="" className=' w-[40px] h-[40px] sm:w-[51px] sm:h-[51px] rounded-[40px] sm:rounded-[51px]' />
                <div className='flex flex-col'>
                  <h1 className=' font-bold text-[14px] sm:text-[16px] sm:leading-[19.41px]'>{viewFlightHistory?.carrier_name}</h1>
                  <p className=' font-normal text-[12px] sm:text-[14px] text-[#AAAAAA] sm:leading-[16.98px]'>Carrier</p>
                </div>
              </div>
              {/**Goodifly logo */}
              <img src={goodifly} alt="" className=' w-[94px] h-[29px] sm:w-[107px] sm:h-[33px]' />
            </div>
  
            {/**Date Info */}
            <div className=' mt-[38px]  w-full flex gap-[10px] items-center justify-between'>
              {/**Departure Info */}
              <div className=' text-[12px] sm:text-[14pxpx] sm:leading-[16.98px] flex flex-col gap-[2px] items-center'>
                <h1 className='  text-[#AAAAAA]'>{viewFlightHistory?.from_city}</h1>
                <h1 className=' font-bold text-[14px] xm:text-[16px] sm:text-[18px] sm:leading-[21.83px]'>{viewFlightHistory?.from_city_code}</h1>
                <h1 className='  text-[#AAAAAA]'>{formatDate(viewFlightHistory?.departure_date)}</h1>
                <h1 className='  text-[#AAAAAA]'>{convertTo12HourFormat(viewFlightHistory?.departure_time)}</h1>
              </div>
  
              <img src={fly} alt="fly" className=' w-[100px] sm:w-auto' />
  
              {/**Arrival Info */}
              <div className=' text-[12px] sm:text-[14pxpx] sm:leading-[16.98px] flex flex-col gap-[2px] items-center'>
                <h1 className=' text-[#AAAAAA]'>{viewFlightHistory?.to_city}</h1>
                <h1 className=' font-bold text-[14px] xm:text-[16px] sm:text-[18px] sm:leading-[21.83px]'>{viewFlightHistory?.to_city_code}</h1>
                <h1 className=' text-[#AAAAAA]'>{formatDate(viewFlightHistory?.arrival_date)}</h1>
                <h1 className=' text-[#AAAAAA]'>{convertTo12HourFormat(viewFlightHistory?.arrival_time)}</h1>
              </div>
            </div>
  
            {/**Fee and Weight */}
            <div className='flex justify-between text-[14px] sm:text-[16px] sm:leading-[19.41px] mt-[47px]'>
              <div className='flex flex-col gap-[5px]'>
                <h1 className=' text-[#AAAAAA]'>Item weight</h1>
                <h1 className=' font-bold'>{viewFlightHistory?.item_weight}kg</h1>
              </div>

              <h1 className=' font-bold text-[#EB0728] text-[18px] sm:text-[20px] md:text-[25px] lg:text-[25px] xl:text-[35px] xl:leading-[42.46px]'>${TotalSenderPrice(viewFlightHistory?.item_weight)}</h1>
            </div>
          </div>
  
          {/**Line */}
          <div className=' mt-[29px] w-full border-solid border-[1px] border-[#C1C1C180]' />
  
          <div className=' mt-[18px] px-[28px] sm:px-[45px]'>
            {/**Item Description */}
            <div className='flex flex-col gap-[5px]'>
              <h1 className=' text-[12px] sm:text-[14px] text-[#AAAAAA] sm:leading-[16.98px]'>Item description</h1>
              <h1 className=' font-bold text-[14px] sm:text-[16px] sm:leading-[19.41px]'>{viewFlightHistory?.item_description}</h1>
            </div>
          </div>
        </div>
      )
}

export default ViewFlightHistory
