import React from 'react'
import { useDispatch } from 'react-redux';
import { sessionFalse } from '../../features/pages/sessionSlice';

const Session = () => {
    const dispatch = useDispatch()

    const handleSessionExpired = () => {
        dispatch(sessionFalse())
        window.localStorage.clear()
        window.location.reload()
    }
    return (
        <div className=' px-4 bg-white rounded-[20px] w-full  flex flex-col gap-[18px] text-center items-center justify-center pt-[28px] pb-[30px]'>
            <h1 className='text-[16px] leading-[19.41px]'>Your session has expired!</h1>
            <button onClick={() => handleSessionExpired()} className=' w-[110px] h-[35px] flex items-center justify-center text-center text-[14px] text-[#FFFFFF] leading-[16.98px] rounded-[60px] bg-[#EB0728]'>Close</button>
        </div>
    )
}

export default Session
