import React from 'react'
import { convertDate, convertTo12HourFormat } from '../ConvertDateAndTime'

const AcceptedRequest = ({ airports, request, viewDetails }) => {
    const departure_airport = airports?.filter((air) => air.id === request.departure_airport)
    const arrival_airport = airports?.filter((air) => air.id === request.arrival_airport)
    if (departure_airport && arrival_airport) {
        request['departure_city'] = departure_airport[0]?.city
        request['arrival_city'] = arrival_airport[0]?.city
    }
    return (
        <div className='w-full bg-[#FFFFFF] flex flex-col gap-[20px] xf:gap-[30px] xf:items-center border-solid border-[1px] border-[#C1C1C180] p-[20px] sm:p-[30px] rounded-[20px]'>
            <div className=' w-full flex flex-col-reverse gap-5 xs:gap-0 xs:flex-row xs:justify-between xs:items-center'>
                {/**Info */}
                <div className='flex flex-col gap-1 text-start'>
                    <h1 className=' font-bold text-[10px] xm:text-[16px] sm:text-[18px]'>{request.departure_city} - {request.arrival_city}</h1>
                    <p className='text-[#707070] font-normal text-[10px] sm:text-[14px]'>Departure date: <span className=' text-black'>{convertDate(request.departure_date)}</span></p>
                </div>

                {/**Status and View Button */}
                <div className='flex items-center gap-[15px]'>
                    <div className={` w-[70px] h-[20px] sm:w-[80px] sm:h-[25px] ${request.status === "Active" && request.carrier_approval === false ? "bg-[#D6FBC9] text-[#4FBF26] border-[#4FBF26]" : request.carrier_approval === true ? " bg-[#E5E5E5] border-[#70707080] text-[#70707080]" : "bg-[#FFDFE4] text-[#EB0728] border-[#EB0728]"} border-[1px] text-[10px] font-bold rounded-[50px] flex items-center justify-center`}>{request.carrier_approval === true ? "Pending" : request.status}</div>
                    <button onClick={() => viewDetails(request)} className=' hidden sm:flex mt-2 xs:mt-0 w-full h-[30px] xm:w-[100px] xm:h-[35px] lg:w-[180px] lg:h-[45px] bg-[#EB0728] text-white text-[12px] md:text-[18px] font-normal rounded-[40px] items-center justify-center'>View</button>
                </div>
            </div>
            {/** */}
            <button onClick={() => viewDetails(request)} className=' flex sm:hidden  w-full h-[30px] xm:h-[40px] bg-[#EB0728] text-white text-[18px] font-normal rounded-[40px] items-center justify-center'>View</button>
        </div>
    )
}

export default AcceptedRequest
