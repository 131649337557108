import React from 'react'
import goodifly from "../../assets/goodifly.png"
import bank from '../../assets/bank.svg'
import momo from '../../assets/momo.svg'
import vodaCash from '../../assets/vodaCash.jpg'
import airtelTigo from '../../assets/airtelTigo.jpg'

const NoMethod = ({ totalBalance, setWhereTo, access_token, setNoMethod, setAddWithrawalType, bankAdded, mobileMoneyAdded, setAddWithrawalMethod, setReviewWithdrawalMethod }) => {

    const handleManage = () => {
        setNoMethod(false)
        setAddWithrawalMethod(true)
    }
    return (
        <div className={` px-5 sm:px-[50px] bg-white rounded-[30px] w-full sm:h-full flex flex-col items-center ${totalBalance>=1? "pt-[44px]" :"pt-[56px]"} sm:pt-[48px] pb-[44px] sm:pb-[105px]`}>
            <img src={goodifly} alt="" className=' w-[104px] sm:w-[107px] h-[32px] sm:h-[33px]' />
            <h1 className=' mt-[23px] font-bold text-[14px] leading-[16.98px] sm:text-[18px] sm:leading-[21.83px] '>Available for withdrawal</h1>
            <h1 className=' mt-[14px] font-bold text-[50px] leading-[60.65px]'>${totalBalance}</h1>
            {totalBalance >= 1 ? <h1 className=' mt-[14px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>{bankAdded !== null || mobileMoneyAdded !== null ? "To begin withdrawal process, select your preferred payout method" : "Add a new withdrawal method"}</h1> :
                <h1 className=' mt-[14px] text-[#AAAAAA] text-[12px] leading-[14.56px] sm:text-[14px] sm:leading-[16.98px]'>There are no funds available at the moment. Hang tight, and check back later</h1>}

            {totalBalance >= 1 ?
                <div className=' w-full flex flex-col'>
                    <div className={` ${bankAdded !== null || mobileMoneyAdded !== null ? " mt-[21px]" : " mt-0 "}  w-full flex flex-col gap-[25px]`}>
                        {bankAdded !== null ?
                            <div onClick={() => { setNoMethod(false); setReviewWithdrawalMethod("Bank Transfer") }} className=' cursor-pointer w-full py-[16px] flex justify-center items-center border-solid border-[1px] border-[#C1C1C1] rounded-[20px]'>
                                <div className='w-ull flex items-center justify-center gap-5'>
                                    <img src={bank} alt="" className=' w-[24px] h-[24px]' />
                                    <div className=' text-start flex flex-col'>
                                        <h1 className=' font-bold text-[16px] leading-[19.41px]'>{bankAdded?.name}</h1>
                                        <div className='flex gap-[8px] flex-wrap'>
                                            <h1 className=' text-[12px] leading-[14.56px]'>{bankAdded?.account_number}</h1>
                                            <h1 className=' text-[12px] leading-[14.56px] text-[#AAAAAA]'>{bankAdded?.account_name}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        {mobileMoneyAdded !== null ?
                            <div onClick={() => { setNoMethod(false); setReviewWithdrawalMethod("Mobile Money") }} className=' cursor-pointer w-full py-[16px] px-2 flex justify-center items-center border-solid border-[1px] border-[#C1C1C1] rounded-[20px]'>
                                <div className='w-ull flex items-center justify-center gap-5'>
                                    {mobileMoneyAdded?.name === "MTN" ? <img src={momo} alt="" className=' w-[40px] h-[40px]' /> : mobileMoneyAdded?.name === "Vodafone" ? <img src={vodaCash} alt="" className=' w-[40px] h-[40px]' /> : <img src={airtelTigo} alt="" className=' object-contain w-[40px] h-[40px]' />}
                                    <div className=' text-start flex flex-col'>
                                        <h1 className=' font-bold text-[16px] leading-[19.41px]'>{mobileMoneyAdded?.name}</h1>
                                        <div className='flex gap-[8px] flex-wrap'>
                                            <h1 className=' text-[12px] leading-[14.56px]'>{mobileMoneyAdded?.account_number}</h1>
                                            <h1 className=' text-[12px] leading-[14.56px] text-[#AAAAAA]'>{mobileMoneyAdded?.account_name}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                    </div>
                    {bankAdded === null && mobileMoneyAdded === null ?
                        <div className='w-full flex flex-col'>
                            <button onClick={() => { setNoMethod(false); setAddWithrawalType("Bank Transfer"); setWhereTo("Withdrawal"); }} className=' mt-[24px] flex items-center justify-center text-center w-full h-[38px]  sm:h-[68px] border-[1px] border-solid border-[#C1C1C1] rounded-[20px] text-[#C1C1C1] font-bold text-[12px] leading-[14.56px]'>+ ADD BANK TRANSFER</button>
                            <button onClick={() => { setNoMethod(false); setAddWithrawalType("Mobile Money"); setWhereTo("Withdrawal"); }} className=' mt-[12px] flex items-center justify-center text-center w-full h-[38px]  sm:h-[68px] border-[1px] border-solid border-[#C1C1C1] rounded-[20px] text-[#C1C1C1] font-bold text-[12px] leading-[14.56px]'>+ ADD MOBILE MONEY</button>
                        </div> :
                        <div className=' w-full flex items-center justify-center'>
                            <h1 onClick={() => handleManage()} className=' w-fit mt-[14px] cursor-pointer border-solid border-b-[1px] border-b-[#AAAAAA] text-[14px] leading-[16.98px] text-[#AAAAAA]'>Manage withdrawal methods</h1>

                        </div>
                    }
                </div> :
                <div></div>
            }
        </div>
    )
}

export default NoMethod
