import React from 'react'
import DeleteMobileMoney from './DeleteMobileMoney'

const DeleteMobileMoneyModal = ({mobileMoneyAdded, access_token, setDeleteMobileMoney, setMobileMoneyAdded, setAddWithrawalMethod, setManageSucess }) => {


    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setDeleteMobileMoney(false)
            setAddWithrawalMethod(true)
        }
    }

    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer pt-[200px] h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id="modal" className="relative my-6 mx-[2%] sm:mx-0 ">
                    {/*content*/}
                    <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <DeleteMobileMoney mobileMoneyAdded={mobileMoneyAdded} access_token={access_token} setDeleteMobileMoney={setDeleteMobileMoney} setMobileMoneyAdded={setMobileMoneyAdded} setAddWithrawalMethod={setAddWithrawalMethod} setManageSucess={setManageSucess}/>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default DeleteMobileMoneyModal
