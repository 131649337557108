// import React from 'react';

// const CustomCommentTooltip = ({ text, hovered, children }) => {
//   return (
//     <div className="relative ml-6">
//       {children}
//       <div className={`absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 ${hovered ? "block" : "hidden"} w-[220px] max-w-[220px] max-h-[50px] overflow-scroll bg-white text-black text-center rounded-[20px] shadow-customTooltip p-2`}>
//         {text}
//       </div>
//       <div className="absolute top-[-50%] left-[15%] transform -translate-x-1/2 w-0 h-0 border-t-8 border-t-white border-l-8 border-l-transparent border-r-8 border-r-transparent"></div>

//     </div>
//   );
// };

// export default CustomCommentTooltip;

import React from "react";

const CustomCommentTooltip = ({ text, hovered, children }) => {
  return (
    <div className="relative ml-6">
      {children}
      {hovered && (
        <>
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[220px] max-w-[220px] max-h-[50px] overflow-scroll bg-white text-black text-center rounded-[20px] shadow-customTooltip p-2">
            {text}
          </div>
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 translate-y-full w-0 h-0 border-t-8 border-t-white border-l-8 border-l-transparent border-r-8 border-r-transparent"></div>
        </>
      )}
    </div>
  );
};

export default CustomCommentTooltip;

