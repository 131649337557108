import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
  selectedFlight: null,
}


export const bookFightSlice = createSlice({
    name: 'bookFlight',
    initialState,
    reducers: {
      BookFlightTrue:(state, action)=>{
        state.value=true;
        state.selectedFlight = action.payload;
      },
      BookFlightFalse:(state)=>{
        state.value=false;
        state.selectedFlight= null;
      },
    },
  })
  
  
  // Action creators are generated for each case reducer function
  export const { BookFlightTrue, BookFlightFalse} = bookFightSlice.actions
  
  export default bookFightSlice.reducer