import React from 'react'
import ReviewMethod from './ReviewMethod';

const ReviewMethodModal = ({totalBalance, access_token, reviewWithdrawalMethod, setReviewWithdrawalMethod, bankAdded, mobileMoneyAdded, setSuccessWithdrawal}) => {
    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            setReviewWithdrawalMethod(null); // Call the function to close the modal
            window.location.reload()
        }
    }
    return (
        <div>
            <div
                onClick={hideModal}
                className=" cursor-pointer h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
            >
                <div id='modal' className="relative my-6 mx-[2%] sm:mx-0 w-full xm:w-auto">
                    {/*content*/}
                    <div className=" cursor-default border-0  relative flex flex-col text-center w-full  xm:w-[418px] sm:w-[518px] h-[400px] sm:h-[550px] outline-none focus:outline-none">
                        <ReviewMethod totalBalance={totalBalance} access_token={access_token} reviewWithdrawalMethod={reviewWithdrawalMethod} setReviewWithdrawalMethod={setReviewWithdrawalMethod} bankAdded={bankAdded} mobileMoneyAdded={mobileMoneyAdded} setSuccessWithdrawal={setSuccessWithdrawal}/>
                    </div>
                </div>
            </div>
            <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
        </div>
    )
}

export default ReviewMethodModal

