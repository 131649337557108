// import React, { useState, useEffect, useRef } from 'react';
// import { convertDate, convertTo12HourFormat } from '../../ConvertDateAndTime';
// import commentIcon from '../assets/comment.svg';
// import CustomCommentTooltip from '../CustomCommentTooltip/CustomCommentTooltip';

// const FlaggedInbound = ({ item, setViewSednerRecipientViewModal, setViewMarketPlaceViewModal }) => {
//     const [hovered, setHovered] = useState(false);
//     const tooltipRef = useRef(null);

//     const handleSednerRecipientViewModal = (item) => {
//         setViewMarketPlaceViewModal(null);
//         setViewSednerRecipientViewModal(item);
//     };

//     const handleMarketPlaceViewModal = (item) => {
//         setViewSednerRecipientViewModal(null);
//         setViewMarketPlaceViewModal(item);
//     };

//     const handleClickOutside = (event) => {
//         if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
//             setHovered(false);
//         }
//     };

//     useEffect(() => {
//         if (hovered) {
//             document.addEventListener('mousedown', handleClickOutside);
//         } else {
//             document.removeEventListener('mousedown', handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [hovered]);

//     return (
//         <div className={`main-container w-full h-[80px] flex items-center border-solid border-b-[1px] border-b-[#C1C1C180] ${hovered ? 'mt-[35px]' : ''}`}>
//             <div className='w-full flex items-center text-[14px] text-[#707070] leading-[16.98px] px-[19px]'>
//                 <div className='w-[12.5%] font-bold text-[18px] leading-[21.83px]'>{item?.sender_name?.split(' ')[0]}</div>
//                 <div className='w-[12.5%]'>{item?.from_city_code} - {item?.arrival_code}</div>
//                 <div className='w-[12.5%] flex flex-col gap-[5px]'>
//                     <p>{convertDate(item?.departure_date)}</p>
//                     <p>{convertTo12HourFormat(item?.depart_time)}</p>
//                 </div>
//                 <div className='w-[12.5%] flex flex-col gap-[5px]'>
//                     <p>{convertDate(item?.arrival_date)}</p>
//                     <p>{convertTo12HourFormat(item?.arrival_time)}</p>
//                 </div>
//                 <div className='w-[12.5%]'>{item?.request_type === 1 ? "Send" : item?.request_type === 2 ? "Receive" : "Marketplace"}</div>
//                 <div className='w-[12.5%]'>{item?.carrier_name}</div>
//                 <div className='w-[12.5%]' ref={tooltipRef}>
//                     <CustomCommentTooltip text={item?.agent_inbound_comment} hovered={hovered}>
//                         <img onClick={() => setHovered(true)} src={commentIcon} alt="commentIcon" className='cursor-pointer ml-5 w-[18.33px] h-[18.33px]' />
//                     </CustomCommentTooltip>
//                 </div>
//                 <div className='w-[12.5%]'>
//                     {item?.request_type === 3 ?
//                         <button onClick={() => handleMarketPlaceViewModal(item)} className='w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]'>View</button>
//                         :
//                         <button onClick={() => handleSednerRecipientViewModal(item)} className='w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]'>View</button>}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default FlaggedInbound;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { convertDate, convertTo12HourFormat } from "../../ConvertDateAndTime";
import commentIcon from "../assets/comment.svg";
import CustomCommentTooltip from "../CustomCommentTooltip/CustomCommentTooltip";

const FlaggedInbound = ({
  item,
  setViewSednerRecipientViewModal,
  setViewMarketPlaceViewModal,
}) => {
  const [hovered, setHovered] = useState(false);
  const tooltipRef = useRef(null);

  // Memoize handlers to avoid unnecessary re-renders
  const handleClickOutside = useCallback((event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setHovered(false);
    }
  }, []);

  useEffect(() => {
    if (hovered) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hovered, handleClickOutside]);

  const handleSednerRecipientViewModal = useCallback(() => {
    setViewMarketPlaceViewModal(null);
    setViewSednerRecipientViewModal(item);
  }, [item, setViewMarketPlaceViewModal, setViewSednerRecipientViewModal]);

  const handleMarketPlaceViewModal = useCallback(() => {
    setViewSednerRecipientViewModal(null);
    setViewMarketPlaceViewModal(item);
  }, [item, setViewSednerRecipientViewModal, setViewMarketPlaceViewModal]);

  const buttonHandler =
    item?.request_type === 3
      ? handleMarketPlaceViewModal
      : handleSednerRecipientViewModal;

  return (
    <div
      className={`main-container w-full h-[80px] flex items-center border-solid border-b-[1px] border-b-[#C1C1C180] ${
        hovered ? "mt-[35px]" : ""
      }`}
    >
      <div className="w-full flex items-center text-[14px] text-[#707070] leading-[16.98px] px-[19px]">
        <div className="w-[12.5%] font-bold text-[18px] leading-[21.83px]">
          {item?.sender_name?.split(" ")[0]}
        </div>
        <div className="w-[12.5%]">
          {item?.from_city_code} - {item?.arrival_code}
        </div>
        <div className="w-[12.5%] flex flex-col gap-[5px]">
          <p>{convertDate(item?.departure_date)}</p>
          <p>{convertTo12HourFormat(item?.depart_time)}</p>
        </div>
        <div className="w-[12.5%] flex flex-col gap-[5px]">
          <p>{convertDate(item?.arrival_date)}</p>
          <p>{convertTo12HourFormat(item?.arrival_time)}</p>
        </div>
        <div className="w-[12.5%]">
          {item?.request_type === 1
            ? "Send"
            : item?.request_type === 2
            ? "Receive"
            : "Marketplace"}
        </div>
        <div className="w-[12.5%]">{item?.carrier_name}</div>
        <div className="w-[12.5%]" ref={tooltipRef}>
          <CustomCommentTooltip
            text={item?.agent_inbound_comment}
            hovered={hovered}
          >
            <img
              onClick={() => setHovered(true)}
              src={commentIcon}
              alt="comment icon"
              className="cursor-pointer ml-5 w-[18.33px] h-[18.33px]"
            />
          </CustomCommentTooltip>
        </div>
        <div className="w-[12.5%]">
          <button
            onClick={buttonHandler}
            className="w-[122px] h-[40px] rounded-[50px] bg-[#EB0728] font-bold text-[14px] text-[#ffffff] leading-[16.98px]"
            aria-label="View details"
          >
            View
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlaggedInbound;
