const CarrierPriceCalculator = (weight, sendercost) => {
    let carriertotalearning;
    // let goodiflyearning;
    const custSendercost = sendercost - 25
    if (weight >= 1 && weight < 18) {
      carriertotalearning = custSendercost - (custSendercost * 0.15);
    } else {
      carriertotalearning = custSendercost - (custSendercost * 0.18);
    }
    
    // goodiflyearning = sendercost - carriertotalearning;
    
    return carriertotalearning.toFixed(2);
  };
  

  export default CarrierPriceCalculator







//   // Example usage:
//   let weight = 10; // example weight
//   let sendercost = 100; // example sender cost
//   let [carriertotalearning, carriertogoodifly] = CarierPriceCalculator(weight, sendercost);
  
//   console.log(`Carrier Total Earning: ${carriertotalearning}, Goodify Earning: ${carriertogoodifly}`);
  

// def carrierEarning(weight=weight,sendercost=sendercost):
//     if weight in range(1,18):
//         carriertotalearning=sendercost-sendercost*0.15
//     else:
//         carriertotalearning=sendercost-sendercost*0.18
//     goodiflyearning=sendercost-carriertotalearning
//     return carriertotalearning,goodiflyearning
// carriertotalearning= carrierEarning()[0]
// carriertogoodifly=carrierEarning()[1]
