import React from 'react'
import { useDispatch } from 'react-redux'
import { BookFlightTrue } from '../../features/flight/bookFlightSlice'
import { UnableFlightTrue } from '../../features/flight/unableFlightSlice'
import './Flight.css'
import { convertTo12HourFormat, formatDate } from '../ConvertDateAndTime'
import planeIcon from './assets/planeIcon.svg'
import MarketplaceBadge from './MarketplaceBadge'


const Flight = ({ flight, setIsMarketPlace }) => {
  // Decode the data after retrieving it
  function decodeData(encodedData) {
    return atob(encodedData); // Base64 decoding
  }
  const some_data = window.localStorage.getItem(
    "jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy"
  );
  const current_Data = some_data ? decodeData(some_data) : null;
  const parsedData = some_data ? JSON?.parse(current_Data) : null;
  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const departureDateTime = new Date(
    `${flight?.departure_date}T${flight?.depart_time}`
  );
  // Calculate the difference in days
  const differenceInTime = departureDateTime - currentDateTime;
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  const handleOnClick = () => {

    if (some_data && parsedData?.id === flight?.carrier_id) {
      dispatch(UnableFlightTrue())
    }

    else {
      dispatch(BookFlightTrue(flight))
    }

    if (differenceInDays >= 7) {
      setIsMarketPlace(true)
    }
  }
  return (
    <>
      <div
        onClick={handleOnClick}
        className="  relative cursor-pointer  flex flex-col  w-[317px] max-w-[317px]  lg:w-[350px] lg:max-w-[350px] h-[430px] mb-[29px]"
      >

        <img
          src={`https://${flight?.airport_img}`}
          alt=""
          className="w-full absolute top-0 left-0 rounded-tl-[30px] rounded-tr-[30px] !h-[247px] object-cover  z-0 "
        />


        {flight?.from_city==="Accra"? null : differenceInDays >= 7 && <MarketplaceBadge />}

        <div className=" absolute  bottom-0 left-0 w-full px-[30px] pb-[30px] pt-[26px] rounded-bl-[30px] rounded-br-[30px] lg:w-[350px] lg:max-w-[350px] w-full max-w-[317px] h-[183px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] shadow-adminLogIn">
          <div className=" w-full flex items-center justify-between">
            {/**Departure Info */}
            <div className=" text-[12px] lg:text-[14px] leading-[14.56px] flex flex-col gap-[2px] items-center">
              <h1 className=" text-[#AAAAAA]">{flight?.from_city}</h1>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                {flight?.from_city_code}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {formatDate(flight?.departure_date)}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {convertTo12HourFormat(flight?.depart_time)}
              </h1>
            </div>

            <div>  <img src={planeIcon} alt="" className=" w-[16px] h-[16px]" />
            <img src={planeIcon} alt="" className=" invisible w-[16px] h-[16px]" />

            </div>

           

            {/**Arrival Info */}
            <div className=" text-[12px] lg:text-[14px] leading-[14.56px] flex flex-col gap-[2px] font-normal items-center">
              <h1 className=" text-[#AAAAAA]">{flight?.to_city}</h1>
              <h1 className=" font-bold text-[16px] lg:text-[18px] leading-[19.41px]">
                {flight?.to_city_code}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {formatDate(flight?.arrival_date)}
              </h1>
              <h1 className=" text-[#AAAAAA]">
                {convertTo12HourFormat(flight?.arrival_time)}
              </h1>
            </div>
          </div>

          {/**Available Space */}
          <div className=" w-full h-[45px] flex gap-[32px] items-center justify-center rounded-[15px] border-solid border-[1px] border-[#C1C1C180] mt-[12px]">
            <h1 className="text-[#AAAAAA] text-[14px] leading-[16.98px] font-normal">
              Available space
            </h1>
            <div className=" leading-[21.83px] flex items-center justify-center text-center text-[18px]  text-[#EB0728] font-bold">
              {flight?.minimum_space < 1 ? (
                <h1>{flight?.maximum_space}kg</h1>
              ) : (
                <h1>
                  {flight?.minimum_space}kg - {flight.maximum_space}kg
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Flight
